import type {
  ApiCreateWorkshop,
  ApiExerciseWithSolutionAttempts,
  ApiWorkshop,
  ApiWorkshopParticipantStats,
  CreateApiSolutionAttempt,
  CreateApiWorkshopAnalyticsEvent,
  Payload,
} from "apiTypes";
import _, { defaultTo } from "lodash";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "../../router";
import {
  useOrganizationApi,
  useOrganizationResource,
  useOrganizationSWR,
  useRootApi,
  useRootResource,
  useRootSWR,
} from "../hooks/useApi";
import type { usePromptingAssistant } from "../prompts/specific/assistant";
import type { useChallengeEvaluationsScore } from "../prompts/specific/challenge";

export function getPayloadId(doc: { id: number } | number) {
  return typeof doc === "object" ? doc.id : doc;
}

// this function will take a payload type of T|number and return the T or throw an error
export function unwrapPayloadArray<T>(doc: (T | number)[]): T[] {
  if (doc.some((d) => typeof d == "number")) {
    throw new Error("Invalid payload reference");
  }
  return doc as T[];
}

export function useCourses() {
  const { i18n } = useTranslation();
  return useOrganizationResource<Payload.Course[]>(
    "elearning/courses?locale=" + i18n.resolvedLanguage
  );
}

export function useCourse(courseId: string) {
  const { i18n } = useTranslation();
  return useOrganizationResource<
    Payload.Course & {
      exercises: {
        exercise: ExerciseWithCompletion;
        order: number;
        isRequired: boolean;
        id?: string | null;
      }[];
    }
  >(`elearning/courses/${courseId}?locale=${i18n.resolvedLanguage}`);
}

export function useCourseProgress(courseId: string) {
  const course = useCourse(courseId);
  const firstUnsolvedExercise = course?.exercises
    .sort((a, b) => a.order - b.order)
    .find((e) => !(e.exercise as ExerciseWithCompletion).completed);
  const numCompleted = course?.exercises.filter(
    (e) => (e.exercise as ExerciseWithCompletion).completed
  ).length;
  const numTotal = course?.exercises.length;

  return {
    numCompleted,
    numTotal,
    firstUnsolvedExerciseId: firstUnsolvedExercise?.exercise
      ? getPayloadId(firstUnsolvedExercise?.exercise) + ""
      : undefined,
  };
}

export type ExerciseWithCompletion = Payload.Exercise & {
  completed: boolean;
};

export function useExercise(courseId: string, exerciseId: string) {
  const { i18n } = useTranslation();

  return useOrganizationResource<ExerciseWithCompletion>(
    `elearning/courses/${courseId}/exercises/${exerciseId}?locale=${i18n.resolvedLanguage}`
  );
}

export function useMutateCourse(courseId: string) {
  const { i18n } = useTranslation();
  return useOrganizationSWR(
    `elearning/courses/${courseId}?locale=${i18n.resolvedLanguage}`
  ).mutate;
}

export function useMutateExercise(courseId: string, exerciseId: string) {
  const { i18n } = useTranslation();

  const mutateExercise = useOrganizationSWR(
    `elearning/courses/${courseId}/exercises/${exerciseId}?locale=${i18n.resolvedLanguage}`
  ).mutate;
  const mutateCourse = useMutateCourse(courseId);
  return async () => {
    await mutateExercise();
    await mutateCourse();
  };
}

export function useNavigateToNextExercise() {
  const { courseId, organizationId, workshopId } = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  );
  const navigate = useNavigate();

  const nextId = useNextExerciseId();

  if (nextId === undefined) {
    return () =>
      navigate("/:organizationId/learn/:workshopId/course/:courseId/done", {
        params: {
          organizationId,
          courseId,
          workshopId,
        },
      });
  }

  return () =>
    navigate(
      "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId",
      {
        params: {
          organizationId,
          courseId,
          exerciseId: nextId ?? "",
          workshopId,
        },
      }
    );
}

export function usePreloadNextExercise() {
  const nextId = useNextExerciseId();
  // in case this is the next one, don't try to preload the next. because hooks cant be called conditionally, this is a workaround
  const currentId = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  ).exerciseId;
  useExercise(
    useParams("/:organizationId/learn/:workshopId/course/:courseId").courseId,
    nextId ?? currentId
  );
}

export function useNextExerciseId() {
  const { courseId, exerciseId } = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  );

  const exercises = useCourse(courseId)?.exercises.sort(
    (a, b) => a.order - b.order
  );

  if (!exercises) {
    return undefined;
  }

  const currentIndex = exercises.findIndex(
    (e) => getPayloadId(e.exercise) + "" === exerciseId
  );

  if (currentIndex === -1) {
    return undefined;
  }

  const nextExercise = exercises[currentIndex + 1];

  if (!nextExercise) {
    return undefined;
  }

  return getPayloadId(nextExercise.exercise) + "";
}

export function useWorkshopParticipantsStats(
  workshopId: string,
  refreshInterval: number = 5000
): ApiWorkshopParticipantStats[] | null | undefined {
  return useRootResource(`workshops/${workshopId}/participants/overview`, {
    refreshInterval,
  });
}

export function useCreateWorkshop() {
  const rootApi = useRootApi();
  const mutateWorkshops = useRootSWR("workshops/admin/list").mutate;

  return async (workshop: ApiCreateWorkshop, organizationId?: string) => {
    await rootApi.post(
      "workshops" + (organizationId ? "/" + organizationId : ""),
      workshop
    );
    await mutateWorkshops();
  };
}

export function useSolutionAttemptsByCourseAndUser(
  courseId: string,
  userId: string
) {
  return useOrganizationResource<ApiExerciseWithSolutionAttempts[]>(
    `elearning/solution-attempts/by-user?courseId=${courseId}&userId=${userId}`
  );
}

export function useCreateSolutionAttempt() {
  const orgApi = useOrganizationApi();
  return async (solutionAttempt: CreateApiSolutionAttempt) => {
    await orgApi.post("elearning/solution-attempts", solutionAttempt);
  };
}

type AssistentEvaluationReturnType = ReturnType<
  typeof usePromptingAssistant
>["promptEvaluations"];
type ChallengeEvaluationReturnType =
  | ReturnType<typeof useChallengeEvaluationsScore>["evaluations"]
  | null;

export function useSolutionAttemptPoster(
  promptingAssistentEvaluation: AssistentEvaluationReturnType,
  promptingAssistentEnabled: boolean,
  challengeEvaluation: ChallengeEvaluationReturnType,
  challengeSolutionAttempt: number,
  courseId: string,
  exerciseId: number,
  chatId: string
) {
  const solved = challengeEvaluation?.every((ce) => ce.completed);

  const createSolutionAttempt = useCreateSolutionAttempt();

  // this hook should post whenever the promptingAssistentEvaluation and challengeEvaluation have both been updated. use _.isEqual to compare the two objects
  const lastPromptingAssistentEvaluation =
    useRef<AssistentEvaluationReturnType>([]);
  const lastChallengeSolutionAttempt = useRef<number>(challengeSolutionAttempt);
  const promptingAssistentHasChanged = useRef(false);
  const challengeHasChanged = useRef(false);

  if (
    !_.isEqual(
      lastPromptingAssistentEvaluation.current,
      promptingAssistentEvaluation
    )
  ) {
    promptingAssistentHasChanged.current = true;
    lastPromptingAssistentEvaluation.current = promptingAssistentEvaluation;
    console.log("promptingAssistentEvaluation has changed");
  }

  if (lastChallengeSolutionAttempt.current !== challengeSolutionAttempt) {
    challengeHasChanged.current = true;
    lastChallengeSolutionAttempt.current = challengeSolutionAttempt;
    console.log("challengeSolutionAttempt has changed");
  }

  const mutateExercise = useMutateExercise(courseId, exerciseId + "");

  // promptingAssistent: either it is disabled or it has changed and a current value
  // challengeEvaluation: it has changed and has a current value
  if (
    ((promptingAssistentEnabled &&
      promptingAssistentEvaluation &&
      promptingAssistentHasChanged.current) ||
      !promptingAssistentEnabled) &&
    challengeHasChanged.current &&
    challengeEvaluation
  ) {
    promptingAssistentHasChanged.current = false;
    challengeHasChanged.current = false;
    createSolutionAttempt({
      courseId: parseInt(courseId),
      exerciseId: exerciseId,
      chatId: chatId,
      SolutionAttemptEvaluation: challengeEvaluation.map((ce) => ({
        challengeEvaluationId: ce.id + "",
        passed: ce.completed,
      })),
      solved: solved ?? false,
      skipped: false,
    })
      .catch(console.error)
      .finally(() => {
        mutateExercise().catch(console.error);
      });
  }
}

export function useUpdateWorkshop() {
  const rootApi = useRootApi();
  const mutateWorkshops = useRootSWR("workshops/admin/list").mutate;

  return async (workshopId: string, workshop: Partial<ApiWorkshop>) => {
    await rootApi.patch(`workshops/admin/${workshopId}`, workshop);
    await mutateWorkshops();
  };
}

export function useCaptureAnalyticsEvent() {
  const { courseId, exerciseId, workshopId } = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  );

  const orgApi = useOrganizationApi({ disableErrorToast: true });
  return (
    eventType: CreateApiWorkshopAnalyticsEvent["eventType"],
    eventData?: Partial<Omit<CreateApiWorkshopAnalyticsEvent, "eventType">>
  ) => {
    const parsedCourseId =
      eventData?.courseId ?? defaultTo(parseInt(courseId), null);
    const parsedExerciseId =
      eventData?.exerciseId ?? defaultTo(parseInt(exerciseId), null);

    void orgApi.post("elearning/analytics", {
      eventType,
      courseId: parsedCourseId,
      exerciseId: parsedExerciseId,
      workshopEventId: workshopId != "none" ? workshopId : null,
    } satisfies CreateApiWorkshopAnalyticsEvent);
  };
}
