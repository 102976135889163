import type { Dispatch, SetStateAction } from "react";
import { useState } from "react";
import { useUploadDocumentWithToast } from "../../../lib/api/documents";
import { useDropzone } from "react-dropzone";
import { Card, IconButton, Typography } from "@mui/joy";
import { DocumentPreview } from "./DocumentPreview";
import { AddCircle, Attachment } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  extensionsByMimeType,
  getExtensionsFromMimeMap,
  getMimeListFromMap,
} from "./mime";
import { toast } from "react-toastify";

export function DocumentDropzone({
  documentIds,
  setDocumentIds,
  customMime,
  allowMultiple = true,
}: {
  documentIds: string[];
  setDocumentIds: Dispatch<SetStateAction<string[]>>;
  customMime?: Record<string, string[]>;
  allowMultiple?: boolean;
}) {
  const { t } = useTranslation();
  const [numLoading, setNumLoading] = useState(0);

  const uploadDocument = useUploadDocumentWithToast();

  const onDrop = async (files: File[]) => {
    if (!allowMultiple && documentIds.length > 0) {
      return;
    }
    setNumLoading((prev) => prev + files.length);
    const prevIds = documentIds;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      try {
        const uploadedFile = await uploadDocument(file);
        if (documentIds.includes(uploadedFile.id)) {
          toast.info(t("documents.alreadyUploaded"));
        } else {
          setDocumentIds([...prevIds, uploadedFile.id]);
          prevIds.push(uploadedFile.id);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setNumLoading((prev) => prev - 1);
      }
    }
    setNumLoading(0);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: customMime ?? extensionsByMimeType,
    maxFiles: allowMultiple ? undefined : 1,
    disabled: allowMultiple && (documentIds.length > 0 || numLoading > 0),
    multiple: allowMultiple,
  });

  const empty = documentIds.length === 0 && numLoading === 0;

  return (
    <div {...getRootProps()}>
      <Card
        sx={{ padding: 2, cursor: "pointer" }}
        variant={isDragActive ? "soft" : "outlined"}
        color={isDragActive ? "primary" : "neutral"}
        className="transition-all"
      >
        <input
          id="dropzoneInput"
          {...getInputProps()}
          accept={getMimeListFromMap(customMime ?? extensionsByMimeType)}
          disabled={
            !allowMultiple && (documentIds.length > 0 || numLoading > 0)
          }
          onChange={async (e) => {
            await onDrop((e.target.files ?? []) as File[]);
          }}
        />
        <div className="flex flex-row flex-wrap items-center gap-2">
          {documentIds.map((docId) => (
            <DocumentPreview
              key={docId}
              documentId={docId}
              onRemove={async () => {
                setDocumentIds((prev) => prev.filter((id) => id !== docId));
              }}
            />
          ))}
          {new Array(numLoading).fill({}).map((_, i) => (
            <DocumentPreview key={i} loading />
          ))}
          {!empty && allowMultiple && (
            <IconButton
              onClick={() => {
                (
                  document.querySelector("#dropzoneInput") as HTMLElement
                )?.click();
              }}
            >
              <AddCircle color="primary" />
            </IconButton>
          )}
        </div>
        {empty && (
          <div
            className="flex h-full w-full flex-col items-center gap-2 text-lg"
            style={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              "--Icon-fontSize": "30px",
            }}
          >
            <Attachment fontSize="large" />
            <Typography level="body-md" textAlign="center">
              {t("documents.dropzoneEmpty")}
            </Typography>
            {customMime && (
              <Typography level="body-sm">
                {t("allowedFiles")}{" "}
                {getExtensionsFromMimeMap(customMime).join(", ")}
              </Typography>
            )}
          </div>
        )}
      </Card>
    </div>
  );
}
