import { useParams } from "../../router";
import { useTranslation } from "../i18n";
import { handleGenericError } from "../errorHandling";

export function useStreamingOrganizationApi() {
  const { t } = useTranslation();
  const { organizationId } = useParams("/:organizationId");

  return async (url: string, options: RequestInit) => {
    return await fetch(`/api/organizations/${organizationId}/` + url, {
      ...options,
      credentials: "include",
      headers: {
        "content-type": "application/json",
        ...options.headers,
      },
    })
      .then((response) => {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        const reader = response.body?.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader!.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      .catch((e) => {
        // check if it is cancelled by the user
        if (e.name === "AbortError") {
          return;
        }
        handleGenericError(
          e,
          t("unexpectedNetworkError"),
          {
            url,
            options,
          },
          true
        );
      });
  };
}
