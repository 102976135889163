import type { ImageRequest } from "apiTypes";
import { ImageResponse, getSizeAspectRatio } from "apiTypes";
import { useEffect, useState } from "react";
import { useGenerateImage } from "../../lib/api/images";
import {
  AspectRatio,
  Button,
  Card,
  Modal,
  ModalClose,
  ModalDialog,
  Skeleton,
  Typography,
} from "@mui/joy";
import { Wallpaper } from "@mui/icons-material";
import { useTranslation } from "../../lib/i18n";
import { CircularFakeLoader } from "../util/FakeLoader";
import { AxiosError } from "axios";
import { useParams } from "../../router";

export function GenerateImage({
  prompt,
  width,
}: {
  prompt: ImageRequest | null;
  width: number;
}) {
  const { t } = useTranslation();
  const [generating, setGenerating] = useState(false);

  const [result, setResult] = useState<ImageResponse | null>(null);
  const [resultPrompt, setResultPrompt] = useState<ImageRequest | null>(null);

  const aspectRatio = resultPrompt ? getSizeAspectRatio(resultPrompt.size) : 1;
  const height = width / aspectRatio;

  const generateImage = useGenerateImage();

  const [error, setError] = useState<string | null>(null);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!prompt) return;
    setError(null);
    setGenerating(true);

    const currentPrompt: ImageRequest = {
      model: "dall-e-3",
      prompt: prompt.prompt,
      quality: prompt.quality,
      size: prompt.size,
      style: prompt.style,
    };

    setResultPrompt(currentPrompt);

    let cancelled = false;

    generateImage(currentPrompt)
      .then((response) => {
        if (cancelled) return;
        const data = ImageResponse.parse(response.data);
        setResult(data);
      })
      .catch((e) => {
        if (cancelled) return;
        if (e instanceof AxiosError) {
          if (e.response?.status === 400) {
            setError(e.response.data.message);
          } else {
            setError("unknown");
          }
        }
      })
      .finally(() => {
        if (cancelled) return;
        setGenerating(false);
      });

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompt]);

  if (error) {
    return (
      <AspectRatio
        ratio={aspectRatio}
        sx={{
          width,
          height,
        }}
      >
        <div className="flex flex-1 flex-col gap-3 rounded border-2 border-red-500 bg-red-500 bg-opacity-50 p-3">
          <h1 className="text-2xl font-thin text-red-800">
            {t("errorDisplay.title")}
          </h1>
          <p className="text-red-800">{t("errors." + error)}</p>
        </div>
      </AspectRatio>
    );
  }

  if (generating) {
    return (
      <div className="relative">
        <div className="absolute z-10 flex h-full w-full items-center justify-center">
          <CircularFakeLoader timeTo90={23000} size="lg" />
        </div>
        <AspectRatio
          ratio={aspectRatio}
          sx={{
            width,
            height,
          }}
        >
          <Skeleton>
            <img
              style={{
                width,
                height,
              }}
              className="-z-10"
              src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              alt="Loading image"
            />
          </Skeleton>
        </AspectRatio>
      </div>
    );
  } else if (!prompt) {
    return (
      <Card
        sx={{
          width,
          height: width / aspectRatio,
          fontSize: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Wallpaper fontSize="inherit" />
      </Card>
    );
  } else if (result) {
    return (
      <>
        <PreviewModal
          imgSrc={result.url}
          open={modalOpen}
          setOpen={setModalOpen}
          key={result.url}
        />
        <img
          className="cursor-pointer transition-all hover:brightness-50"
          onClick={() => {
            setModalOpen(true);
          }}
          src={result.url}
          alt="Generated image"
          style={{
            width,
            height,
          }}
        />
      </>
    );
  } else {
    return (
      <AspectRatio ratio={aspectRatio}>{t("errorDisplay.title")}</AspectRatio>
    );
  }
}

function PreviewModal({
  imgSrc,
  open,
  setOpen,
}: {
  imgSrc: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation();
  const { organizationId } = useParams("/:organizationId");

  const downloadUrl = `/api/organizations/${organizationId}/images/download?url=${encodeURIComponent(
    imgSrc
  )}`;

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalDialog>
        <ModalClose />
        <div className="flex min-w-max max-w-full flex-col gap-4">
          <Typography level="h4">{t("images.preview")}</Typography>
          <img
            src={imgSrc}
            alt="An AI Generated image"
            style={{
              maxWidth: "80vw",
              maxHeight: "80vh",
            }}
          />
          <div className="flex flex-row gap-2">
            <a href={downloadUrl} download="generated.png">
              <Button>{t("download")}</Button>
            </a>
          </div>
        </div>
      </ModalDialog>
    </Modal>
  );
}
