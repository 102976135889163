import { Outlet } from "react-router-dom";
import { Sidebar } from "../../components/sidebar/Sidebar";
import { useNavigate, useParams } from "../../router";
import { useMe } from "../../lib/api/user";
import { useEffect } from "react";
import { usePartOfCurrentOrganization } from "../../lib/api/organization";
import { Alert, CssVarsProvider, Link } from "@mui/joy";
import { useTheme } from "../../lib/hooks/useTheme";
import { NotFound } from "../../components/util/NotFound";
import { Plausible } from "../../components/analytics/Plausible";
import { useBanners } from "../../lib/api/banners";
import { Info } from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import { FormBricks } from "../../components/analytics/Formbricks";
import { IntroTour } from "../../components/onboarding/IntroTour";
import { useLoggedInOnly } from "../../lib/hooks/useLoggedInOnly";
import { trpc } from "../../lib/api/trpc/trpc";
import { useCurrentOrganizationId } from "../../lib/api/trpc/helpers/useCurrentOrganizationId";
import { GuidelinesModal } from "../../components/util/GuidelinesModal";

export default function OrganizationLayout() {
  useLoggedInOnly();
  const me = useMe();
  const navigate = useNavigate();
  const params = useParams("/:organizationId");
  const organizationId = useCurrentOrganizationId();

  const { data: organization } = trpc.organization.getOrganization.useQuery({
    organizationId,
  });

  usePartOfCurrentOrganization();

  const banners = useBanners();

  useEffect(() => {
    if (me && me.onboarded === false) {
      navigate("/:organizationId/onboarding", { params, replace: true });
    }

    if (!me || !organization) return;

    if (organization.isAcademyOnly) {
      navigate("/:organizationId/learn/:workshopId", {
        params: {
          ...params,
          workshopId: "none",
        },
        replace: true,
      });
    }
  }, [me, navigate, params, organization]);

  useEffect(() => {
    // set the title of the page to the organization name
    if (organization?.customTitle) {
      document.title = organization.customTitle;
    } else {
      document.title = "meinGPT";
    }
  }, [organization?.customTitle]);

  const theme = useTheme();

  if (organization === null) {
    return <NotFound />;
  }

  return (
    <CssVarsProvider theme={theme}>
      <IntroTour />
      <FormBricks />
      <Plausible />
      <GuidelinesModal organizationId={organizationId} me={me} />
      <div className="border-red relative flex h-screen w-screen flex-row">
        {me?.isGlobalAdminOnly && (
          <>
            <div className="pointer-events-none absolute left-0 top-0 z-50 p-2">
              <Alert
                variant="soft"
                color="danger"
                size="sm"
                startDecorator={<Info />}
              >
                Super Admin View. You are not a member of this organization.
              </Alert>
            </div>
            <div className="pointer-events-none fixed z-40 h-screen w-screen border-4 border-red-500" />
          </>
        )}
        <Sidebar />
        <div className="relative h-full flex-1 overflow-y-auto">
          <div className="absolute left-0 right-0 top-0 z-10 flex flex-col items-center gap-4 p-4">
            {banners?.map((banner) => (
              <Banner banner={banner} key={banner.id} />
            ))}
          </div>
          <Outlet />
        </div>
      </div>
    </CssVarsProvider>
  );
}

function Banner({
  banner,
}: {
  banner: {
    content: string;
    type: "danger" | "warning" | "success";
  };
}) {
  return (
    <Alert
      sx={{
        width: "100%",
      }}
      variant="soft"
      color={banner.type ?? "primary"}
      size="lg"
      startDecorator={<Info />}
    >
      <ReactMarkdown
        components={{
          a: ({ ...props }) => (
            <Link>
              <a {...props} target="_blank" rel="noopener noreferrer" />
            </Link>
          ),
        }}
      >
        {banner.content}
      </ReactMarkdown>
    </Alert>
  );
}
