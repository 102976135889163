import type { ApiTextModel } from "apiTypes";
import { Badge, IconButton } from "@mui/joy";
import { Settings } from "@mui/icons-material";
import { useState } from "react";
import { ModelSelectorModal } from "../input/ModelSelectorModal";

export function ChatSettingsMenu({
  selectedModel,
  setSelectedModel,
}: {
  selectedModel: ApiTextModel | null;
  setSelectedModel: (model: ApiTextModel | null) => void;
}) {
  const [open, setOpen] = useState(false);

  const hasOverrideSettings = selectedModel !== null;

  return (
    <>
      <Badge badgeContent={hasOverrideSettings ? "" : 0}>
        <IconButton
          variant="soft"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <Settings />
        </IconButton>
      </Badge>
      <ModelSelectorModal
        open={open}
        setOpen={setOpen}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
      />
    </>
  );
}
