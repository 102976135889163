import { ChevronRight, Error } from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import * as Sentry from "@sentry/react";
import type { ApiWorkshop } from "apiTypes";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import useDigitInput from "react-digit-input";
import { useTranslation } from "react-i18next";
import {
  useNavigate as reactNavigate,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import LogoSolid from "../../assets/logo_solid.svg";
import { LanguageSelector } from "../../components/settings/languageSelector";
import {
  useAcademyProfile,
  useMe,
  useMutateMe,
  useUpdateAcademyProfile,
  useUser,
} from "../../lib/api/user.ts";
import { useAuthStore } from "../../lib/context/authStore";
import { useOrganizationApi, useRootApi } from "../../lib/hooks/useApi";
import { useNavigate } from "../../router.ts";

import { Header } from "../[organizationId].learn.[workshopId]";

enum JoinProcessStep {
  InputCode,
  InputEmail,
  CompleteProfile,
  Join,
}

export default function JoinWorkshopPage() {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const codeParam = params.get("code");
  const [processStep, setProcessStep] = useState<JoinProcessStep>(
    JoinProcessStep.InputCode
  );

  function updateProcessStep(val: JoinProcessStep) {
    if (val > processStep) {
      setProcessStep(val);
    }
  }

  return (
    <div className="flex flex-col items-stretch gap-12">
      <Header className="py-20">
        <div className="flex flex-col items-center gap-5 text-white">
          <img src={LogoSolid} alt="Logo" className="h-20 w-20" />
          <Typography level="h1" className="!text-white">
            meinGPT Academy
          </Typography>
        </div>
      </Header>
      <Sentry.ErrorBoundary
        fallback={<Alert>{t("errors.cannotDisplay")}</Alert>}
      >
        <main className="flex flex-col items-center">
          {{
            0: (
              <CodeInput
                onComplete={() => updateProcessStep(JoinProcessStep.InputEmail)}
                joinCode={codeParam}
              />
            ),
            1: (
              <SetEmailStep
                onEmailSubmit={() => {
                  updateProcessStep(JoinProcessStep.CompleteProfile);
                }}
              />
            ),
            2: (
              <CompleteDetailsStep
                onDetailsFilled={() => updateProcessStep(JoinProcessStep.Join)}
              />
            ),
            3: <JoinWorkshopStep joinCode={codeParam ?? ""} />,
          }[processStep] ?? null}
        </main>
      </Sentry.ErrorBoundary>
      <LanguageSelector
        sx={{ position: "absolute", bottom: 20, left: 20 }}
        filter={["de", "en"]}
      />
    </div>
  );
}

const ERRORS = {
  not_found: "errors.workshopNotFound",
  completed: "workshopAlreadyCompleted",
};

function CodeInput({
  onComplete,
  joinCode,
}: {
  onComplete?: (code: string) => void;
  joinCode: string | null;
}) {
  const { t } = useTranslation();
  const api = useRootApi();

  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const [error, setError] = useState<string | null>(null);

  const navigate = reactNavigate();

  const checkCode = useCallback(
    async (code: string) => {
      const res = await api.post("workshopUsers/testCode/" + code);
      const isValid = res.data.codeValid;
      if (isValid) {
        onComplete && onComplete(code);
        navigate("/workshops/join?code=" + code);
      } else {
        setError(t(ERRORS[res.data.errorMessage]));
      }
      setLoading(false);
    },
    [api, onComplete, navigate]
  );

  useEffect(() => {
    if (joinCode) {
      void checkCode(joinCode);
    }
  }, [joinCode, checkCode]);

  const onLocalSubmit = () => {
    setLoading(true);
    checkCode(input).catch((e) => {
      console.error(e);
      toast.error("Error: " + t("unexpectedNetworkError"));
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onLocalSubmit();
      }}
      className="flex max-w-xl flex-col items-center gap-4"
    >
      <Typography level="h4">{`${t("giveCodeToJoin.start")} ${CODE_LENGTH}${t("giveCodeToJoin.end")}`}</Typography>
      <Card size="sm">
        <DigitInput value={input} onChange={setInput} />
      </Card>
      <Button
        type="submit"
        disabled={
          loading ||
          input.split("").filter((a) => a != " ").length != CODE_LENGTH
        }
        size="lg"
        loading={loading}
      >
        {t("join")}
      </Button>
      {error && !loading && (
        <Alert color="danger" startDecorator={<Error />}>
          {error}
        </Alert>
      )}
    </form>
  );
}

const validationSchema = z.object({
  email: z.string().email(),
});

function SetEmailStep({
  onEmailSubmit,
}: {
  onEmailSubmit: (email: string) => void;
}) {
  const { t } = useTranslation();
  const user = useMe();
  const mutateMe = useMutateMe();

  const api = useRootApi();

  useEffect(() => {
    if (user != null && user.primaryEmail) {
      onEmailSubmit(user.primaryEmail);
    }
  }, [user, onEmailSubmit]);

  const setLoggedIn = useAuthStore((s) => s.setLoggedIn);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: toFormikValidationSchema(validationSchema),
    onSubmit: async (values) => {
      try {
        const response = await api.post("auth/tempAccount", {
          email: values.email,
        });

        if (response.data.success) {
          setLoggedIn(true);
          void mutateMe();
          onEmailSubmit(values.email);
        } else {
          toast.error("Error: " + t("errors.loginFailed"));
        }
      } catch (e) {
        console.error(e);
        toast.error("Error: " + t("errors.loginFailed"));
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col  gap-4">
      <Typography level="h4">{t("signInOrSignUp")}</Typography>
      <Card style={{ width: "clamp(500px, 25vw, 800px)" }}>
        <FormControl
          error={Boolean(formik.errors.email) && formik.touched.email}
          required
        >
          <FormLabel>{t("emailAddress")}</FormLabel>
          <Input
            type="email"
            {...formik.getFieldProps("email")}
            required
            autoFocus
          />
          {formik.errors.email && formik.touched.email && (
            <FormHelperText>{t("invalidEmailAddress")}</FormHelperText>
          )}
        </FormControl>
      </Card>
      <Button
        type="submit"
        disabled={formik.values.email === "" || !formik.isValid}
        loading={formik.isSubmitting}
        endDecorator={<ChevronRight />}
        className="self-end"
      >
        {t("continue")}
      </Button>
    </form>
  );
}

const DEPARTMENTS = [
  // { label: "Allgemein", value: "general" },
  // { label: "R&D", value: "r_and_d" },
  { label: "sales", value: "sales" },
  { label: "accounting", value: "accounting" },
  { label: "purchasing", value: "purchasing" },
  { label: "logistics", value: "logistics" },
  { label: "management", value: "management" },
  { label: "hr", value: "hr" },
  { label: "marketing", value: "marketing" },
  { label: "IT", value: "it" },
  { label: "others", value: "other" },
];

function CompleteDetailsStep({
  onDetailsFilled,
}: {
  onDetailsFilled: () => void;
}) {
  const { t } = useTranslation();
  const orgApi = useOrganizationApi();
  const user = useUser("me");
  const academyProfile = useAcademyProfile();
  const updateAcademyProfile = useUpdateAcademyProfile();

  const detailsValidationSchema = z.object({
    firstName: z
      .string({ required_error: t("enterName") })
      .min(1, t("enterName")),
    lastName: z
      .string({
        required_error: t("enterLastName"),
      })
      .min(1, t("enterLastName")),
    company: z.string().optional(),
    priorKnowledge: z
      .string()
      .nullable()
      .transform((val, ctx): string => {
        if (val == null) {
          ctx.addIssue({
            code: "custom",
            message: t("chooseOption"),
          });
        }
        return val ?? "";
      }),
    department: z
      .string()
      .nullable()
      .transform((val, ctx): string => {
        if (val == null) {
          ctx.addIssue({
            code: "custom",
            message: t("chooseOption"),
          });
        }
        return val ?? "";
      }),
  });

  const onSubmit = async (values) => {
    try {
      // patch all user details
      await orgApi.patch("users/me", {
        firstName: values.firstName,
        lastName: values.lastName || null,
        company: values.company,
        onboarded: true,
      });

      await updateAcademyProfile({
        knowledgeLevel: values.priorKnowledge,
        department: values.department,
      });

      onDetailsFilled();
    } catch (e) {
      console.error(e);
      toast.error(t("errors.profileUpdateFailed"));
    }
  };

  // If the user already has details filled out, skip this step
  useEffect(() => {
    if (
      user?.firstName &&
      user?.lastName &&
      academyProfile?.knowledgeLevel &&
      academyProfile?.department
    ) {
      onDetailsFilled();
    }
  }, [academyProfile, onDetailsFilled, user]);

  if (!user || academyProfile === undefined)
    return <Typography>{t("loading")}...</Typography>;

  return (
    <Formik
      initialValues={{
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        company: user?.company ?? "",
        priorKnowledge: academyProfile?.knowledgeLevel ?? null,
        department: academyProfile?.department ?? null,
      }}
      validationSchema={toFormikValidationSchema(detailsValidationSchema)}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form className="flex flex-col gap-4">
            <Typography level="h4">{t("moreQuestions")}</Typography>

            <Card style={{ width: "clamp(500px, 25vw, 800px)" }}>
              <div className="flex w-full flex-wrap gap-2 overflow-hidden ">
                <FormControl
                  className="flex-1"
                  required
                  error={
                    Boolean(formik.errors.firstName) &&
                    Boolean(formik.touched.firstName)
                  }
                >
                  <FormLabel>{t("firstName")}</FormLabel>
                  <Field as={Input} name="firstName" autoFocus />
                  <ErrorMessage name="firstName" component={FormHelperText} />
                </FormControl>
                <FormControl
                  className="flex-1"
                  required
                  error={
                    Boolean(formik.errors.lastName) &&
                    Boolean(formik.touched.lastName)
                  }
                >
                  <FormLabel>{t("lastName")}</FormLabel>
                  <Field as={Input} name="lastName" />
                  <ErrorMessage name="lastName" component={FormHelperText} />
                </FormControl>
              </div>
              <FormControl>
                <FormLabel>{t("company")} (optional)</FormLabel>
                <Field as={Input} name="company" />
              </FormControl>

              <FormControl
                required
                error={
                  Boolean(formik.errors.priorKnowledge) &&
                  Boolean(formik.touched.priorKnowledge)
                }
              >
                <FormLabel>ChatGPT {t("priorKnowledge")}</FormLabel>
                <Select
                  placeholder={t("priorKnowledgePlaceholder")}
                  defaultValue={formik.values.priorKnowledge}
                  onChange={(event, val) =>
                    formik.setFieldValue("priorKnowledge", val)
                  }
                >
                  <Option value="no_prior_knowledge">{t("never")}</Option>
                  <Option value="medium">{t("aLittle")}</Option>
                  <Option value="high">{t("frequently")}</Option>
                </Select>
                <ErrorMessage
                  name="priorKnowledge"
                  component={FormHelperText}
                />
              </FormControl>
              <FormControl
                required
                error={
                  Boolean(formik.errors.department) &&
                  Boolean(formik.touched.department)
                }
              >
                <FormLabel>{t("Department")}</FormLabel>
                <Select
                  placeholder={t("DepartmentPlaceholder")}
                  defaultValue={formik.values.department}
                  onChange={(event, val) =>
                    formik.setFieldValue("department", val)
                  }
                >
                  {DEPARTMENTS.map((dep) => (
                    <Option key={dep.value} value={dep.value}>
                      {t(dep.label)}
                    </Option>
                  ))}
                </Select>
                <FormHelperText>{t("department.ifNotFound")}</FormHelperText>
                <ErrorMessage
                  name="priorKnowledge"
                  component={FormHelperText}
                />
              </FormControl>
            </Card>
            <Button
              type="submit"
              disabled={!formik.isValid}
              loading={formik.isSubmitting}
              endDecorator={<ChevronRight />}
              className="self-end"
            >
              {t("continue")}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

const CODE_LENGTH = 6;

function DigitInput({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: CODE_LENGTH,
    value,
    onChange,
  });

  return (
    <div className="flex flex-row gap-2">
      {new Array(CODE_LENGTH).fill({}).map((_, i) => (
        <input
          key={i}
          autoFocus={i == 0}
          {...digits[i]}
          className="w-9 border-b-2 border-b-black bg-transparent py-2 text-center text-3xl outline-none focus:border-b-blue-500"
        />
      ))}
    </div>
  );
}

function JoinWorkshopStep({ joinCode }: { joinCode: string }) {
  const api = useRootApi();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [workshop, setWorkshop] = useState<ApiWorkshop | null>(null);

  useEffect(() => {
    const fetchWorkshop = async () => {
      const res = await api.post("workshopUsers/joinWorkshop/" + joinCode);
      setWorkshop(res.data);
    };

    fetchWorkshop().catch(console.error);
  }, [api, joinCode]);

  async function onJoinClicked() {
    navigate("/:organizationId/learn/:workshopId", {
      params: {
        workshopId: workshop?.id ?? "",
        organizationId: "academy",
      },
    });
  }

  return (
    <div className="flex flex-col gap-4">
      <Card className="flex min-w-[300px]">
        <Typography color="neutral" textAlign="center" mb={0}>
          {t("welcomeToWorkshop")}
        </Typography>
        <Typography level="h4" className="text-center" mt={0}>
          {workshop?.name}
        </Typography>
      </Card>
      <Button
        endDecorator={<ChevronRight />}
        onClick={onJoinClicked}
        className=" self-end"
      >
        {t("join")}
      </Button>
    </div>
  );
}
