import { z } from "zod";
import { ApiTextModel } from "./Message";

export const WorkflowInput = z.object({
  key: z.string(),
  name: z.string(),
  type: z
    .enum(["short_text", "long_text", "enum", "toggle"])
    .default("short_text"),
  options: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      }),
    )
    .default([]),
});

export const WorkflowStep = z.object({
  id: z.string(),
  order: z.number().int(),
  promptTemplate: z.string(),
});

export const Workflow = z.object({
  id: z.string().cuid(),
  name: z.string(),
  description: z.string().nullable(),
  modelOverride: ApiTextModel.nullable(),
  index: z.number(),
  departmentId: z.string(),
  folderId: z.string().nullable(),
  estimatedMinutesSaved: z.number().nullable().optional(),
  includedDocuments: z.array(z.object({ id: z.string() })),
  steps: z.array(WorkflowStep),
  allowDocumentUpload: z.boolean().default(false),
  inputs: z.array(WorkflowInput).nullable().default([]),
});

export type Workflow = z.infer<typeof Workflow>;
export type WorkflowInput = z.infer<typeof WorkflowInput>;
export type WorkflowStep = z.infer<typeof WorkflowStep>;
