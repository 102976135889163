import { trpc } from "./trpc/trpc";
import { useCurrentOrganizationId } from "./trpc/helpers/useCurrentOrganizationId";
import { useNavigate } from "../../router";

export function useOrganization() {
  return trpc.organization.getOrganization.useQuery({
    organizationId: useCurrentOrganizationId(),
  }).data;
}

export function usePartOfCurrentOrganization() {
  const navigate = useNavigate();
  const { error: orgError } = trpc.organization.getOrganization.useQuery({
    organizationId: useCurrentOrganizationId(),
  });

  if (
    orgError &&
    ["NOT_FOUND", "FORBIDDEN"].includes(orgError.data?.code ?? "")
  ) {
    navigate("/", { replace: true });
  }
}
