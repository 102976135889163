import {
  CropLandscape,
  CropPortrait,
  CropSquare,
  Info,
  Wallpaper,
} from "@mui/icons-material";
import {
  AspectRatio,
  Button,
  Card,
  FormControl,
  FormLabel,
  Skeleton,
  Switch,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useState } from "react";

import { useGenerateImage } from "../../../lib/api/images";
import type { ImageRequest } from "apiTypes";
import { ImageResponse } from "apiTypes";
import { BeatLoader } from "react-spinners";
import { usePrimaryColor } from "../../../lib/hooks/useTheme";
import { useTranslation } from "../../../lib/i18n";

const sizes = (t: (key: string) => string) =>
  [
    {
      key: "1024x1024",
      name: t("square"),
      icon: <CropSquare />,
    },
    {
      key: "1792x1024",
      name: t("images.wide"),
      icon: <CropLandscape />,
    },
    {
      key: "1024x1792",
      name: t("images.high"),
      icon: <CropPortrait />,
    },
  ] as {
    key: ImageRequest["size"];
    name: string;
    icon: React.ReactNode;
  }[];

export default function ImageTool() {
  const { t } = useTranslation();
  const [prompt, setPrompt] = useState("");
  const [selectedSize, setSelectedSize] = useState<ImageRequest["size"]>(
    sizes(t)[0].key
  );

  const primaryColor = usePrimaryColor();

  const [lastSettings, setLastSettings] = useState<ImageRequest | null>(null);

  const selectedAspectRation = selectedSize
    .split("x")
    .map(Number)
    .reduce((a, b) => a / b);

  const [loading, setLoading] = useState(false);

  const [result, setResult] = useState<string | null>(null);

  const [quality, setQuality] = useState<"standard" | "hd">("standard");

  const generateImage = useGenerateImage();

  const maxWidth = 350;

  const onGenerate = (regenerate = false) => {
    const options = regenerate
      ? lastSettings!
      : ({
          prompt,
          size: selectedSize,
          model: "dall-e-3",
          quality,
          style: "vivid",
        } as const);
    setLastSettings(options);
    setLoading(true);
    generateImage(options)
      .then((res) => {
        const data = ImageResponse.parse(res.data);
        setResult(data.url);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex flex-col gap-10 p-10">
      <Typography level="h1">{t("generateImage")}</Typography>
      <div className="flex flex-row gap-10">
        <div className="flex flex-col gap-6">
          <FormControl>
            <FormLabel>{t("prompt")}</FormLabel>
            <Textarea
              minRows={3}
              maxRows={6}
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder={t("images.promptPlaceholder")}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("images.size")}</FormLabel>
            <div className="flex flex-row gap-4">
              {sizes(t).map((size) => (
                <Card
                  key={size.key}
                  variant={selectedSize === size.key ? "soft" : "outlined"}
                  color={selectedSize === size.key ? "primary" : "neutral"}
                  sx={{
                    p: 1.5,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setSelectedSize(size.key)}
                >
                  {size.icon}
                  <Typography level="body-sm">{size.name}</Typography>
                </Card>
              ))}
            </div>
          </FormControl>
          <FormControl orientation="horizontal">
            <FormLabel>
              HD{" "}
              <Tooltip title={t("images.hdDescription")}>
                <Info fontSize="small" />
              </Tooltip>
            </FormLabel>
            <Switch
              checked={quality === "hd"}
              onChange={(e) => setQuality(e.target.checked ? "hd" : "standard")}
            />
          </FormControl>
          <Button
            onClick={() => onGenerate()}
            disabled={loading || prompt.length == 0}
          >
            {loading ? t("generating") : t("generate")}
          </Button>
        </div>
        <div className="flex flex-col items-start gap-4">
          {!loading && !result && (
            <Card
              sx={{
                width: maxWidth,
                height: maxWidth / selectedAspectRation,
                fontSize: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Wallpaper fontSize="inherit" />
              <Typography textAlign="center" level="body-lg">
                {t("images.generateHint")}
              </Typography>
            </Card>
          )}
          {!loading && result && (
            <>
              <img
                src={result}
                alt={prompt}
                style={{
                  width: maxWidth,
                }}
              />
              <div className="flex flex-row gap-4">
                <a href={result} download target="_blank" rel="noreferrer">
                  <Button color="primary" variant="outlined">
                    {t("save")}
                  </Button>
                </a>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onGenerate(true)}
                >
                  {t("regenerate")}
                </Button>
              </div>
            </>
          )}
          {loading && (
            <div className="relative">
              <div className="absolute z-10 flex h-full w-full items-center justify-center">
                <BeatLoader size="20" color={primaryColor} />
              </div>
              <AspectRatio
                ratio={selectedAspectRation}
                sx={{
                  width: maxWidth,
                  height: maxWidth / selectedAspectRation,
                }}
              >
                <Skeleton>
                  <img
                    style={{
                      width: maxWidth,
                      height: maxWidth / selectedAspectRation,
                    }}
                    className="-z-10"
                    src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                    alt="Loading image"
                  />
                </Skeleton>
              </AspectRatio>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
