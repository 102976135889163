import { Button, Typography } from "@mui/joy";

import { useNavigate, useParams } from "../../../router";
import { ChevronRight } from "@mui/icons-material";
import { getPayloadId, useCourse } from "../../../lib/api/learning";
import { useTranslation } from "react-i18next";

export default function CourseIndex() {
  const course = useCourse(
    useParams("/:organizationId/learn/:workshopId/course/:courseId").courseId
  );
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId"
  );
  const onLetsGetStarted = () => {
    navigate(
      "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId",
      {
        params: {
          workshopId: params.workshopId,
          courseId: params.courseId,
          organizationId: params.organizationId,
          exerciseId:
            getPayloadId(
              course?.exercises.sort((a, b) => a.order - b.order)[0].exercise ??
                0
            ) + "",
        },
      }
    );
  };

  return (
    <div className="flex flex-grow flex-col items-center justify-center">
      <Typography level="body-lg">{t("welcomeTo")}</Typography>
      <Typography level="h1">{course?.title}</Typography>
      <Typography level="body-md" mt={5} className="max-w-lg text-center">
        {course?.description}
      </Typography>
      <div className="pt-10">
        <Button size="lg" onClick={onLetsGetStarted}>
          <div className="flex flex-row items-center gap-2">
            {t("letsStart")}
            <ChevronRight />
          </div>
        </Button>
      </div>
    </div>
  );
}
