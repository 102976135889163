import { Language, Summarize, Translate, Wallpaper } from "@mui/icons-material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { List, Typography } from "@mui/joy";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useEnabledServices } from "../../lib/api/services";
import { useTranslation } from "../../lib/i18n";
import { comparePath } from "../../lib/routeUtils";
import type { ModalPath, Path } from "../../router";
import { useModals, useNavigate, useParams } from "../../router";
import { TreeItem } from "./tree/LeafItem";

type Tool = {
  name: string;
  icon: React.ReactNode;
  checkEnabled?: (
    services: NonNullable<ReturnType<typeof useEnabledServices>>
  ) => boolean;
  new?: boolean;
} & (
  | {
      path: Path;
    }
  | {
      modal: ModalPath;
    }
);

export default function ToolsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams("/:organizationId");
  const path = useLocation().pathname;
  const enabled = useEnabledServices();
  const modals = useModals();

  const tools: Tool[] = useMemo(
    () => [
      {
        name: t("generateImage"),
        icon: <Wallpaper fontSize="small" />,
        path: "/:organizationId/tools/imageFactory",
        checkEnabled: (services) => services.dalle3,
      },
      {
        name: t("researchAssistant"),
        icon: <Language fontSize="small" />,
        path: "/:organizationId/tools/researchAssistant",
        checkEnabled: (services) =>
          services.textModels.includes("llama-3-sonar-large-32k-online"),
      },
      {
        name: t("transcriptSummarizer"),
        icon: <Summarize fontSize="small" />,
        path: "/:organizationId/tools/transcriptSummarizer",
        checkEnabled: (services) =>
          services.textModels.includes("gemini-1.5-pro-preview-0409") &&
          services.meetingSummarizer,
        new: true,
      },
      {
        name: t("supportAssistant.title"),
        icon: <SupportAgentIcon fontSize="small" />,
        path: "/:organizationId/tools/supportAssistant",
        checkEnabled: (services) =>
          services.textModels.includes("gemini-1.5-pro-preview-0409") &&
          services.helpdesk,
      },
      {
        name: t("translateText"),
        icon: <Translate fontSize="small" />,
        path: "/:organizationId/tools/translateText",
        checkEnabled: (services) => services.translateText,
      },
      // {
      //   name: t("diagramCreator"),
      //   icon: <AccountTree />,
      //   modal: "/[organizationId]/tools/diagramcreator",
      //   new: true,
      // },
    ],
    [t]
  );

  if (!enabled) return null;

  const enabledTools = tools.filter((tool) => {
    if (!tool.checkEnabled) return true;
    return tool.checkEnabled(enabled);
  });

  if (enabledTools.length === 0) return null;

  return (
    <>
      <Typography
        level="title-md"
        color="neutral"
        sx={{
          px: 2,
          py: 1,
        }}
      >
        {t("aiTools")}
      </Typography>
      <List size="sm">
        {enabledTools.map((tool) => (
          <TreeItem
            selected={"path" in tool ? comparePath(path, tool.path) : false}
            key={tool.name}
            icon={tool.icon}
            name={tool.name}
            onClick={() => {
              if ("modal" in tool) {
                modals.open(tool.modal, {
                  params,
                });
                return;
              } else {
                navigate(tool.path, {
                  params,
                });
              }
            }}
          />
        ))}
      </List>
    </>
  );
}
