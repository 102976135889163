import { ChevronLeft } from "@mui/icons-material";
import { IconButton, Sheet, Skeleton, Typography } from "@mui/joy";
import { Card, DonutChart, FunnelChart, Metric, Title } from "@tremor/react";
import type { Exercise } from "apiTypes/dist/payload-types";
import { UserMenu } from "../../components/auth/UserMenu.tsx";
import { DelayedLoader } from "../../components/util/DelayadLoader.tsx";
import { unwrapPayloadArray, useCourse } from "../../lib/api/learning.ts";
import { useOrganizationSchemaResource } from "../../lib/hooks/useApi.tsx";
import { useLoggedInOnly } from "../../lib/hooks/useLoggedInOnly.tsx";
import { Link, useParams } from "../../router.ts";
import {
  CourseStatistics,
  CourseFunnelStatistics,
  ExerciseStatistics,
} from "apiTypes";
import { useTranslation } from "react-i18next";

export default function CourseInsightsPage() {
  useLoggedInOnly();
  const { t } = useTranslation();
  const { courseId, organizationId } = useParams(
    "/:organizationId/teach/course/:courseId"
  );

  const courseMetrics = useOrganizationSchemaResource(
    `elearning/analytics/course/${courseId}`,
    CourseStatistics
  );

  const funnelData = useOrganizationSchemaResource(
    `elearning/analytics/course/${courseId}/funnel`,
    CourseFunnelStatistics
  );

  const course = useCourse(courseId);

  const exercises = unwrapPayloadArray<Exercise>(
    (course?.exercises ?? []).map((e) => e.exercise)
  );

  return (
    <Sheet variant="soft" className="h-full min-h-screen w-full px-8 py-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <IconButton
            component={Link}
            to={{ pathname: "/:organizationId/teach", search: "tab=1" }}
            params={{ organizationId }}
          >
            <ChevronLeft />
          </IconButton>
          <Typography level="h3">
            {course == null ? (
              <Skeleton>{t("courseTitleLoading")}</Skeleton>
            ) : (
              course.title
            )}
          </Typography>
        </div>
        <UserMenu languageSelector />
      </div>
      <main className="py-4">
        <Typography level="h3" mb={2}>
          {t("courseStatistics")}
        </Typography>
        <div className="mb-8 flex gap-8">
          <Card>
            <Title>{t("started")}</Title>
            <Metric>
              {courseMetrics?.attempts} {t("times")}
            </Metric>
          </Card>
          <Card>
            <Title>{t("cancelled")}</Title>
            <Metric>
              {courseMetrics?.abandons} {t("times")}
            </Metric>
          </Card>
          <Card>
            <Title>{t("abandonmentRate")}</Title>
            <Metric>
              {(
                (courseMetrics?.abandons ?? 0) / (courseMetrics?.attempts ?? 1)
              ).toLocaleString("de", {
                style: "percent",
                maximumFractionDigits: 2,
              })}
            </Metric>
          </Card>
          <Card>
            <Title>{t("helpRequests")}</Title>
            <Metric>{courseMetrics?.helps}</Metric>
          </Card>
        </div>
        <Card>
          {funnelData != null ? (
            <FunnelChart
              evolutionGradient
              gradient={false}
              className="h-60"
              data={funnelData}
            />
          ) : (
            <div className="h-60">
              <DelayedLoader />
            </div>
          )}
        </Card>
        <Typography level="h3" mb={2} mt={4}>
          {t("tasks")}
        </Typography>
        <div
          className="grid gap-4"
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
          }}
        >
          {exercises.map((exercise) => (
            <ExerciseStats key={exercise.id} exercise={exercise} />
          ))}
        </div>
      </main>
    </Sheet>
  );
}

function KPI({ value, title }: { value: string | number; title: string }) {
  return (
    <div className="min-w-fit overflow-hidden">
      <Title className="text-gray-500">{title}</Title>
      <Metric className="whitespace-nowrap">{value}</Metric>
    </div>
  );
}

function ExerciseStats({ exercise }: { exercise: Exercise }) {
  const metrics = useOrganizationSchemaResource(
    `elearning/analytics/exercise/${exercise.id}`,
    ExerciseStatistics
  );
  const { t } = useTranslation();
  if (metrics == null) return null;

  const chartData = [
    { name: t("solved"), value: metrics.solvedCount },
    { name: t("skipped"), value: metrics.skipCount },
    { name: t("cancelled"), value: metrics.abandonCount },
    {
      name: t("noData"),
      value:
        metrics.startCount -
        metrics.skipCount -
        metrics.solvedCount -
        metrics.abandonCount,
    },
  ];

  function formatDuration(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    if (minutes === 0) {
      return `${remainingSeconds}s`;
    } else {
      return `${minutes}m ${remainingSeconds}s`;
    }
  }

  return (
    <Card className="overflow-hidden">
      <Typography level="h4" mb={4}>
        {exercise.title}
      </Typography>
      <div className="flex items-center gap-6">
        <DonutChart
          data={chartData}
          valueFormatter={(v) => v + " User"}
          category="value"
          index="name"
          colors={["green", "yellow", "red", "gray"]}
          className="col-span-2 row-span-2 w-40 "
        />
        <div className="grid grid-cols-2 gap-4">
          <KPI title={t("requestHelp")} value={metrics.helpRequests} />
          <KPI
            title={t("averageTimeToSolve")}
            value={formatDuration(metrics.averageTimeToSolve)}
          />
          <KPI
            title={t("attemptToSolve")}
            value={(
              metrics.solutionAttempts / metrics.solvedCount
            ).toLocaleString("de", { maximumFractionDigits: 2 })}
          />
          <KPI
            title={t("timeForAttempt")}
            value={formatDuration(
              metrics.averageTimeToSolve /
                (metrics.solutionAttempts / metrics.solvedCount)
            )}
          />
        </div>
      </div>
    </Card>
  );
}
