import { Button, Card, Checkbox, Stack, Textarea, Typography } from "@mui/joy";
import { ApiTextModelEnum as allModelsEnum } from "apiTypes";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { trpc } from "../../lib/api/trpc/trpc";
import { useParams } from "react-router-dom";
import { useCurrentOrganizationId } from "../../lib/api/trpc/helpers/useCurrentOrganizationId";
import { useTranslation } from "react-i18next";
import { ALL_SERVICES } from "../../../../../packages/apiTypes/src/AllServices";
import { TextSnippet } from "@mui/icons-material";
import { DEFAULT_GUIDELINES } from "../../lib/constants/DEFAULT_GUIDELINES";

export function ProductConfig() {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const utils = trpc.useUtils();
  const { data: productConfig } = trpc.productConfig.getProductConfig.useQuery({
    organizationId: useCurrentOrganizationId(),
  });
  const mutateProductConfig =
    trpc.productConfig.mutateProductConfig.useMutation().mutateAsync;

  const allModels = allModelsEnum.options;
  const enabledModels = productConfig?.enabledModels ?? [];
  const toggleModel = (model: string) => {
    void mutateProductConfig({
      organizationId: organizationId ?? "",
      enabledModels: enabledModels?.includes(model)
        ? enabledModels.filter((m) => m !== model)
        : [...enabledModels, model],
    }).then(() => {
      void utils.invalidate();
    });
  };

  const toggleService = (service: string) => {
    void mutateProductConfig({
      organizationId: organizationId ?? "",
      [service]: !productConfig?.[service],
    }).then(() => {
      void utils.invalidate();
    });
  };

  const [guidelinesInput, setGuidelinesInput] = useState("");
  useEffect(() => {
    if (productConfig) {
      setGuidelinesInput(productConfig?.guidelines ?? "");
    }
  }, [productConfig]);
  const saveGuidelines = () => {
    void mutateProductConfig({
      organizationId: organizationId ?? "",
      guidelines: guidelinesInput,
    }).then(() => {
      toast.success(t("guidelinesSaved"));
    });
  };

  const noProductConfig =
    productConfig === null ? t("errors.noProductConfig") : "Loading...";
  return (
    <>
      <Typography level="h3">{t("productConfigs")}</Typography>
      <Card>
        {!productConfig ? (
          <Typography>{noProductConfig}</Typography>
        ) : (
          <>
            <Typography level="h4">LLMs:</Typography>
            {allModels.map((model) => (
              <Checkbox
                checked={enabledModels?.includes(model) ?? false}
                onChange={() => {
                  toggleModel(model);
                }}
                label={model}
                key={model}
              />
            ))}
            <Typography level="h4">AI-Apps:</Typography>
            {ALL_SERVICES.map((service) => (
              <Checkbox
                label={service}
                checked={productConfig[service]}
                key={service}
                onChange={() => {
                  toggleService(service);
                }}
              />
            ))}
            <Stack direction="row" justifyContent="space-between">
              <Typography level="h4">GPT-Guidelines:</Typography>
              <Button
                endDecorator={<TextSnippet />}
                onClick={() => {
                  setGuidelinesInput(DEFAULT_GUIDELINES);
                }}
              >
                {t("defaultTemplate")}
              </Button>
            </Stack>
            <Textarea
              placeholder="Guidelines..."
              sx={{
                height: "800px",
                "&>textarea": { overflowY: "scroll !important" },
                padding: "30px",
              }}
              value={guidelinesInput}
              onChange={(e) => {
                setGuidelinesInput(e.target.value);
              }}
            />
            <Button
              onClick={saveGuidelines}
              disabled={productConfig.guidelines === guidelinesInput}
            >
              {t("saveGuidelines")}
            </Button>
          </>
        )}
      </Card>
    </>
  );
}
