import { z } from "zod";

export const GenerateTranscriptInput = z.object({
  documentId: z.string(),
  length: z.enum(["short", "appropriate", "comprehensive"]),
  components: z.object({
    executiveSummary: z.boolean(),
    participants: z.boolean(),
    agenda: z.boolean(),
    actionItems: z.boolean(),
  }),
  language: z.enum(["en", "de"]).catch("en").default("en"),
});

export type GenerateTranscriptInput = z.infer<typeof GenerateTranscriptInput>;
