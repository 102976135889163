import { z } from "zod";

export const DallE3ImageSize = z.union([
  z.literal("1024x1024"),
  z.literal("1792x1024"),
  z.literal("1024x1792"),
]);

export const ImageRequest = z.object({
  model: z.literal("dall-e-3"),
  prompt: z.string(),
  quality: z
    .union([z.literal("standard"), z.literal("hd")])
    .default("standard"),
  size: DallE3ImageSize.default("1024x1024"),
  style: z.union([z.literal("vivid"), z.literal("natural")]).default("vivid"),
});

export const ImageResponse = z.object({
  url: z.string(),
});

export type ImageRequest = z.infer<typeof ImageRequest>;
export type ImageResponse = z.infer<typeof ImageResponse>;
export type DallE3ImageSize = z.infer<typeof DallE3ImageSize>;

export function getSizeAspectRatio(size: DallE3ImageSize) {
  const [width, height] = size.split("x").map((n) => Number(n));
  return width / height;
}

export const MultiImageRequest = ImageRequest.extend({
  variations: z.number().default(1),
  template: z.string().default(""),
});
export type MultiImageRequest = z.infer<typeof MultiImageRequest>;
