import { Delete, Help } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Card,
  FormLabel,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import type { WorkflowStep } from "apiTypes";
import cuid from "cuid";
import type { ReactNode } from "react";
import { useState } from "react";
import Editor from "react-simple-code-editor";
import { useTranslation } from "../../lib/i18n";

export function WorkflowStepsEditor({
  steps,
  onChange,
}: {
  steps: WorkflowStep[];
  onChange: (values: WorkflowStep[]) => void;
}) {
  const { t } = useTranslation();
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  function setSteps(newValue: WorkflowStep[]) {
    const reordered = newValue
      .sort((a, b) => a.order - b.order)
      .map((s, i) => ({ ...s, order: i }));
    onChange(reordered);
  }

  function addStep() {
    const newStep: WorkflowStep = {
      id: cuid(),
      order: steps.length,
      promptTemplate: "",
    };
    setSteps([...steps, newStep]);
  }

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography level="h3">{t("workflowEditor.steps")}</Typography>
          <Typography color="neutral" mb={2}>
            {t("workflowEditor.stepsDescription")}
          </Typography>
        </Stack>
        <IconButton
          className="self-center"
          onClick={() => setHelpModalOpen(true)}
        >
          <Help />
        </IconButton>
      </Stack>
      <Card sx={{ gap: 3 }}>
        {steps.length == 0 && (
          <Typography
            className="self-center "
            level="body-lg"
            color="neutral"
            my={2}
          >
            {t("workflowEditor.noSteps")}
          </Typography>
        )}
        {steps.map((step) => (
          <WorkflowStepEditor
            key={step.id}
            step={step}
            onChange={(updatedStep) => {
              setSteps(
                steps.map((s) => (s.id === updatedStep.id ? updatedStep : s))
              );
            }}
            onDelete={() => {
              setSteps(steps.filter((s) => s.id !== step.id));
            }}
          />
        ))}
        <Button
          startDecorator={<AddIcon />}
          className="self-end"
          onClick={addStep}
        >
          {t("workflowEditor.newStep")}
        </Button>
      </Card>
      <Modal open={helpModalOpen} onClose={() => setHelpModalOpen(false)}>
        <ModalDialog>
          <ModalClose />
          <Typography level="h4">
            {t("workflowEditor.stepsHelpTitle")}
          </Typography>
          <Typography>{t("workflowEditor.stepsHelpBody")}</Typography>
        </ModalDialog>
      </Modal>
    </Stack>
  );
}

function highlight(code: string): ReactNode {
  // highlight sections which look like {{this}}. The regex for this is /{{(.*?)}}/g
  // and the replacement is <span style="color: red;">$1</span>

  return code.split("\n").map((line, i) => (
    <div
      key={i}
      style={{
        color: "black",
      }}
    >
      {line.trim() === "" ? <br /> : null}
      {line.split(/{{(.*?)}}/g).map((part, j) => {
        if (j % 2 == 0) {
          return part;
        }
        return (
          <span
            style={{
              color: "var(--joy-palette-primary-500)",
              fontWeight: "bold",
            }}
            key={j}
          >
            {"{{"}
            {part}
            {"}}"}
          </span>
        );
      })}
    </div>
  ));
}

function WorkflowStepEditor({
  step,
  onChange,
  onDelete,
}: {
  step: WorkflowStep;
  onChange: (step: WorkflowStep) => void;
  onDelete: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2">
      <div className="grow">
        <FormLabel sx={{ mb: 1 }}>
          {t("workflowEditor.stepTitle", { step: step.order + 1 })}
        </FormLabel>
        <Card className="!font-mono text-lg">
          <Editor
            textareaClassName="!outline-none"
            value={step.promptTemplate}
            onValueChange={(prompt) =>
              onChange({ ...step, promptTemplate: prompt })
            }
            highlight={(code) => highlight(code)}
          />
        </Card>
      </div>
      <IconButton className="self-center" onClick={onDelete}>
        <Delete />
      </IconButton>
    </div>
  );
}
