import ClearIcon from "@mui/icons-material/Clear";
import { Button, IconButton, Switch, Table, Typography } from "@mui/joy";
import type { ApiDepartmentUser } from "apiTypes";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  useDepartmentUsers,
  useMutateDepartmentUsers,
} from "../../../lib/api/deparment";
import { useUser } from "../../../lib/api/user";
import { useOrganizationApi } from "../../../lib/hooks/useApi";
import { useTranslation } from "../../../lib/i18n";
import { DelayedLoader } from "../../util/DelayadLoader";
import { UserSearch } from "../../util/user/UserSearch";

export function DepartmentUserEditor({
  departmentId,
}: {
  departmentId: string;
}) {
  const { t } = useTranslation();
  const users = useDepartmentUsers(departmentId);
  const mutate = useMutateDepartmentUsers(departmentId);

  if (!users) return <DelayedLoader />;

  return (
    <div className="flex flex-col gap-6">
      <Typography level="title-lg">{t("manageUsers")}</Typography>

      <UserAdder departmentId={departmentId} onMutate={mutate} />

      <div className="max-h-96 overflow-y-auto">
        <Table stickyHeader>
          <thead>
            <tr>
              <th>{t("name")}</th>
              <th>E-Mail</th>
              <th
                style={{
                  width: "20%",
                }}
              >
                {t("writePermissions")}
              </th>
              <th
                style={{
                  width: "5%",
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => (
              <UserRow
                user={user}
                key={user.userId}
                onMutate={mutate}
                departmentId={departmentId}
              />
            ))}
            {users?.length === 0 && (
              <tr>
                <td colSpan={3}>{t("noUsersFound")}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

function UserRow({
  user: departmentUser,
  departmentId,
  onMutate,
}: {
  user: ApiDepartmentUser;
  departmentId: string;
  onMutate: () => void;
}) {
  const { t } = useTranslation();

  const user = useUser(departmentUser.userId);
  const organizationApi = useOrganizationApi();
  const setWritePermission = (value: boolean) => {
    toast
      .promise(
        organizationApi.patch(
          `/departments/${departmentId}/users/${user?.id}`,
          {
            writePermission: value,
          }
        ),
        {
          success: t("permissionsUpdated"),
          error: t("permissionsUpdateFailed"),
        }
      )
      .then(() => onMutate())
      .catch(console.error);
  };

  const removeUser = () => {
    toast
      .promise(
        organizationApi.delete(
          `/departments/${departmentId}/users/${user?.id}`
        ),
        {
          success: t("userRemoved"),
          error: t("userRemoveFailed"),
        }
      )
      .then(() => onMutate())
      .catch(console.error);
  };

  if (!user) return <DelayedLoader />;

  return (
    <tr>
      <td>
        {user.firstName} {user.lastName}
      </td>
      <td>{user.primaryEmail}</td>
      <td>
        <SegmentSwitch
          writePermission={departmentUser.writePermission}
          setWritePermission={setWritePermission}
        />
      </td>
      <td className="flex flex-row items-center justify-end">
        <IconButton onClick={removeUser}>
          <ClearIcon />
        </IconButton>
      </td>
    </tr>
  );
}

function SegmentSwitch({
  writePermission,
  setWritePermission,
}: {
  writePermission: boolean;
  setWritePermission: (value: boolean) => void;
}) {
  return (
    <Switch
      checked={writePermission}
      onChange={(e) => {
        setWritePermission(e.target.checked);
      }}
    />
  );
}

function UserAdder({
  departmentId,
  onMutate,
}: {
  departmentId: string;
  onMutate: () => void;
}) {
  const { t } = useTranslation();

  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const api = useOrganizationApi();
  const users = useDepartmentUsers(departmentId);

  const addUser = () => {
    toast
      .promise(
        api.put(`/departments/${departmentId}/users/${selectedUserId}`),
        {
          success: t("userAdded"),
          error: t("userAddFailed"),
        }
      )
      .then(() => {
        setSelectedUserId(null);
        onMutate();
      })
      .catch(console.error);
  };

  return (
    <div className="flex flex-row gap-2">
      <UserSearch
        blacklist={users?.map((u) => u.userId)}
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
      />
      <Button disabled={!selectedUserId} onClick={addUser}>
        {t("add")}
      </Button>
    </div>
  );
}
