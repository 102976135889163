import { Attachment, CropSquare, Send } from "@mui/icons-material";
import { Button, IconButton, Textarea } from "@mui/joy";
import type { ComponentProps } from "react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../lib/i18n";
import { create } from "zustand";
import { useUploadDocumentWithToast } from "../../lib/api/documents";
import { DocumentPreview } from "./attachments/DocumentPreview";
import { useEnabledServices } from "../../lib/api/services";
import { allowedMimeTypesForAdiDocuments } from "./attachments/mime";
import { twMerge } from "tailwind-merge";

export const useQueuedPlaceholderStore = create<{
  placeholder: string | null;
  queuePlaceholder: (placeholder: string) => void;
  clearPlaceholder: () => void;
}>((set) => ({
  placeholder: null,
  queuePlaceholder: (placeholder) => set({ placeholder }),
  clearPlaceholder: () => set({ placeholder: null }),
}));

export const ChatInput = React.forwardRef(
  (
    {
      postMessage,
      startDecorator,
      disabled,
      onCancel,
      isGenerating,
      allowDocumentUpload = true,
      showAttachmentButton = true,
      embedded = false,
      value,
      setValue,
      ...textAreaProps
    }: {
      postMessage: (message: string, attachmentIds: string[]) => void;
      onCancel?: () => void;
      isGenerating?: boolean;
      showAttachmentButton?: boolean;
      startDecorator?: React.ReactNode;
      allowDocumentUpload?: boolean;
      embedded?: boolean;
      value?: string;
      setValue?: (value: string) => void;
    } & ComponentProps<typeof Textarea>,
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    const [_input, _setInput] = useState("");
    const input = value ?? _input;
    const setInput = setValue ?? _setInput;
    const { placeholder, clearPlaceholder } = useQueuedPlaceholderStore();

    const enabledServices = useEnabledServices();

    useEffect(() => {
      if (
        placeholder &&
        typeof placeholder == "string" &&
        placeholder.length > 0
      ) {
        setInput(placeholder);
        clearPlaceholder();
      }
    }, [placeholder, clearPlaceholder]);

    const send = () => {
      if (input.replace(/\na/g, "").trim() === "") return;

      postMessage(input.trim(), attachedDocumentIds);
      setInput("");
      setAttachedDocumentIds([]);
    };

    const attachmentUploaderRef = useRef<HTMLInputElement>(null);

    const [attachedDocumentIds, setAttachedDocumentIds] = useState<string[]>(
      []
    );
    const [numLoadingAttachments, setNumLoadingAttachments] = useState(0);

    const hasAttachedDocuments =
      attachedDocumentIds.length > 0 || numLoadingAttachments > 0;

    const uploadDocument = useUploadDocumentWithToast();

    const onAddAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      setNumLoadingAttachments((prev) => prev + 1);

      uploadDocument(file)
        .then((doc) => {
          setAttachedDocumentIds((prev) => [...prev, doc.id]);
        })
        .catch(console.error)
        .finally(() => {
          setNumLoadingAttachments((prev) => prev - 1);
        });

      // clear the input
      e.target.value = "";
    };

    const { t } = useTranslation();

    const isDisabled = disabled || numLoadingAttachments > 0;

    return (
      <div
        className={twMerge(
          "flex flex-row items-center justify-stretch gap-3",
          embedded && "gap"
        )}
        id="messageInput"
      >
        {startDecorator}
        <input
          type="file"
          name="file"
          id="attachment"
          accept={allowedMimeTypesForAdiDocuments}
          hidden
          ref={attachmentUploaderRef}
          onChange={onAddAttachment}
        />
        <Textarea
          className="flex-1"
          sx={{
            // paddingY: "20px",
            display: "flex",
            flexDirection: hasAttachedDocuments ? "column" : "row",
            alignItems: hasAttachedDocuments ? "start" : "center",
          }}
          slotProps={{
            textarea: {
              ref,
              sx: {
                paddingY: embedded ? "5px" : "20px",
              },
            },
            startDecorator: {
              sx: {
                paddingX: "0px",
                margin: "0px",
                marginRight: "10px",
                height: "100%",
              },
            },
          }}
          placeholder={t("composeMessage")}
          autoFocus
          maxRows={18}
          minRows={1}
          onKeyDown={(e) => {
            // submit the form on control+enter
            if (input.replace(/\na/g, "").trim() === "") return;

            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              if (!isDisabled) send();
            }
          }}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          {...textAreaProps}
          startDecorator={
            <div className="flex flex-row items-center gap-2">
              {attachedDocumentIds.map((id) => (
                <DocumentPreview
                  documentId={id}
                  key={id}
                  onRemove={() => {
                    setAttachedDocumentIds(
                      attachedDocumentIds.filter((d) => d !== id)
                    );
                  }}
                />
              ))}
              {numLoadingAttachments > 0 &&
                new Array(numLoadingAttachments)
                  .fill({})
                  .map((_, i) => <DocumentPreview key={i} loading />)}
              {enabledServices?.documentIntelligence &&
                showAttachmentButton &&
                allowDocumentUpload && (
                  <div
                    style={{
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      "--Icon-fontSize": "20px",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        attachmentUploaderRef.current?.click();
                      }}
                      size="sm"
                    >
                      <Attachment fontSize="medium" />
                    </IconButton>
                  </div>
                )}
            </div>
          }
        ></Textarea>
        {isGenerating && !!onCancel ? (
          <Button onClick={onCancel} variant="outlined">
            <div className="flex flex-row items-center gap-2">
              <CropSquare fontSize="small" />
              {input.length > 0 ? t("resend") : t("cancel")}
            </div>
          </Button>
        ) : (
          <Button
            className="flex flex-row items-center gap-2"
            onClick={send}
            disabled={isDisabled}
          >
            <Send fontSize="small" />
            {!embedded && t("sendMessage")}
          </Button>
        )}
      </div>
    );
  }
);

ChatInput.displayName = "ChatInput";
