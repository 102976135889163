import { Edit, PlayCircle } from "@mui/icons-material";
import { Tooltip } from "@mui/joy";
import { useState } from "react";
import { toast } from "react-toastify";
import { useCreateChat } from "../../../lib/api/chat";
import { useDepartment, useMutateDepartment } from "../../../lib/api/deparment";
import { useEnabledServices } from "../../../lib/api/services";
import { useCurrentOrganizationId } from "../../../lib/api/trpc/helpers/useCurrentOrganizationId.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useQueuedMessagesStore } from "../../../lib/context/queuedMessagesStore";
import { useTranslation } from "../../../lib/i18n";
import { useNavigate, useParams } from "../../../router";
import { replaceAll } from "../../util/polyfill";
import { TreeItem } from "./LeafItem";
import { RunWorkflowModal } from "./RunWorkflowModal.tsx";

export function WorkflowItem({ workflowId }: { workflowId: string }) {
  const { t } = useTranslation();

  const { data: workflow } = trpc.workflows.getById.useQuery({
    organizationId: useCurrentOrganizationId(),
    workflow: { id: workflowId },
  });

  const navigate = useNavigate();
  const { mutateAsync: deleteWorkflow } = trpc.workflows.delete.useMutation();
  const createChat = useCreateChat();
  const mutateDepartment = useMutateDepartment(
    workflow?.departmentId ?? "personal"
  );
  const params = useParams("/:organizationId");
  const department = useDepartment(workflow?.departmentId ?? "personal");

  const onDelete = async () => {
    await deleteWorkflow({
      organizationId: params.organizationId,
      workflow: { id: workflowId },
    });
    await mutateDepartment();

    toast.success(t("workflowDeleted"));
  };

  const enabledServices = useEnabledServices();

  const hasInputs =
    (workflow?.inputs?.length ?? 0) > 0 ||
    (workflow?.allowDocumentUpload && enabledServices?.documentIntelligence);

  const [modalOpen, setModalOpen] = useState(false);

  const { mutateAsync: trackUsage } =
    trpc.workflows.trackWorkflowExecution.useMutation();
  const enqueueMessage = useQueuedMessagesStore((s) => s.addQueuedMessage);
  const clearMessageQueue = useQueuedMessagesStore((s) => s.clear);

  if (!workflow) return;

  const hasSteps = workflow.steps.length > 0;

  const onInit = async (
    values: Record<string, string>,
    attachmentIds: string[]
  ) => {
    setModalOpen(false);

    attachmentIds = [
      ...new Set([
        ...attachmentIds,
        ...(workflow.includedDocuments ?? []).map((doc) => doc.id),
      ]),
    ];

    const chat = await createChat({
      name: workflow.name,
      modelOverride: workflow.modelOverride,
      departmentId: workflow.departmentId,
    });

    trackUsage({
      organizationId: params.organizationId,
      workflow: { id: workflowId },
    }).catch(console.error);

    navigate("/:organizationId/chats/:chatId", {
      params: {
        organizationId: params.organizationId,
        chatId: chat.data.id,
      },
    });

    if (hasSteps) {
      clearMessageQueue();
      workflow.steps.forEach((step, index) => {
        let content = step.promptTemplate;

        // Idea: replace with handlebars
        if (content.length > 0) {
          for (const input of workflow.inputs ?? []) {
            content = replaceAll(
              content,
              `{{${input.key}}}`,
              values[input.key]
            );
          }
          enqueueMessage({
            content,
            chatId: chat.data.id,
            attachmentIds: index === 0 ? attachmentIds : undefined,
          });
        }
      });
    }

    toast.success(t("workflowExecuted"));
  };

  const onClick = () => {
    if (hasInputs) {
      setModalOpen(true);
    } else {
      onInit({}, []).catch(console.error);
    }
  };

  return (
    <>
      <TreeItem
        selected={false}
        icon={<PlayCircle fontSize="small" />}
        name={workflow.name}
        onClick={onClick}
        onDelete={onDelete}
        canDelete={department?.writePermission}
        endDecorator={
          department?.writePermission && (
            <Tooltip title={t("edit")}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/:organizationId/workflows/:workflowId", {
                    params: {
                      organizationId: params.organizationId,
                      workflowId: workflowId,
                    },
                  });
                }}
              >
                <Edit fontSize="small" />
              </div>
            </Tooltip>
          )
        }
      />
      {hasInputs && hasSteps && (
        <RunWorkflowModal
          workflow={workflow}
          open={modalOpen}
          setOpen={setModalOpen}
          onSubmit={onInit}
        />
      )}
    </>
  );
}
