import type { ApiCreateCompletedCourse } from "apiTypes";
import { ApiXP } from "apiTypes";
import {
  useOrganizationApi,
  useRootSWR,
  useRootSchemaResource,
} from "../hooks/useApi";
import { calcLevelProgress, normalizeCurrentProgress } from "apiTypes";

export type UserXP = {
  xp: number;
  currentLevel: number;
  currentLevelProgess: number;
  xpNeededForNextLevel: number;
  normalizedProgress: number;
  isNewLevel: boolean;
  normalizedProgressBefore: number;
};

export type FinishCourseResponse = {
  data: {
    completedCourse?: {
      awardedXp: number;
    };
    userXp?: number;
  };
  status?: number;
};

export function useXP() {
  return useRootSchemaResource("academy/gamification/xp", ApiXP);
}

export function useMutateXP() {
  return useRootSWR("academy/gamification/xp").mutate;
}

export function useFinishCourse() {
  const mutate = useMutateXP();
  const api = useOrganizationApi();
  return async (request: ApiCreateCompletedCourse) => {
    const resp = await api.post("elearning/courses/finish", request);
    await mutate();
    return resp;
  };
}

/**
 * Get all the necessary data for the user's XP
 */
export function calcUserXP(xp: number, awardedXP: number) {
  const userLevel = calcLevelProgress(xp);
  const userLevelBefore = calcLevelProgress(xp - awardedXP);

  const isNewLevel = userLevelBefore.currentLevel < userLevel.currentLevel;
  const normalized = normalizeCurrentProgress(
    userLevel.currentLevelProgess,
    userLevel.currentLevel
  );
  let normalizedBefore = normalizeCurrentProgress(
    userLevelBefore.currentLevelProgess,
    userLevelBefore.currentLevel
  );
  if (normalizedBefore >= normalized) {
    normalizedBefore = 0;
  }

  const userData: UserXP = {
    xp: xp,
    currentLevel: userLevel.currentLevel,
    currentLevelProgess: userLevel.currentLevelProgess,
    xpNeededForNextLevel: userLevel.xpNeededForNextLevel,
    normalizedProgress: normalized,
    isNewLevel: isNewLevel,
    normalizedProgressBefore: normalizedBefore,
  };
  return userData;
}
