import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  IconButton,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import type { ApiMessage, ApiChat } from "apiTypes";
import { ApiTextModel } from "apiTypes";
import { useRef, useState } from "react";
import { ContentCopy, Edit, Replay, Send } from "@mui/icons-material";
import { UserAvatar } from "../auth/UserMenu";
import { MarkdownRenderer } from "./MarkdownRenderer";
import { useTranslation } from "../../lib/i18n";
import { BounceLoader } from "react-spinners";
import { usePrimaryColor } from "../../lib/hooks/useTheme";
import { useSmoothTypingText } from "./useSmoothTypincText";
import "./ChatMessage.scss";
import { DocumentPreview } from "./attachments/DocumentPreview";
import { twMerge } from "tailwind-merge";
import { useCopySafe } from "../../lib/hooks/useCopySafe";
import { Citation } from "./Citation";
import { modelMeta } from "../../lib/modelMeta";
import { useOrganization } from "../../lib/api/organization";

export function ChatMessage({
  chat,
  message,
  onRegenerate,
  onEdit,
  embedded,
}: {
  chat: ApiChat;
  message: ApiMessage;
  onRegenerate: () => void;
  onEdit: (content: string) => void;
  embedded?: boolean;
}) {
  const { t } = useTranslation();

  const messageContentRef = useRef<HTMLDivElement>(null);
  const [editing, setEditing] = useState(false);

  const m = message;
  const citations = m.citations;

  if (citations.length > 0) {
    m.content.replace(/\[(\d+)\]/g, (match, number) => {
      return `[#${number}]`; // could also display an icon with a number
    });
  }

  const [editedContent, setEditedContent] = useState(m.content);

  const generating = m.fromAi && !m.responseCompleted;

  const smoothContent = useSmoothTypingText(m.content);
  const content = generating ? smoothContent : m.content;

  const onEditSafe = (newContent: string) => {
    if (
      newContent.trim().length > 0 &&
      newContent.trim() !== m.content.trim()
    ) {
      onEdit(newContent);
    }
    setEditing(false);
    setEditedContent(m.content);
  };

  const organization = useOrganization();
  const currentModel = chat?.modelOverride
    ? ApiTextModel.parse(chat.modelOverride)
    : organization?.defaultModel;

  const model: ApiTextModel = (m.generationModel ??
    currentModel ??
    "gpt-3.5-turbo") as ApiTextModel;
  const gptSrc = modelMeta[model]?.icon;

  const primaryColor = usePrimaryColor();

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={
        twMerge(
          "mx-10 my-5 flex flex-row items-start gap-6",
          embedded && "mx-0 my-2 gap-2"
        ) + (m.fromAi ? " aiMessage" : "")
      }
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-4">
          {(!embedded || m.fromAi) && (
            <div>
              {!m.fromAi ? (
                <UserAvatar userId={m.authorId} />
              ) : (
                <Avatar variant="outlined" src={gptSrc} />
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={twMerge(
          "flex-grow basis-0 overflow-hidden",
          embedded && m.fromAi && "pr-10",
          embedded && !m.fromAi && "pl-20"
        )}
      >
        <Card
          size={embedded ? "sm" : undefined}
          key={m.id}
          color={m.fromAi ? "primary" : "neutral"}
          variant={
            // eslint-disable-next-line no-nested-ternary
            embedded ? "soft" : m.fromAi && !generating ? "outlined" : "plain"
          }
          className={twMerge(
            "group flex flex-1 flex-col items-stretch overflow-x-auto"
          )}
        >
          <div className="flex flex-row gap-2">
            {m.attachmentIds.map((id) => (
              <DocumentPreview documentId={id} key={id} />
            ))}
          </div>
          <div
            ref={messageContentRef}
            className=""
            style={{
              minHeight: !embedded ? "3rem" : "0",
            }}
          >
            <div className="pr-7">
              <Typography color="neutral" className="">
                {editing && (
                  <Textarea
                    value={editedContent}
                    onChange={(e) => setEditedContent(e.target.value)}
                    onKeyDown={(e) => {
                      // submit the form on control+enter

                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        onEditSafe(editedContent.trim());
                      }
                    }}
                    autoFocus
                    className="w-full"
                  />
                )}
                {m.cancelled && (
                  <div>
                    <Typography level="body-sm" fontStyle="italic">
                      {t("messageCancelled")}
                    </Typography>
                  </div>
                )}
                {!editing && !m.cancelled && (
                  <>
                    <MarkdownRenderer content={content} />
                    <Citation citations={citations} />
                  </>
                )}
              </Typography>
            </div>
          </div>
          {generating && (
            <div className="absolute bottom-0 left-0 px-4 py-2">
              <BounceLoader color={primaryColor} key="spinner" size={20} />
            </div>
          )}
          <div className="absolute bottom-0 right-0 p-2">
            {m.fromAi && m.responseCompleted && (
              <AiMessageOptions
                onRegenerate={onRegenerate}
                messageContentRef={messageContentRef}
                content={m.content}
              />
            )}
            {!m.fromAi && (
              <UserMessageOptions
                onEdit={() => {
                  setEditing(true);
                }}
                onEditSafe={onEditSafe}
                editedContent={editedContent}
                isEditing={editing}
              />
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

function UserMessageOptions({
  onEdit,
  onEditSafe,
  editedContent,
  isEditing,
}: {
  onEdit: () => void;
  onEditSafe: (newContent: string) => void;
  editedContent: string;
  isEditing?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center gap-2 opacity-0 transition-all group-hover:opacity-100">
      {isEditing ? (
        <Tooltip title={t("sendMessage")}>
          <IconButton
            size="sm"
            onClick={() => onEditSafe(editedContent.trim())}
          >
            <Send />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t("editMessage")}>
          <IconButton size="sm" onClick={onEdit}>
            <Edit />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

function AiMessageOptions({
  messageContentRef,
  content,
  onRegenerate,
}: {
  messageContentRef: React.RefObject<HTMLDivElement>;
  content: string;
  onRegenerate: () => void;
}) {
  const { t } = useTranslation();
  const copy = useCopySafe();

  return (
    <div className="flex flex-row items-center gap-2 opacity-0 transition-all group-hover:opacity-100">
      <Tooltip
        variant="outlined"
        title={
          <ButtonGroup orientation="vertical" variant="plain">
            <Button
              onClick={() => {
                copy(content);
              }}
            >
              {t("copyMarkdown")}
            </Button>
            <Button
              onClick={() => {
                copy(messageContentRef.current?.innerText ?? "");
              }}
            >
              {t("copyText")}
            </Button>
          </ButtonGroup>
        }
      >
        <IconButton
          size="sm"
          onClick={() => {
            copy(
              messageContentRef.current?.innerHTML ?? "",
              messageContentRef.current?.innerText ?? ""
            );
          }}
        >
          <ContentCopy />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("regenerate")}>
        <IconButton size="sm" onClick={onRegenerate}>
          <Replay />
        </IconButton>
      </Tooltip>
    </div>
  );
}
