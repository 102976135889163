import {
  Stack,
  Modal,
  ModalDialog,
  Typography,
  Button,
  ModalClose,
  ModalOverflow,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Option,
} from "@mui/joy";
import { useState } from "react";
import type { Workflow, WorkflowInput } from "apiTypes";
import { Form } from "react-router-dom";
import { useTranslation } from "../../../lib/i18n";
import { MarkdownRenderer } from "../../chat/MarkdownRenderer";
import { DocumentDropzone } from "../../chat/attachments/DocumentDropzone";
import { useEnabledServices } from "../../../lib/api/services";

export function RunWorkflowModal({
  workflow,
  open,
  setOpen,
  onSubmit,
}: {
  workflow: Workflow;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (values: Record<string, string>, documentIds: string[]) => void;
}) {
  const { t } = useTranslation();
  const enabledServices = useEnabledServices();

  const [values, setValues] = useState<Record<string, string>>({});

  const [attachedDocumentIds, setAttachedDocumentIds] = useState<string[]>([]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalOverflow>
        <ModalDialog
          sx={{
            width: "60vw",
            height: "60vh",
          }}
        >
          <ModalClose />

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(values, attachedDocumentIds);
            }}
          >
            <Stack spacing={3}>
              <div className="flex flex-col gap-1">
                <Typography level="h4">{workflow.name}</Typography>
                <Typography level="body-md">
                  <MarkdownRenderer
                    content={workflow.description ?? ""}
                  ></MarkdownRenderer>
                </Typography>
              </div>
              {workflow.allowDocumentUpload &&
                enabledServices?.documentIntelligence && (
                  <FormControl>
                    <FormLabel>{t("documents.documents")}</FormLabel>
                    <DocumentDropzone
                      documentIds={attachedDocumentIds}
                      setDocumentIds={setAttachedDocumentIds}
                    />
                  </FormControl>
                )}
              {(workflow.inputs ?? []).map((input) => {
                return (
                  <WorkflowInputField
                    input={input}
                    key={input.key}
                    value={values[input.key]}
                    onChange={(value) => {
                      setValues((v) => ({
                        ...v,
                        [input.key]: value,
                      }));
                    }}
                  />
                );
              })}
              <Button
                type="submit"
                disabled={
                  Object.keys(values).filter((val) => values[val] !== "")
                    .length !== workflow.inputs?.length
                }
              >
                {t("execute")}
              </Button>
            </Stack>
          </Form>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}

export function WorkflowInputField({
  input,
  value,
  onChange,
}: {
  input: WorkflowInput;
  value: string;
  onChange: (value: string) => void;
}) {
  const { t } = useTranslation();

  if (input.type === "short_text")
    return (
      <FormControl>
        <FormLabel>{input.name}</FormLabel>
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Eingabe..."
        />
      </FormControl>
    );

  if (input.type === "long_text")
    return (
      <FormControl>
        <FormLabel>{input.name}</FormLabel>
        <Textarea
          minRows={3}
          maxRows={10}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Eingabe..."
        />
      </FormControl>
    );

  if (input.type === "enum")
    return (
      <FormControl>
        <FormLabel>{input.name}</FormLabel>

        <Select value={value}>
          {input.options.map((option) => (
            <Option
              value={option.value}
              key={option.value + option.label}
              onClick={() => {
                onChange(option.value);
              }}
            >
              {option.label}
            </Option>
          ))}
        </Select>
      </FormControl>
    );

  if (input.type === "toggle") {
    const options = [t("on"), t("off")];
    if (!options.includes(value)) {
      onChange(options[1]);
    }
    return (
      <FormControl>
        <FormLabel>{input.name}</FormLabel>
        <ButtonGroup>
          {options.map((state) => (
            <Button
              variant={state === value ? "solid" : "soft"}
              color={state === value ? "primary" : "neutral"}
              key={input.name + state}
              onClick={() => {
                onChange(state);
              }}
            >
              {state}
            </Button>
          ))}
        </ButtonGroup>
      </FormControl>
    );
  }
}
