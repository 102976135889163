import { AppRegistration, Check } from "@mui/icons-material";
import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import Typography from "@mui/joy/Typography";
import type { ExerciseWithCompletion } from "../../lib/api/learning.ts";
import { useNavigate, useParams } from "../../router.ts";
import { ErrorDisplay } from "../util/ErrorDisplay.tsx";
import { useTranslation } from "react-i18next";

export function ExerciseStepperItem({
  exercise,
  index,
  isNextToSolve,
  lastChild,
}: {
  exercise: ExerciseWithCompletion | number;
  index: number;
  isNextToSolve: boolean;
  lastChild: boolean;
}) {
  const { t } = useTranslation();

  if (typeof exercise === "number") throw new Error("Invalid exercise");

  const navigate = useNavigate();
  const params = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  );

  const completed = exercise.completed;

  if (!exercise)
    return (
      <ErrorDisplay error="This exercise reference is null. Please check your course setup in payload." />
    );

  let state: string,
    variant: "solid" | "soft",
    color: "primary" | "neutral" | "success";

  if (completed) {
    state = "completed";
    color = "success";
    variant = "solid";
  } else if (isNextToSolve) {
    state = "active";
    color = "primary";
    variant = "solid";
  } else {
    state = "idle";
    color = "neutral";
    variant = "soft";
  }

  const selected = params.exerciseId === exercise.id + "";

  return (
    <Step
      slotProps={{
        root: {
          "data-last-child": lastChild ? "true" : undefined,
          onClick: () => {
            if (state === "idle") return;
            navigate(
              `/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId`,
              {
                params: {
                  workshopId: params.workshopId,
                  organizationId: params.organizationId,
                  courseId: params.courseId,
                  exerciseId: String(exercise.id),
                },
              }
            );
          },
          sx: {
            cursor: state != "idle" ? "pointer" : undefined,
          },
        },
      }}
      completed={state === "completed"}
      active={selected}
      indicator={
        <StepIndicator variant={variant} color={color}>
          {state === "completed" && <Check />}
          {state === "active" && <AppRegistration />}
          {state === "idle" && index + 1}
        </StepIndicator>
      }
    >
      <div>
        <Typography level="title-sm">
          {t("task")} {index + 1}
        </Typography>
        <span className="line-clamp-2">{exercise.title}</span>
      </div>
    </Step>
  );
}
