import {
  Button,
  Card,
  CircularProgress,
  IconButton,
  Input,
  Sheet,
  Typography,
} from "@mui/joy";
import { useTranslation } from "../../../lib/i18n";
import { useCallback, useRef, useState } from "react";
import { MarkdownRenderer } from "../../../components/chat/MarkdownRenderer";
import { Citation } from "../../../components/chat/Citation";
import { MeinGPTLogo } from "../../../components/branding/BrandedLogo";
import { Close, Search } from "@mui/icons-material";
import { trpc } from "../../../lib/api/trpc/trpc";
import { useCurrentOrganizationId } from "../../../lib/api/trpc/helpers/useCurrentOrganizationId";

export default function WebSearchUI() {
  const { t } = useTranslation();

  const [query, setQuery] = useState<string>("");

  const search = trpc.tools.webSearch.queryLLM.useMutation();
  const [response, setResponse] = useState<string>("");
  const [citations, setCitations] = useState<string[]>([]);

  const [generating, setGenerating] = useState(false);

  const currentOrgId = useCurrentOrganizationId();

  const cancelCurrent = useRef<() => void>(() => {});

  const onCreate = useCallback(
    async (searchQuery: string) => {
      if (!searchQuery) return;

      cancelCurrent.current();
      let cancelled = false;
      cancelCurrent.current = () => {
        cancelled = true;
      };

      setResponse("");
      setCitations([]);
      setGenerating(true);

      const stream = await search.mutateAsync({
        query: searchQuery,
        organizationId: currentOrgId,
      });

      for await (const chunk of stream) {
        if (cancelled) return;
        setResponse(chunk.fullMessage);
        setCitations(chunk.citations ?? []);
      }
      if (cancelled) return;
      setGenerating(false);
    },
    [search, currentOrgId]
  );

  const hasAnswer = search.isPending || search.isSuccess;

  const searchBar = (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await onCreate(query);
      }}
    >
      <div className="flex flex-row items-center justify-stretch gap-2">
        <Input
          className=" flex-1"
          sx={{
            width: "300px",
          }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t("search")}
          endDecorator={
            query ? (
              <IconButton onClick={() => setQuery("")} aria-label={t("clear")}>
                <Close />
              </IconButton>
            ) : undefined
          }
          autoFocus
          fullWidth
        />
        <Button startDecorator={<Search />} type="submit">
          {t("search")}
        </Button>
      </div>
    </form>
  );

  return (
    <Sheet variant="soft" className="min-h-screen">
      {hasAnswer ? (
        <div className="flex flex-col justify-center gap-6 p-20">
          <div className="flex flex-row items-center gap-6">
            <MeinGPTLogo width={150} />
            {searchBar}
          </div>
          <div className="max-w-7xl">
            <Typography level="body-md" mb={2}>
              {t("resultsFor")} &quot;{search.variables?.query}&quot;
            </Typography>
            <Card>
              <MarkdownRenderer content={response} />
              {generating && <CircularProgress />}
              <Citation citations={citations} />
            </Card>
          </div>
        </div>
      ) : (
        <div className="flex h-screen w-full flex-col items-center justify-center">
          <div className="flex w-[600px] flex-col items-center gap-6">
            <div className="flex w-full flex-col items-center">
              <MeinGPTLogo width={400} />
            </div>
            {searchBar}
            <Typography level="body-sm" color="neutral">
              {t("researchAssistantDisclaimer")}
            </Typography>
          </div>
        </div>
      )}
    </Sheet>
  );
}
