import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Table,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { toast } from "react-toastify";
import { z } from "zod";
import {
  useCreateEmailInvite,
  useDeleteEmailInvite,
  useEmailInvites,
} from "../../../lib/api/invites";
import { useTranslation } from "../../../lib/i18n";
import { DomainsEditor } from "./DomainsEditor";
import { useMe } from "../../../lib/api/user";
import { useCurrentOrganizationId } from "../../../lib/api/trpc/helpers/useCurrentOrganizationId";
import { trpc } from "../../../lib/api/trpc/trpc";
import { DelayedLoader } from "../../util/DelayadLoader";
import { ContentCopy } from "@mui/icons-material";
import { useCopySafe } from "../../../lib/hooks/useCopySafe";

export function InvitesEditor() {
  const { t } = useTranslation();
  const invites = useEmailInvites();
  const user = useMe();

  const deleteEmailInvite = useDeleteEmailInvite();

  return (
    <div className="flex flex-col gap-4">
      {user?.isGlobalAdmin && (
        <>
          <Card>
            <DomainsEditor />
          </Card>
          <Card>
            <InviteLinksEditor />
          </Card>
        </>
      )}
      <Card>
        <div className="flex flex-col gap-4">
          <Typography level="h3">{t("settings.tabs.invites")}</Typography>
          <InviteNewUser />
          <Table variant="soft">
            <thead>
              <tr>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invites?.map((invite) => (
                <tr key={invite.email}>
                  <td>{invite.email}</td>
                  <td className="text-right">
                    <Button
                      color="danger"
                      onClick={() => {
                        deleteEmailInvite(invite.email).catch((error) => {
                          toast.error(error.message);
                          throw error;
                        });
                      }}
                    >
                      {t("cancel")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {invites?.length === 0 && (
            <Typography level="body-sm" className="text-center">
              {t("settings.invitesEditor.noInvites")}
            </Typography>
          )}
        </div>
      </Card>
    </div>
  );
}

function InviteNewUser() {
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState<string>("");
  const createEmailInvite = useCreateEmailInvite();

  function isValid(): boolean {
    if (emailInput.includes(",")) {
      const mails = emailInput.split(",").map((mail) => mail.trim());
      return z.array(z.string().email()).safeParse(mails).success;
    } else {
      return z.string().email().safeParse(emailInput).success;
    }
  }

  async function onSubmit(e) {
    e.preventDefault();

    let mails: string | string[] = emailInput;
    if (emailInput.includes(",")) {
      mails = emailInput.split(",").map((mail) => mail.trim());
    }

    createEmailInvite(mails).catch((error) => {
      console.error(error);
      throw error;
    });
    setEmailInput("");
  }

  return (
    <div className="flex flex-col gap-1">
      <form onSubmit={onSubmit} className="my-2 flex flex-row items-end gap-3">
        <FormControl>
          <FormLabel>{t("settings.invitesEditor.inviteNewUser")}</FormLabel>
          <Input
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            sx={{ width: "100%" }}
          />
        </FormControl>
        <Button type="submit" disabled={!isValid()}>
          {t("invite")}
        </Button>
      </form>
      <FormHelperText sx={{ width: "500px", mb: 2 }}>
        {t("settings.invitesEditor.inviteNewUserDescription")}
      </FormHelperText>
    </div>
  );
}

function InviteLinksEditor() {
  const orgId = useCurrentOrganizationId();
  const inviteLinkQuery = trpc.organizationInvites.getJoinTokens.useQuery({
    organizationId: orgId,
  });
  const utils = trpc.useUtils();
  const updateJoinTokensMutation =
    trpc.organizationInvites.updateJoinTokens.useMutation();

  if (inviteLinkQuery.isLoading) {
    return <DelayedLoader />;
  }

  return (
    <div className="flex flex-col gap-2">
      <Typography level="h4">Invite Links</Typography>
      <Typography level="title-md">Link for Members</Typography>
      <InviteLinkDisplay
        joinToken={inviteLinkQuery.data?.memberJoinToken ?? null}
        onEnable={async () => {
          await updateJoinTokensMutation.mutateAsync({
            organizationId: orgId,
            memberJoinToken: "enable",
          });
          void utils.organizationInvites.invalidate();
        }}
        onDisable={async () => {
          await updateJoinTokensMutation.mutateAsync({
            organizationId: orgId,
            memberJoinToken: "disable",
          });
          void utils.organizationInvites.invalidate();
        }}
      />
      <Typography level="title-md">Link for Admins</Typography>

      <InviteLinkDisplay
        joinToken={inviteLinkQuery.data?.adminJoinToken ?? null}
        onEnable={async () => {
          await updateJoinTokensMutation.mutateAsync({
            organizationId: orgId,
            adminJoinToken: "enable",
          });
          void utils.organizationInvites.invalidate();
        }}
        onDisable={async () => {
          await updateJoinTokensMutation.mutateAsync({
            organizationId: orgId,
            adminJoinToken: "disable",
          });
          void utils.organizationInvites.invalidate();
        }}
      />
    </div>
  );
}

function InviteLinkDisplay({
  joinToken,
  onEnable,
  onDisable,
}: {
  joinToken: string | null;
  onEnable: () => void;
  onDisable: () => void;
}) {
  const fullUrl = `${window.location.origin}/invites?joinToken=${joinToken}`;

  const copy = useCopySafe();

  return (
    <div>
      <p>
        {joinToken ? (
          <>
            {fullUrl}
            <IconButton
              onClick={() => {
                copy(fullUrl);
              }}
            >
              <ContentCopy />
            </IconButton>
          </>
        ) : (
          "Disabled"
        )}
      </p>
      <div className="flex flex-row gap-2">
        <Button onClick={onEnable} size="sm">
          {joinToken ? "Regenerate" : "Enable"}
        </Button>
        {joinToken && (
          <Button
            onClick={onDisable}
            size="sm"
            variant="outlined"
            color="danger"
          >
            Disable
          </Button>
        )}
      </div>
    </div>
  );
}
