import { Sheet, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { DepartmentsEditor } from "../../components/settings/deparments/DepartmentsEditor";
import { GeneralSettings } from "../../components/settings/GeneralSettings";
import { UsersEditor } from "../../components/settings/users/UsersEditor";
import { useTranslation } from "../../lib/i18n";
import { InvitesEditor } from "../../components/settings/users/InvitesEditor";
import { MockModulesDisplay } from "../../components/settings/MockModules";
import { CreditDisplay } from "../../components/settings/CreditDisplay";
import { useEnabledServices } from "../../lib/api/services";
import { useMemo } from "react";
import { useSearchParamsState } from "../../lib/hooks/useSearchParamsState";
import { useMe } from "../../lib/api/user";
import { EnableAcademy } from "../../components/settings/EnableAcademy";
import { ProductConfig } from "../../components/settings/ProductConfig";

export default function OrganisationSettings() {
  const [activeTab, setActiveTab] = useSearchParamsState("tab", "general");
  const { t } = useTranslation();
  const services = useEnabledServices();
  const user = useMe();

  const settingsTabs = useMemo(() => {
    const tabs: {
      label: string;
      component: React.ReactNode;
      path: string;
    }[] = [
      {
        label: t("settings.tabs.general"),
        component: <GeneralSettings />,
        path: "general",
      },
      {
        label: t("settings.tabs.departments"),
        component: <DepartmentsEditor />,
        path: "departments",
      },
      {
        label: t("settings.tabs.users"),
        component: <UsersEditor />,
        path: "users",
      },
      {
        label: t("settings.tabs.invites"),
        component: <InvitesEditor />,
        path: "invites",
      },
      {
        label: t("settings.tabs.modules"),
        component: <MockModulesDisplay />,
        path: "modules",
      },
    ];
    if (services?.creditSystem) {
      tabs.push({
        label: t("settings.tabs.credits"),
        component: <CreditDisplay />,
        path: "credits",
      });
    }
    if (user?.isGlobalAdmin) {
      tabs.push({
        label: "Academy",
        component: <EnableAcademy />,
        path: "academy",
      });
      tabs.push({
        label: "Product Config",
        component: <ProductConfig />,
        path: "product-config",
      });
    }
    return tabs;
  }, [services?.creditSystem, t, user]);

  return (
    <Sheet className="min-h-screen" variant="soft">
      <div className="flex flex-col items-center px-10 pt-10">
        <div className="container flex flex-col gap-10">
          <Typography level="h2">{t("settings.literal")}</Typography>
          <Tabs
            sx={{
              minWidth: 600,
              height: "100%",
            }}
            size="sm"
            style={{
              backgroundColor: "transparent",
            }}
            value={Math.max(
              settingsTabs.findIndex((tab) => tab.path === activeTab),
              0
            )}
            onChange={(_, newValue) =>
              setActiveTab(
                settingsTabs.find((tab, i) => i === newValue)?.path ?? "general"
              )
            }
          >
            <TabList>
              {settingsTabs.map((tab, i) => (
                <Tab
                  key={tab.label}
                  sx={{
                    px: 6,
                    py: 1,
                  }}
                  value={i}
                >
                  {tab.label}
                </Tab>
              ))}
            </TabList>
            {settingsTabs.map((tab, i) => (
              <TabPanel key={tab.label} value={i} className="pb-20">
                <div className="h-full overflow-y-auto pt-5">
                  {tab.component}
                </div>
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </div>
    </Sheet>
  );
}
