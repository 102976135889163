import { z } from "zod";
import { ApiTextModel } from "./Message";

export const EnabledServices = z.object({
  textModels: z.array(ApiTextModel),
  dalle3: z.boolean(),
  documentIntelligence: z.boolean(),
  academy: z.boolean(),
  creditSystem: z.boolean(),
  entraId: z.boolean(),
  helpdesk: z.boolean(),
  meetingSummarizer: z.boolean(),
  translateText: z.boolean(),
});

export type EnabledServices = z.infer<typeof EnabledServices>;
