import { ChevronLeft } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Card, Typography } from "@mui/joy";
import Step, { stepClasses } from "@mui/joy/Step";
import StepIndicator, { stepIndicatorClasses } from "@mui/joy/StepIndicator";
import Stepper from "@mui/joy/Stepper";
import { typographyClasses } from "@mui/joy/Typography";

import type { Payload } from "apiTypes";
import { ErrorDisplay } from "../../../../components/util/ErrorDisplay.tsx";
import { useCourse, useCourseProgress } from "../../../../lib/api/learning.ts";

import { Link, useParams } from "../../../../router.ts";
import { useTranslation } from "react-i18next";

export function EvaluationResultIcon({ passed }: { passed: boolean | null }) {
  if (passed === null) return null;

  return passed ? (
    <CheckCircleIcon color="success" />
  ) : (
    <CancelIcon color="error" />
  );
}

function ExerciseStepperItem({
  exercise,
  index,
  lastChild,
}: {
  exercise: Payload.Exercise | number;
  index: number;
  isNextToSolve: boolean;
  lastChild: boolean;
}) {
  const { t } = useTranslation();
  if (typeof exercise === "number") throw new Error("Invalid exercise");

  if (!exercise)
    return <ErrorDisplay error={t("errors.exerciseNullRefernce")} />;

  return (
    <Step
      slotProps={{
        root: {
          "data-last-child": lastChild ? "true" : undefined,
          onClick: () => {
            //TODO Do something on click?
          },
        },
      }}
      completed={false}
      active={false}
      indicator={
        <StepIndicator variant="soft" color="neutral">
          {index + 1}
        </StepIndicator>
      }
    >
      <div>
        <Typography level="title-sm">
          {t("task")} {index + 1}
        </Typography>
        {exercise.title}
      </div>
    </Step>
  );
}

export default function TrainerCourseIndex() {
  const params = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId"
  );

  const { t } = useTranslation();

  const { courseId, organizationId, workshopId } = params;

  const course = useCourse(courseId);

  const courseProgress = useCourseProgress(courseId);

  if (course == null) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <Link
        to="/:organizationId/teach/:workshopId"
        params={{
          workshopId: params.workshopId,
          organizationId,
        }}
      >
        <Button variant="plain" color="neutral" sx={{ px: 0 }}>
          <div className="flex flex-row items-center gap-2">
            <ChevronLeft />
            <span>{t("backToCourseOverview")}</span>
          </div>
        </Button>
      </Link>
      <div className="mb-8">
        <Typography level="h1">{course?.title}</Typography>
        <Typography level="body-md">{course?.description}</Typography>
      </div>
      <div className="flex flex-nowrap items-start gap-20">
        <div className="">
          <Card
            size="lg"
            sx={{
              pr: 10,
              flexShrink: 0,
              maxWidth: "400px",
            }}
          >
            <Link
              to="/:organizationId/learn/:workshopId/course/:courseId"
              params={{ organizationId, courseId, workshopId }}
            >
              <Typography level="title-lg" className="pb-3">
                {course.title}
              </Typography>
            </Link>
            <Stepper
              orientation="vertical"
              sx={{
                "--Stepper-verticalGap": "2.5rem",
                "--StepIndicator-size": "2.5rem",
                "--Step-gap": "1rem",
                "--Step-connectorInset": "0.5rem",
                "--Step-connectorRadius": "1rem",
                "--Step-connectorThickness": "4px",
                "--joy-palette-success-solidBg":
                  "var(--joy-palette-success-400)",
                [`& .${stepClasses.completed}`]: {
                  "&::after": { bgcolor: "success.solidBg" },
                },
                [`& .${stepClasses.active}`]: {
                  [`& .${stepIndicatorClasses.root}`]: {
                    border: "4px solid",
                    borderColor: "#fff",
                    boxShadow: (theme) =>
                      `0 0 0 1px ${theme.vars.palette.primary[500]}`,
                  },
                },
                [`& .${stepClasses.disabled} *`]: {
                  color: "neutral.softDisabledColor",
                },
                [`& .${typographyClasses["title-sm"]}`]: {
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  fontSize: "10px",
                },
              }}
            >
              {course.exercises
                .sort((a, b) => a.order - b.order)
                .map((exercise, i) => (
                  <ExerciseStepperItem
                    key={exercise.id}
                    isNextToSolve={courseProgress?.numCompleted === i}
                    index={i}
                    exercise={exercise.exercise}
                    lastChild={i === course.exercises.length - 1}
                  />
                ))}
            </Stepper>
          </Card>
        </div>
      </div>
    </div>
  );
}
