import { Card, Tooltip } from "@mui/joy";
import { useDocumentHeader } from "../../../lib/api/documents";
import { Close, InsertDriveFile } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { usePrimaryColor } from "../../../lib/hooks/useTheme";
import { DelayedLoader } from "../../util/DelayadLoader";

export function DocumentPreview(
  props:
    | {
        documentId: string;
        onRemove?: () => void;
        loading?: false;
      }
    | {
        loading: true;
      }
) {
  const { t } = useTranslation();

  const primaryColor = usePrimaryColor();

  if (props.loading)
    return (
      <Card
        size="sm"
        variant="soft"
        color="primary"
        className="group/attachment relative"
        sx={{
          "--Icon-fontSize": "18px",
        }}
      >
        <div className="flex flex-row items-center gap-2 text-sm">
          <ClipLoader size={18} color={primaryColor} />
          {t("loading")}...
        </div>
      </Card>
    );

  return <ActualDocumentPreview {...props} />;
}

export function ActualDocumentPreview({
  documentId,
  onRemove,
}: {
  documentId: string;
  onRemove?: () => void;
}) {
  const header = useDocumentHeader(documentId);

  if (!header) return <DelayedLoader />;

  const fileExtension = header.fileName.split(".").pop();
  const fileName = header.fileName.split(".").slice(0, -1).join(".");

  const fileNameCapped =
    fileName.length > 30 ? fileName.slice(0, 20) + "... " : fileName;

  const readableFileSize = (bytes: number) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
      (bytes / Math.pow(1024, i)).toFixed(0) +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  };

  const fileSize = readableFileSize(header.originalSize);

  return (
    <Tooltip title={`${fileName}.${fileExtension} (${fileSize})`}>
      <Card
        size="sm"
        variant="soft"
        color="primary"
        className="group/attachment relative"
        sx={{
          "--Icon-fontSize": "18px",
        }}
      >
        <div className="flex flex-row items-center gap-2 text-sm">
          <InsertDriveFile fontSize="small" />
          {fileNameCapped}.{fileExtension}
          {onRemove && (
            <div
              className="cursor-pointer hover:bg-opacity-40 bg-opacity-0 bg-white rounded px-1"
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
            >
              <Close fontSize="medium" />
            </div>
          )}
        </div>
      </Card>
    </Tooltip>
  );
}
