import type { ReactNode } from "react";
import { DoNotDisturb } from "@mui/icons-material";

import advertising from "./advertising.png";
import illustration from "./illustration.png";
import interior from "./interior.png";
import logo from "./logo.png";
import memphis from "./memphis.png";
import minimalistic from "./minimalistic.png";
import productMockup from "./productmockup.png";
import productPresentation from "./productpresentation.png";
import realistic from "./realistic.png";
import socialMedia from "./socialmedia.png";
import stock from "./stock.png";
import ui from "./ui.png";
import vector from "./vector.png";
import wallpaper from "./wallpaper.png";

export type ImageStyle = {
  promptSuffix: string;
  example: string | ReactNode;
  nameKey: string;
  promptRecommendation: string;
};

export const imageStyles = [
  {
    promptSuffix: "",
    example: (
      <div
        className="flex h-full w-full items-center justify-center"
        style={{
          fontSize: "100px",
        }}
      >
        <DoNotDisturb fontSize="inherit" />
      </div>
    ),
    nameKey: "images.styles.none.label",
    promptRecommendation: "images.styles.none.recommendation",
  },
  {
    promptSuffix: ", high quality marketing image",
    example: advertising,
    nameKey: "images.styles.advertising.label",
    promptRecommendation: "images.styles.advertising.recommendation",
  },
  {
    promptSuffix: ",  styled as a beautiful professional illustration",
    example: illustration,
    nameKey: "images.styles.illustration.label",
    promptRecommendation: "images.styles.illustration.recommendation",
  },
  {
    promptSuffix: ", beautiful interior design, spacious, comfortable",
    example: interior,
    nameKey: "images.styles.interior.label",
    promptRecommendation: "images.styles.interior.recommendation",
  },
  {
    promptSuffix: 'realistic minimal professional logo design: "{{prompt}}"',
    example: logo,
    nameKey: "images.styles.logo.label",
    promptRecommendation: "images.styles.logo.recommendation",
  },
  {
    promptSuffix:
      ", corporate memphis, flat art, minimal, friendly, simple, no details",
    example: memphis,
    nameKey: "images.styles.memphis.label",
    promptRecommendation: "images.styles.memphis.recommendation",
  },
  {
    promptSuffix: ", minimalist style, simple, very few details",
    example: minimalistic,
    nameKey: "images.styles.minimalistic.label",
    promptRecommendation: "images.styles.minimalistic.recommendation",
  },
  {
    promptSuffix: "Product mockup of a {{prompt}}, to be used for R&D purposes",
    example: productMockup,
    nameKey: "images.styles.productMockup.label",
    promptRecommendation: "images.styles.productMockup.recommendation",
  },
  {
    promptSuffix:
      "High quality product photography to be used in a corporate keynote presentation of {{prompt}}",
    example: productPresentation,
    nameKey: "images.styles.productPresentation.label",
    promptRecommendation: "images.styles.productPresentation.recommendation",
  },
  {
    promptSuffix: ", hyperrealistic, photo, unedited, reddit",
    example: realistic,
    nameKey: "images.styles.realistic.label",
    promptRecommendation: "images.styles.realistic.recommendation",
  },
  {
    promptSuffix: ", high quality stock photo for social media",
    example: socialMedia,
    nameKey: "images.styles.socialMedia.label",
    promptRecommendation: "images.styles.socialMedia.recommendation",
  },
  {
    promptSuffix: ", high quality stock photo, realistic",
    example: stock,
    nameKey: "images.styles.stock.label",
    promptRecommendation: "images.styles.stock.recommendation",
  },
  {
    promptSuffix: ", minimalist UI mockup, 2D",
    example: ui,
    nameKey: "images.styles.ui.label",
    promptRecommendation: "images.styles.ui.recommendation",
  },
  {
    promptSuffix: ", vector graphic, clear lines, single color",
    example: vector,
    nameKey: "images.styles.vector.label",
    promptRecommendation: "images.styles.vector.recommendation",
  },
  {
    promptSuffix: "high quality wallpaper of {{prompt}}",
    example: wallpaper,
    nameKey: "images.styles.wallpaper.label",
    promptRecommendation: "images.styles.wallpaper.recommendation",
  },
];
