import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  List,
  ListItem,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  Table,
  Typography,
} from "@mui/joy";
import type { WorkflowInput } from "apiTypes";
import { useState } from "react";
import type { UpdateWorkflowInput } from "../../../../backend/src/api/routers/workflows/workflowRouter.ts";
import { useEnabledServices } from "../../lib/api/services.ts";
import { useTranslation } from "../../lib/i18n.ts";
import { DocumentDropzone } from "../chat/attachments/DocumentDropzone.tsx";

export function WorkflowInputEditor({
  variablesInText,
  currentValues,
  updateDocumentIds,
  updateAllowUploads,
  updateInput,
  deleteInput,
}: {
  variablesInText: string[];
  currentValues: UpdateWorkflowInput;
  updateDocumentIds: (documentIds: { id: string }[]) => void;
  updateAllowUploads: (allowUploads: boolean) => void;
  updateInput: (input: WorkflowInput) => void;
  deleteInput: (input: WorkflowInput) => void;
}) {
  const enabledServices = useEnabledServices();
  const { t } = useTranslation();

  const inputs = currentValues.inputs ?? [];

  const includedDocumentIds =
    currentValues.includedDocuments?.map((doc) => doc.id) ?? [];

  return (
    <div className="flex flex-col">
      <Typography level="h3">Inputs</Typography>

      <Typography color="neutral" mb={2}>
        {t("workflowEditor.inputsDescription")}
      </Typography>
      <Card sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        {enabledServices?.documentIntelligence && (
          <>
            <Stack>
              <Typography level="h4">
                {t("workflowEditor.documents")}
              </Typography>
              <Divider />
            </Stack>
            <FormControl>
              <FormLabel>{t("workflowEditor.includedDocuments")}</FormLabel>
              <div>
                <DocumentDropzone
                  documentIds={includedDocumentIds}
                  setDocumentIds={(e) => {
                    // e is a dispatch, it might be a function
                    const docIds =
                      typeof e === "function" ? e(includedDocumentIds) : e;
                    updateDocumentIds(docIds.map((id) => ({ id })));
                  }}
                  allowMultiple
                />
                <FormHelperText>
                  {t("workflowEditor.includedDocumentsTooltip")}
                </FormHelperText>
              </div>
            </FormControl>
            <FormControl>
              <Checkbox
                label={<div>{t("workflowEditor.allowDocumentUpload")}</div>}
                checked={currentValues?.allowDocumentUpload}
                onChange={(e) => updateAllowUploads(e.target.checked)}
              />
              <FormHelperText sx={{ ml: 0 }}>
                {t("workflowEditor.documentsTooltip")}
              </FormHelperText>
            </FormControl>
          </>
        )}
        <Stack>
          <Typography level="h4">{t("workflowEditor.variables")}</Typography>
          <Divider />
        </Stack>
        <Table>
          <thead>
            <tr>
              <th
                style={{
                  width: "12%",
                }}
              >
                {t("workflowEditor.variableId")}
              </th>
              <th
                style={{
                  width: "20%",
                }}
              >
                {t("name")}
              </th>
              <th
                style={{
                  width: "10%",
                }}
              >
                {t("type")}
              </th>
              <th
                style={{
                  width: "30%",
                }}
              >
                {t("workflowEditor.options")}
              </th>
              <th
                style={{
                  width: "10%",
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {inputs.map((input) => (
              <InputRow
                key={input.key}
                input={input}
                isUsed={variablesInText.includes(input.key)}
                onDelete={() => {
                  deleteInput(input);
                }}
                setInput={updateInput}
              />
            ))}
            {inputs.length == 0 && (
              <tr>
                <td colSpan={3}>
                  <Typography level="body-sm" color="neutral">
                    {t("workflowEditor.noVariables")}
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </div>
  );
}

function InputRow({
  input,
  setInput,
  onDelete,
  isUsed,
}: {
  input: WorkflowInput;
  setInput: (input: WorkflowInput) => void;
  onDelete: () => void;
  isUsed: boolean;
}) {
  const { t } = useTranslation();

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <tr>
      <td
        className="text-md font-mono font-bold "
        style={{ color: "var(--joy-palette-primary-500)" }}
      >
        {"{{"}
        {input.key}
        {"}}"}
      </td>
      <td>
        <Input
          value={input.name}
          onChange={(e) => {
            setInput({ ...input, name: e.target.value });
          }}
          placeholder={t("inputName")}
        />
      </td>
      <td>
        <Select value={input.type}>
          {(
            [
              ["short_text", t("workflowEditor.shortText")],
              ["long_text", t("workflowEditor.textBlock")],
              ["enum", t("workflowEditor.selection")],
              ["toggle", `${t("on")}/${t("off")}`],
            ] as const
          ).map(([value, label]) => (
            <Option
              value={value}
              onClick={() => {
                setInput({ ...input, type: value });
              }}
              key={value}
            >
              {label}
            </Option>
          ))}
        </Select>
      </td>
      <td>
        {input.type === "long_text" ||
        input.type == "short_text" ||
        input.type === "toggle" ? (
          "-"
        ) : (
          <>
            <div className="flex flex-row items-center gap-1">
              {input.options.length > 0 &&
              !input.options.some((o) => o.label === "" || o.value === "") ? (
                <div>{input.options.map((o) => o.label).join(", ")}</div>
              ) : (
                <div>
                  <em>{t("selectionError")}</em>
                </div>
              )}
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
            </div>
            <Modal open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <ModalDialog>
                <Stack
                  sx={{
                    p: 1,
                  }}
                  gap={2}
                >
                  <Typography level="title-lg">
                    {t("workflowEditor.optionsFor", { input: input.name })}
                  </Typography>
                  {input.type === "enum" && (
                    <>
                      {input.options.length === 0 && (
                        <Typography level="body-sm" color="neutral">
                          {t("workflowEditor.noOptions")}
                        </Typography>
                      )}
                      <List>
                        {input.options.map((option, index) => (
                          <ListItem key={index}>
                            <Stack direction="row" spacing={1}>
                              <FormControl>
                                <FormLabel>
                                  {t("workflowEditor.optionNumber", {
                                    index: index + 1,
                                  })}
                                </FormLabel>
                                <Input
                                  value={option.label}
                                  onChange={(e) => {
                                    setInput({
                                      ...input,
                                      options: input.options.map((o, i) => {
                                        if (i === index) {
                                          return {
                                            ...o,
                                            label: e.target.value,
                                          };
                                        }
                                        return o;
                                      }),
                                    });
                                  }}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel>{t("value")}</FormLabel>
                                <Input
                                  value={option.value}
                                  onChange={(e) => {
                                    setInput({
                                      ...input,
                                      options: input.options.map((o, i) => {
                                        if (i === index) {
                                          return {
                                            ...o,
                                            value: e.target.value,
                                          };
                                        }
                                        return o;
                                      }),
                                    });
                                  }}
                                />
                              </FormControl>
                              <IconButton
                                onClick={() => {
                                  setInput({
                                    ...input,
                                    options: input.options.filter(
                                      (o, i) => i !== index
                                    ),
                                  });
                                }}
                              >
                                <Delete fontSize="small"></Delete>
                              </IconButton>
                            </Stack>
                          </ListItem>
                        ))}
                      </List>
                      <div className="w-max">
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setInput({
                              ...input,
                              options: [
                                ...input.options,
                                {
                                  label: "",
                                  value: "",
                                },
                              ],
                            });
                          }}
                        >
                          <div className="flex flex-row items-center gap-2">
                            <Add />
                            <span>{t("workflowEditor.addOption")}</span>
                          </div>
                        </Button>
                      </div>
                    </>
                  )}
                  <div className="text-right">
                    <Button onClick={() => setDrawerOpen(false)}>
                      {t("save")}
                    </Button>
                  </div>
                </Stack>
              </ModalDialog>
            </Modal>
          </>
        )}
      </td>
      <td className="text-right">
        <IconButton
          color="danger"
          onClick={() => {
            onDelete();
          }}
          disabled={isUsed}
        >
          <Delete />
        </IconButton>
      </td>
    </tr>
  );
}
