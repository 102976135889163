import { useTranslation } from "./i18n";

export function maxStringLength<T extends string | undefined>(
  input: T,
  length: number
): T {
  if (!input) return input;
  if (input.length > length) {
    return (input.substring(0, length) + "...") as T;
  }
  return input;
}

export function useRelativeTime(time: Date) {
  const { t } = useTranslation();
  // if its today, return the relative time (e.g. vor 2 Stunden, gerade eben)
  // if its yesterday, return gestern
  // if its less than a week ago, return the weekday (e.g. Montag, Dienstag)
  // if its more than a week ago, return the weeks ago (e.g. vor 2 Wochen, vor 3 Wochen)

  const now = new Date();
  const diff = now.getTime() - time.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days === 0) {
    if (hours === 0) {
      if (minutes === 0) {
        return t("justNow");
      }
      return t("minutesAgo", { minutes });
    }
    return t("hoursAgo", { hours });
  }

  if (days === 1) {
    return t("yesterday");
  }

  if (days < 7) {
    return t("daysAgo", { days });
  }

  const weeks = Math.floor(days / 7);

  return t("weeksAgo", { count: weeks });
}
