import { Card, Checkbox, Stack, Switch, Typography } from "@mui/joy";
import { t } from "i18next";
import { useCourses, useCreateWorkshop } from "../../lib/api/learning";
import { useOrganization } from "../../lib/api/organization";
import { toast } from "react-toastify";
import {
  useRootApi,
  useRootResource,
  useRootSWR,
} from "../../lib/hooks/useApi";
import { trpc } from "../../lib/api/trpc/trpc";

export function EnableAcademy() {
  const api = useRootApi();
  const createWorkshop = useCreateWorkshop();

  const organization = useOrganization();
  const resourcePath = `workshops/admin/${organization?.defaultWorkshopId}`;
  const defaultWorkshop = useRootResource(resourcePath);
  const availableCourses = useCourses()?.sort((c1, c2) => c1.id - c2.id);
  const chosenCourses = defaultWorkshop?.allCoursesAIDs;

  function generateCode() {
    let code = Math.floor(Math.random() * 1000000).toString();
    while (code.length < 6) {
      code = "0" + code;
    }
    return code;
  }
  const mutateWorkshop = useRootSWR(resourcePath).mutate;
  const utils = trpc.useUtils();
  const toggleAcademyAvailability = (enabled: boolean) => {
    if (enabled) {
      createWorkshop(
        {
          name: organization?.name + " Academy",
          status: "ACTIVE",
          courses: chosenCourses ?? [],
          loginCode: generateCode(),
        },
        organization?.id
      )
        .then(() => {
          void utils.organization.invalidate();
          mutateWorkshop(organization?.id).catch(console.error);
        })
        .catch(console.error);
    } else {
      api
        .delete(
          `workshops/admin/${organization?.id}/workshop/${defaultWorkshop?.id}`
        )
        .then(() => {
          void utils.organization.invalidate();
        })
        .catch(console.error);
    }
  };
  const toggleCourseAvailability = async (course) => {
    api
      .post(
        `workshops/admin/${defaultWorkshop?.id}/courses/${course.id}/toggleAvailability`
      )
      .then(() => {
        mutateWorkshop().catch(console.error);
      })
      .catch(console.error);
  };
  return (
    <Card>
      <Stack flexDirection="row" gap={3}>
        <Typography level="title-lg">{t("enableAcademy")}</Typography>
        <Switch
          checked={!!organization?.defaultWorkshopId}
          onChange={(e) => {
            toggleAcademyAvailability(e.target.checked);
          }}
        />
      </Stack>
      <div>
        <Typography level="title-md" mb={1}>
          {t("availableCourses")}
        </Typography>
        <Stack spacing={1}>
          {availableCourses?.map((course) => (
            <Checkbox
              label={course.title}
              key={course.id}
              disabled={!defaultWorkshop}
              checked={
                defaultWorkshop?.allCoursesAIDs?.includes(course.id + "") ??
                false
              }
              onChange={() => {
                toggleCourseAvailability(course).catch(() => {
                  toast.error(t("errors.network"));
                });
              }}
            />
          ))}
        </Stack>
      </div>
    </Card>
  );
}
