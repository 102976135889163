import { Card, Modal, Typography } from "@mui/joy";
import Lottie from "react-lottie";
import chatBotLoading from "../../assets/chat-bot-show-process.json";
import { GenericLoadingText } from "./GenericLoadingText";
import { ClipLoader } from "react-spinners";
import { LinearFakeLoader } from "./FakeLoader";

export function FullScreenLoader() {
  return (
    <Modal open>
      <div className="w-screen h-screen flex items-center justify-center">
        <div className="w-full flex flex-col items-center h-full justify-center pb-36">
          {/* <Card>
          </Card> */}
          <Card>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: chatBotLoading,
              }}
              height={350}
              width={350}
              isClickToPauseDisabled
            />
            <div className="flex flex-col items-center gap-4 px-4 pb-8">
              <Typography level="h3">Generating your workflow...</Typography>
              <div className="flex flex-row items-center gap-4">
                <Typography level="body-lg">
                  <GenericLoadingText />
                </Typography>
                <ClipLoader size="30px" />
              </div>
              <div className="mt-3 w-full">
                <LinearFakeLoader timeTo90={27500} color="neutral" />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Modal>
  );
}
