import { Card, CardContent, Typography } from "@mui/joy";
import { stepClasses } from "@mui/joy/Step";
import { stepIndicatorClasses } from "@mui/joy/StepIndicator";
import Stepper from "@mui/joy/Stepper";
import { typographyClasses } from "@mui/joy/Typography";
import { useEffect } from "react";
import { ExerciseStepperItem } from "../../../components/elearning/ExerciseStepperItem.tsx";
import { MessagesBlock } from "../../../components/elearning/MessagesBlock.tsx";
import { SlateRenderer } from "../../../components/elearning/SlateRenderer.tsx";
import type { ExerciseWithCompletion } from "../../../lib/api/learning.ts";
import {
  useCaptureAnalyticsEvent,
  useCourse,
  useExercise,
} from "../../../lib/api/learning.ts";
import { useParams } from "../../../router.ts";
import "./course-layout.css";
import { useTranslation } from "react-i18next";

export default function ExerciseView() {
  const params = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  );
  const exercise = useExercise(params.courseId, params.exerciseId);
  const course = useCourse(params.courseId);

  const { t } = useTranslation();

  const captureAnalyticsEvent = useCaptureAnalyticsEvent();

  const courseProgress = {
    numCompleted: course?.exercises.filter(
      (e) => (e.exercise as ExerciseWithCompletion).completed
    ).length,
  };

  useEffect(() => {
    if (params.exerciseId) {
      void captureAnalyticsEvent("EXERCISE_STARTED");
    }
  }, [params.courseId, params.exerciseId, params.workshopId]);

  return (
    <div className="course-layout-stacked lg:course-layout-medium 2xl:course-layout-large flex-grow basis-0 gap-x-8 self-stretch 2xl:min-h-[600px]">
      <Stepper
        orientation="horizontal"
        sx={{
          overflow: "auto",
          alignSelf: "stretch",
          gridArea: "stepper",
          p: 0.5,
          "--Stepper-verticalGap": "2.5rem",
          "--StepIndicator-size": "2.5rem",
          "--Step-gap": "1rem",
          "--Step-connectorInset": "0.5rem",
          "--Step-connectorRadius": "1rem",
          "--Step-connectorThickness": "4px",
          "--joy-palette-success-solidBg": "var(--joy-palette-success-400)",
          [`& .${stepClasses.completed}`]: {
            "&::after": { bgcolor: "success.solidBg" },
          },
          [`& .${stepClasses.active}`]: {
            [`& .${stepIndicatorClasses.root}`]: {
              border: "4px solid",
              borderColor: "#fff",
              boxShadow: (theme) =>
                `0 0 0 1px ${theme.vars.palette.primary[500]}`,
            },
          },
          [`& .${stepClasses.disabled} *`]: {
            color: "neutral.softDisabledColor",
          },
          [`& .${typographyClasses["title-sm"]}`]: {
            textTransform: "uppercase",
            letterSpacing: "1px",
            fontSize: "10px",
          },
        }}
      >
        {course?.exercises
          .sort((a, b) => a.order - b.order)
          .map((ex, i) => (
            <ExerciseStepperItem
              key={ex.id}
              isNextToSolve={courseProgress?.numCompleted === i}
              index={i}
              exercise={ex.exercise as ExerciseWithCompletion}
              lastChild={i === course.exercises.length - 1}
            />
          ))}
      </Stepper>

      <Typography
        level="body-lg"
        color="neutral"
        mb={0.5}
        ml={0.5}
        mt={2}
        style={{ gridArea: "content-title" }}
      >
        {t("task")}
      </Typography>
      <Card className="overflow-hidden" sx={{ gridArea: "content" }}>
        <CardContent className="overflow-auto" sx={{ pr: 1 }}>
          <SlateRenderer content={exercise?.exerciseDescription ?? []} />
        </CardContent>
      </Card>
      {exercise && (
        <MessagesBlock
          exercise={exercise}
          courseId={params.courseId}
          key={exercise.id}
        />
      )}
    </div>
  );
}
