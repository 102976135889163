import * as z from "zod";
import { ApiDate } from "./Date";

export const ApiChat = z.object({
  id: z.string(),
  name: z.string().nullable(),
  createdAt: ApiDate,
  updatedAt: ApiDate,
  archived: z.boolean(),
  modelOverride: z.string().nullable(),
  assistantRoleDescription: z.string().nullable(),
  organizationId: z.string(),
  departmentId: z.string().nullable(),
  customSystemPromptSuffix: z.string().nullable(),
});
export type ApiChat = z.infer<typeof ApiChat>;

export const CreateApiChat = ApiChat.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  archived: true,
})
  .extend({
    defaultMessages: z.array(
      z.object({
        content: z.string(),
        role: z.enum(["system", "user", "assistant"]),
      }),
    ),
    isHidden: z.boolean(),
  })
  .partial();
export type CreateApiChat = z.infer<typeof CreateApiChat>;

export const ChatHistoryEntry = z.object({
  content: z.string(),
  fromAi: z.enum(["no", "yes"]),
});

export const CreatePrefilledApiChat = z.object({
  createChat: CreateApiChat,
  chatHistory: z.array(ChatHistoryEntry).optional(),
});
export type CreatePrefilledApiChat = z.infer<typeof CreatePrefilledApiChat>;
