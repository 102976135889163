import { ChevronLeft } from "@mui/icons-material";
import { Button, IconButton, Sheet, Typography } from "@mui/joy";
import { Outlet } from "react-router-dom";
import { UserMenu } from "../../../components/auth/UserMenu.tsx";
import { LiveWorkshopCard } from "../../../components/elearning/LiveWorkshopCard.tsx";
import {
  useCaptureAnalyticsEvent,
  useCourse,
  useCourseProgress,
} from "../../../lib/api/learning";
import { Link, useParams } from "../../../router";
import { useTranslation } from "react-i18next";
import { useOrganization } from "../../../lib/api/organization.ts";

export default function CourseView() {
  const { courseId, organizationId, workshopId, exerciseId } = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId"
  );

  const course = useCourse(courseId);
  const { numTotal: totalExercises, numCompleted: completedExercises } =
    useCourseProgress(courseId);

  const captureAnalyticsEvent = useCaptureAnalyticsEvent();
  const { t } = useTranslation();

  const organization = useOrganization();

  if (!course) return null;

  return (
    <Sheet
      className="flex min-h-screen w-full flex-col items-center gap-4 overflow-y-auto p-8"
      variant="soft"
    >
      <div className="left-right-center">
        <div className="flex">
          {exerciseId == undefined ? (
            <Button
              variant="plain"
              color="neutral"
              to="/:organizationId/learn/:workshopId"
              params={{
                organizationId,
                workshopId,
              }}
              component={Link}
              sx={{ pl: 0 }}
              onClick={() => {
                if ((completedExercises ?? 0) < (totalExercises ?? 0)) {
                  captureAnalyticsEvent("COURSE_ABANDONED");
                }
              }}
            >
              <div className="flex flex-row items-center gap-2">
                <ChevronLeft />
                <span>{t("backToCourseOverview")}</span>
              </div>
            </Button>
          ) : (
            <>
              <IconButton
                variant="plain"
                color="neutral"
                to="/:organizationId/learn/:workshopId"
                params={{
                  organizationId,
                  workshopId,
                }}
                component={Link}
                sx={{ pl: 0 }}
              >
                <ChevronLeft />
              </IconButton>
              <Typography level="h2">{course.title}</Typography>
            </>
          )}
        </div>
        {workshopId != "none" &&
        workshopId !== organization?.defaultWorkshopId ? (
          <LiveWorkshopCard workshopId={workshopId} />
        ) : (
          <div />
        )}
        <div className="justify-self-end">
          <UserMenu isAcademy={true} languageSelector />
        </div>
      </div>
      <Outlet />
    </Sheet>
  );
}
