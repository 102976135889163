import { ApiCreditTransaction, ApiCreditUsage } from "apiTypes";
import {
  useOrganizationApi,
  useOrganizationSchemaResource,
} from "../hooks/useApi";

export function useCreditsUsage() {
  return useOrganizationSchemaResource("credits/usage", ApiCreditUsage);
}

export function useCreditsTransactions() {
  return useOrganizationSchemaResource(
    "credits/transactions",
    ApiCreditTransaction.array(),
  );
}

export function useSpendCreditsForWorkflowCreation() {
  const api = useOrganizationApi();
  return async () => {
    await api.post("credits/spend-credits-for-workflow-creation");
  };
}
