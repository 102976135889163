import { Card, Button, Modal, ModalDialog, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { trpc } from "../../lib/api/trpc/trpc";
import { BrandedLogo } from "../branding/BrandedLogo";
import { MarkdownRenderer } from "../chat/MarkdownRenderer";
import { useEffect, useState } from "react";
import type { ApiUser } from "apiTypes";

export function GuidelinesModal({
  organizationId,
  me,
}: {
  organizationId: string;
  me: ApiUser | undefined;
}) {
  const { data } = trpc.usageGuidelines.getGuidelines.useQuery({
    organizationId,
  });

  const { guidelines, guidelinesLastUpdate } = data ?? {};

  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const acceptGuidelines =
    trpc.usageGuidelines.acceptGuidelines.useMutation().mutateAsync;

  useEffect(() => {
    if (guidelines && me && !me.acceptedGuidelines) {
      setOpen(true);
    }
  }, [guidelines, me, me?.acceptedGuidelines]);

  if (!me || !guidelines || !guidelinesLastUpdate) return null;

  const accept = () => {
    void acceptGuidelines().then(() => {
      setOpen(false);
    });
  };

  return (
    <Modal open={open} disableEscapeKeyDown>
      <ModalDialog
        sx={{
          height: "90vh",
          width: "80%",
          maxWidth: "1000px",
          p: 5,
          gap: 5,
          overflowY: "scroll",
        }}
      >
        <BrandedLogo style={{ height: "100px" }} />
        <Typography level="h2" textAlign="center">
          {t("usageGuidelines")}
        </Typography>
        <Card>
          <MarkdownRenderer
            content={
              guidelines +
                "\n\n" +
                t("lastUpdated") +
                new Date(guidelinesLastUpdate).toLocaleDateString(
                  i18n.language
                ) +
                " " +
                new Date(guidelinesLastUpdate).toLocaleTimeString(
                  i18n.language
                ) ?? ""
            }
          />
        </Card>
        <Button onClick={accept} size="lg" sx={{ alignSelf: "flex-end" }}>
          {t("accept")}
        </Button>
      </ModalDialog>
    </Modal>
  );
}
