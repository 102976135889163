import { Sheet } from "@mui/joy";
import type { ApiMessage } from "apiTypes";
import { ApiTextModel } from "apiTypes";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import { useChat, useUpdateChat } from "../../lib/api/chat";
import { useMessages, useMutateMessages } from "../../lib/api/message";
import { useMe } from "../../lib/api/user";
import { useQueuedMessagesStore } from "../../lib/context/queuedMessagesStore";
import { useOrganizationApi } from "../../lib/hooks/useApi";
import { useTranslation } from "../../lib/i18n";
import { maxStringLength } from "../../lib/util";
import { DelayedLoader } from "../util/DelayadLoader";

import { ChatInput } from "./ChatInput";
import { ChatMessage } from "./ChatMessage";
import { ChatSettingsMenu } from "./ChatSettingsMenu";
import { SmartIterations } from "./SmartIterations";
import { handleGenericError } from "../../lib/errorHandling";
import { PromptingFeedback } from "./PromptingFeedback";
import { trpc } from "../../lib/api/trpc/trpc";
import { useCurrentOrganizationId } from "../../lib/api/trpc/helpers/useCurrentOrganizationId";

export { ErrorDisplay as Catch } from "../../components/util/ErrorDisplay";

export function ChatInterface({
  chatId,
  showSmartIterations = true,
  showSettings = true,
  showAttachmentButton = true,
  embedded = false,
  sheetProps = {},
  onPrompt,
  onMessageHistoryChange,
  readonly = false,
  lastMessageId = null,
  customSystemPromptSuffix,
  customTemperature,
  autoFocus = true,
  showPromptingFeedback = true,
}: {
  chatId: string;
  showSmartIterations?: boolean;
  showSettings?: boolean;
  showAttachmentButton?: boolean;
  embedded?: boolean;
  sheetProps?: React.ComponentProps<typeof Sheet>;
  onPrompt?: ({
    prompt,
    messageHistory,
  }: {
    prompt: string;
    messageHistory: ApiMessage[];
  }) => void;
  onMessageHistoryChange?: (
    messages: ApiMessage[],
    type: "prompt" | "response"
  ) => void;
  readonly?: boolean;
  lastMessageId?: string | null;
  customSystemPromptSuffix?: string;
  customTemperature?: number;
  autoFocus?: boolean;
  showPromptingFeedback?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const apiMessages = useMessages(chatId);

  const lastHistoryChangeTrigger = useRef<ApiMessage[]>([]);
  useEffect(() => {
    const completedApiMessages =
      apiMessages?.filter((m) => m.responseCompleted || !m.fromAi) ?? [];
    if (_.isEqual(lastHistoryChangeTrigger.current, completedApiMessages))
      return;
    lastHistoryChangeTrigger.current = completedApiMessages;
    onMessageHistoryChange?.(
      completedApiMessages,
      completedApiMessages[completedApiMessages.length - 1]?.fromAi
        ? "response"
        : "prompt"
    );
  }, [apiMessages, onMessageHistoryChange]);

  const [frozenMessages, setFrozenMessages] = useState([] as ApiMessage[]);
  const [tempMessages, setTempMessages] = useState([] as ApiMessage[]);
  const [completed, setCompleted] = useState(true);
  const waitingForQueuedMessage = useRef<boolean>(false);
  const messages = completed ? apiMessages : frozenMessages;

  let filteredMessages: typeof messages = [];
  if (lastMessageId != null) {
    const sortedMessages = messages?.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    const lastMessageIndex = messages?.findIndex((m) => m.id === lastMessageId);
    if (lastMessageIndex !== -1 && lastMessageIndex !== undefined) {
      filteredMessages = sortedMessages?.slice(0, lastMessageIndex + 2) ?? [];
    }
  }

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const api = useOrganizationApi();

  useEffect(() => {
    if (completed) {
      inputRef.current?.focus({
        preventScroll: true,
      });
    }
  }, [completed]);

  const mutateMessages = useMutateMessages(chatId);
  const chat = useChat(chatId);
  const updateChat = useUpdateChat(chatId);
  const updateChatSafe = useCallback(
    (data) => {
      toast
        .promise(updateChat(data), {
          success: t("chatUpdated"),
          error: t("chatUpdateFailed"),
        })
        .catch((e) => {
          throw e;
        });
    },
    [updateChat, t]
  );

  const me = useMe();

  const currentOrgId = useCurrentOrganizationId();
  const createMessageMutation =
    trpc.message.postMessageAndRequestResponse.useMutation();
  const abortMessageGenerationMutation =
    trpc.message.abortMessageResponse.useMutation();

  const cancelFunctionRef = useRef<() => Promise<void>>();

  const cancelMessageGeneration = useCallback(async () => {
    waitingForQueuedMessage.current = false;
    clearMessageQueue();
    if (completed) return;
    await cancelFunctionRef.current?.();
  }, [completed]);

  const createMessage = useCallback(
    async (message: string, attachmentIds: string[]) => {
      const promptTempMessage = {
        fromAi: false,
        content: message,
        createdAt: new Date().toISOString(),
        authorId: me?.id ?? null,
        chatId,
        id: "temp",
        attachmentIds: attachmentIds,
        generationModel: null,
        responseCompleted: true,
        citations: [],
        cancelled: false,
      };

      onPrompt?.({
        prompt: message,
        messageHistory: [...(messages ?? []), promptTempMessage],
      });

      const aiTempMessage = {
        fromAi: true,
        content: "",
        createdAt: new Date(Date.now() + 10).toISOString(),
        authorId: null,
        chatId,
        id: "temp_ai",
        attachmentIds: [],
        generationModel: null,
        responseCompleted: false,
        citations: [],
        cancelled: false,
      };

      setTempMessages([promptTempMessage, aiTempMessage]);
      setFrozenMessages(apiMessages ?? []);

      setCompleted(false);

      let cancelled = false;

      // this is the function that will be called when the user cancels the message generation
      cancelFunctionRef.current = async () => {
        // only cancel once
        if (cancelled) return;
        cancelled = true;

        setCompleted(true);
        waitingForQueuedMessage.current = false;

        // the ai message id should always be here, since the server sends it first thing in the response, if not, there is also no content we need to sync. If the user actually does manage to cancel before the package arrives, the message won't be marked as cancelled and when the user refreshes the page later the generated message will be displayed.
        aiMessageId.current &&
          (await abortMessageGenerationMutation.mutateAsync({
            chatId,
            messageId: aiMessageId.current,
            organizationId: currentOrgId,
            receivedContent: fullResponse,
          }));

        // server messages sync and then flush temp message
        await mutateMessages();
        setTempMessages([]);
      };

      // this will be the package stream for the completion
      const res = await createMessageMutation.mutateAsync({
        content: message,
        language: i18n.language?.split("-")[0] ?? "en",
        attachmentIds,
        customSystemPromptSuffix,
        temperature: customTemperature,
        organizationId: currentOrgId,
        chatId,
      });

      // here we accumulate the response from the incoming chunks
      let fullResponse = "";

      // a ref to the ai message id, so we can cancel it if needed
      const aiMessageId: {
        current: string | null;
      } = { current: null };

      // iterate over the chunks from the server
      for await (const chunk of res) {
        // if the user cancelled, break the loop
        if (cancelled) break;

        // if the ai message id is in the chunk, set it and skip this packages since it is not a response
        if ("aiMessageId" in chunk) {
          aiMessageId.current = chunk.aiMessageId;
          continue;
        }

        fullResponse += chunk.delta;

        // update the temp message with the response
        setTempMessages((currentTempMessages) => {
          return [
            currentTempMessages[0],
            {
              ...currentTempMessages[1],
              content: fullResponse,
              citations: chunk.citations,
            },
          ];
        });
      }

      // if the user cancelled, we don't need to sync the messages since that already happened
      if (cancelled) return;
      await mutateMessages();
      setCompleted(true);
      waitingForQueuedMessage.current = false;
      setTempMessages([]);
    },
    [
      abortMessageGenerationMutation,
      createMessageMutation,
      currentOrgId,
      customTemperature,
      onPrompt,
      messages,
      chatId,
      me?.id,
      mutateMessages,
      i18n.language,
      apiMessages,
      customSystemPromptSuffix,
    ]
  );

  const enqueueMessage = useQueuedMessagesStore((s) => s.addQueuedMessage);
  const clearMessageQueue = useQueuedMessagesStore((s) => s.clear);

  const regenerateMessage = useCallback(
    async (aiMessageId: ApiMessage) => {
      await cancelMessageGeneration();

      if (!messages) return; // impossible
      // get the messsage before the ai message
      const index = messages.findIndex((m) => m.id === aiMessageId.id);
      const userMessage = messages[index - 1];
      if (!userMessage) {
        handleGenericError(
          new Error("no user message found before ai message"),
          "generateMessageFailed",
          {
            messages,
            aiMessageId,
          },
          true
        );
      }

      await api.delete(`chats/${chatId}/messages/${userMessage.id}/following`);
      await mutateMessages();

      enqueueMessage({
        chatId,
        content: userMessage.content,
        attachmentIds: userMessage.attachmentIds,
      });
    },
    [
      api,
      messages,
      mutateMessages,
      chatId,
      enqueueMessage,
      cancelMessageGeneration,
    ]
  );

  const editMessage = useCallback(
    async (oldMessageId: string, content: string) => {
      await cancelMessageGeneration();

      if (!apiMessages) return; // impossible
      const oldMessage = apiMessages.find((m) => m.id === oldMessageId);
      if (!oldMessage) {
        // if we are currently generating a message it will not be in the apiMessages
        const updatedMessages = await mutateMessages();
        // the message we are trying to edit will be the second last message
        const updatedOldMessage = updatedMessages[updatedMessages.length - 2];
        if (!updatedOldMessage) {
          handleGenericError(
            new Error("no message found to edit"),
            "generateMessageFailed",
            {
              messages: updatedMessages,
              oldMessageId,
            },
            true
          );
        }
        oldMessageId = updatedOldMessage.id;
      }
      await api.delete(`chats/${chatId}/messages/${oldMessageId}/following`);
      await mutateMessages();
      enqueueMessage({
        chatId,
        content,
        attachmentIds: oldMessage?.attachmentIds ?? [],
      });
    },
    [
      api,
      mutateMessages,
      chatId,
      apiMessages,
      enqueueMessage,
      cancelMessageGeneration,
    ]
  );

  const queuedMessages = useQueuedMessagesStore((s) => s.queuedMessages);
  const shiftQueuedMessages = useQueuedMessagesStore(
    (s) => s.shiftQueuedMessage
  );

  useEffect(() => {
    if (!chat) return;
    const forThisChat = queuedMessages.filter((m) => m.chatId === chatId);
    if (forThisChat.length > 0 && !waitingForQueuedMessage.current) {
      const message = forThisChat[0];
      createMessage(message.content, message.attachmentIds ?? []).catch((e) => {
        // check if its this: DOMException: BodyStreamBuffer was aborted
        if (e.name === "AbortError") return;

        handleGenericError(e, "messageSendFailed", {
          message,
        });
        console.error(e);
      });
      waitingForQueuedMessage.current = true;
      shiftQueuedMessages();
    }
  }, [
    queuedMessages.length,
    chat,
    chatId,
    createMessage,
    shiftQueuedMessages,
    queuedMessages,
    t,
    completed,
  ]);

  const chatName = maxStringLength(chat?.name ?? undefined, 80);

  if (!chat || !messages)
    return (
      <Sheet
        className={twMerge("relative h-full w-full")}
        variant="soft"
        {...sheetProps}
        sx={{
          backgroundColor: embedded ? "transparent" : undefined,
        }}
      >
        <DelayedLoader />
      </Sheet>
    );

  return (
    <Sheet
      className={twMerge("relative h-full w-full")}
      variant="soft"
      {...sheetProps}
      sx={{
        backgroundColor: embedded ? "transparent" : undefined,
      }}
      onClick={() => {
        embedded && inputRef.current?.focus();
      }}
    >
      <>
        <div
          className={twMerge(
            "flex flex-1 flex-col-reverse overflow-auto overflow-y-auto overscroll-y-contain pb-44",
            embedded && "pb-0"
          )}
          style={{
            height: !readonly ? "calc(100% - 4rem)" : "100%",
          }}
        >
          {(lastMessageId == null
            ? [
                ...messages,
                ...tempMessages.map((c) => ({ ...c, id: c.id + "temp" })),
              ]
            : filteredMessages
          )
            .sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
            .map((m) => (
              <ChatMessage
                chat={chat}
                embedded={embedded}
                message={{
                  ...m,
                  responseCompleted:
                    m.responseCompleted || !m.id.includes("temp"), // if the message is not a temp message, it is completed
                }}
                key={m.id}
                onRegenerate={() => {
                  regenerateMessage(m).catch(console.error); // can only throw if the message is not found and handles it internally or api errors which are handled by the api
                }}
                onEdit={async (content: string) => {
                  await editMessage(m.id, content);
                }}
              />
            ))}
          {!embedded && (
            <span className="p-20 text-center text-4xl">{chatName}</span>
          )}
        </div>
        {!readonly && (
          <div
            className={twMerge(
              "absolute bottom-0 flex w-full flex-col items-stretch justify-center gap-4 p-10",
              showSmartIterations &&
                "bg-gradient-to-t from-[#F0F4F8] from-80% to-transparent pb-20 pt-16",
              embedded && "p-2"
            )}
          >
            <div className="flex w-full flex-row justify-between gap-4 pe-28 ps-12">
              {showSmartIterations && <SmartIterations disabled={!completed} />}
              {showPromptingFeedback && <PromptingFeedback />}
            </div>
            <ChatInput
              isGenerating={!completed}
              embedded={embedded}
              postMessage={(content: string, attachmentIds: string[]) => {
                cancelMessageGeneration()
                  .then(() => {
                    enqueueMessage({ chatId, content, attachmentIds });
                  })
                  .catch(console.error);
              }}
              onCancel={cancelMessageGeneration}
              ref={inputRef}
              autoFocus={autoFocus}
              showAttachmentButton={showAttachmentButton}
              startDecorator={
                showSettings && (
                  <ChatSettingsMenu
                    selectedModel={
                      chat?.modelOverride
                        ? ApiTextModel.parse(chat.modelOverride)
                        : null
                    }
                    setSelectedModel={(modelOverride) => {
                      updateChatSafe({ modelOverride });
                    }}
                  />
                )
              }
            />
          </div>
        )}
      </>
    </Sheet>
  );
}
