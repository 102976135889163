import { Box, IconButton, Typography } from "@mui/joy";
import { useTranslation } from "../../lib/i18n";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { useState } from "react";
import {
  useSuccessColor,
  useWarningColor,
  useDangerColor,
} from "../../lib/hooks/useTheme";

const CircleStyle = "rounded-full w-5 h-5 display:inline-block mt-1 mr-1 ml-1";

const FeedbackResult = ({
  title,
  color,
  comment,
}: {
  title: string;
  color: string;
  comment: string;
}) => {
  return (
    <div className="my-2 flex">
      <div className="col">
        <div className={CircleStyle} style={{ backgroundColor: color }} />
      </div>

      <div className="col">
        <Typography ml="8px" fontWeight="bold">
          {title}
        </Typography>
        <Typography ml="8px">{comment}</Typography>
      </div>
    </div>
  );
};

export function PromptingFeedback({
  promptEvaluations = [
    { rating: "1", comment: "Super!" },
    { rating: "2", comment: "Meh" },
    { rating: "3", comment: "Not gud" },
  ],
}: {
  promptEvaluations?: {
    rating: string;
    comment: string;
  }[];
}) {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  // from 1 to 3: danger, warning, success
  const ratingColors = {
    "1": useDangerColor(),
    "2": useWarningColor(),
    "3": useSuccessColor(),
  };
  const feedbackTitles = [
    t("promptingAssistant.assumption"),
    t("promptingAssistant.task"),
    t("promptingAssistant.output"),
  ];

  return (
    <Box display="flex" alignItems="center" position="relative">
      {menuOpen && (
        <Box
          left="50%"
          bottom="100%"
          position="absolute"
          className="z-1 flex min-w-[240px] -translate-x-[35%] -translate-y-1 transform flex-col items-start rounded-md p-2 shadow-md"
          bgcolor="white"
        >
          <Typography fontWeight="bold" className="self-center">
            Prompting Assistant
          </Typography>
          <Typography level="body-xs" className="self-center pb-2">
            Basierend auf den letzten Prompt
          </Typography>
          {promptEvaluations.map((res, index) => (
            <FeedbackResult
              key={index}
              title={feedbackTitles[index]}
              color={ratingColors[res.rating]}
              comment={res.comment}
            />
          ))}
        </Box>
      )}

      {promptEvaluations.map((res) => (
        <div
          className={CircleStyle}
          key={res.rating}
          style={{ backgroundColor: ratingColors[res.rating] }}
        />
      ))}
      <IconButton onClick={() => setMenuOpen((o) => !o)} sx={{ ml: "3px" }}>
        {menuOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      </IconButton>
    </Box>
  );
}
