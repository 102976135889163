import { useTranslation } from "../../lib/i18n";

export function ErrorDisplay({ error = undefined }: { error?: string }) {
  const { t } = useTranslation();

  return (
    <div className="h-full w-hull flex-1 p-4 items-stretch justify-stretch">
      <div className="flex flex-col items-center justify-center flex-1 bg-red-500 bg-opacity-50 border-red-500 border-2 rounded gap-3 p-3">
        <h1 className="text-4xl font-thin text-red-800">
          {t("errorDisplay.title")}
        </h1>
        <span className="text-red-800">
          {error ?? t("errorDisplay.helpText")}
        </span>
      </div>
    </div>
  );
}
