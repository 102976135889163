import type { ApiTextModel } from "apiTypes";
import openaiIcon from "../assets/modelicons/openai.png";
import googleIcon from "../assets/modelicons/google.png";
import mistralIcon from "../assets/modelicons/mistral.png";
import perplexityIcon from "../assets/modelicons/perplexity.png";
import claudeIcon from "../assets/modelicons/claude.webp";

export type ModelMeta = {
  name: string;
  provider: string;
  infoUrl: string;
  online?: boolean;
  hostingLocation: "EU" | "US";
  icon?: string;
  quality: number;
  speed: number;
  capabilities: string[];
};
export const imageModelMeta: Record<string, ModelMeta> = {
  "dall-e-3": {
    name: "DALL-E 3",
    infoUrl: "https://platform.openai.com/docs/models/dall-e",
    hostingLocation: "US",
    provider: "OpenAI",
    quality: 4,
    speed: 3,
    capabilities: ["Images"],
  },
};
export const modelMeta: Record<ApiTextModel, ModelMeta> = {
  "gpt-3.5-turbo": {
    name: "GPT-3.5",
    infoUrl: "https://platform.openai.com/docs/models/gpt-3-5",
    hostingLocation: "EU",
    icon: openaiIcon,
    provider: "Microsoft",
    quality: 3,
    speed: 4,
    capabilities: ["Short Drafts", "General Chat", "Medium Complexity"],
  },
  "gpt-4": {
    name: "GPT-4",
    infoUrl: "https://platform.openai.com/docs/models/gpt-4",
    hostingLocation: "EU",
    icon: openaiIcon,
    provider: "Microsoft",
    quality: 5,
    speed: 2,
    capabilities: [
      "Brainstorming",
      "Strategies",
      "Long Responses",
      "Complex Reasoning",
      "Technical Writing",
    ],
  },
  "gpt-3.5-turbo-us": {
    name: "GPT-3.5",
    infoUrl: "https://platform.openai.com/docs/models/gpt-3-5",
    hostingLocation: "US",
    icon: openaiIcon,
    provider: "OpenAI",
    quality: 3,
    speed: 4,
    capabilities: ["Short Drafts", "General Chat", "Medium Complexity"],
  },
  "gpt-4-us": {
    name: "GPT-4",
    infoUrl: "https://platform.openai.com/docs/models/gpt-4",
    hostingLocation: "US",
    icon: openaiIcon,
    provider: "OpenAI",
    quality: 5,
    speed: 2,
    capabilities: [
      "Brainstorming",
      "Strategies",
      "Long Responses",
      "Complex Reasoning",
      "Technical Writing",
    ],
  },
  "gpt-4o-us": {
    name: "GPT-4o",
    infoUrl: "https://openai.com/index/hello-gpt-4o/",
    hostingLocation: "US",
    icon: openaiIcon,
    provider: "OpenAI",
    quality: 0,
    speed: 0,
    capabilities: [],
  },
  "llama-3-sonar-large-32k-online": {
    name: "Perplexity Online",
    infoUrl: "https://www.perplexity.ai/hub/blog/introducing-pplx-online-llms",
    hostingLocation: "US",
    online: true,
    icon: perplexityIcon,
    provider: "Perplexity",
    quality: 3,
    speed: 4,
    capabilities: ["Research", "Search", "Summarization"],
  },
  "mistral-small-latest": {
    name: "Mistral Small",
    infoUrl: "https://mistral.ai/technology/#models",
    hostingLocation: "EU",
    icon: mistralIcon,
    provider: "Mistral",
    quality: 1,
    speed: 5,
    capabilities: ["Classification", "Categorization", "Batch Tasks"],
  },
  "mistral-medium-latest": {
    name: "Mistral Medium",
    infoUrl: "https://mistral.ai/technology/#models",
    hostingLocation: "EU",
    icon: mistralIcon,
    provider: "Mistral",
    quality: 3,
    speed: 3,
    capabilities: ["Moral decisions", "Short Responses", "Chat"],
  },
  "mistral-large-latest": {
    name: "Mistral Large",
    infoUrl: "https://mistral.ai/technology/#models",
    hostingLocation: "EU",
    icon: mistralIcon,
    provider: "Mistral",
    quality: 4,
    speed: 3,
    capabilities: ["Moral decisions", "Medium Responses"],
  },
  "claude-3-5-sonnet-20240620": {
    name: "Claude 3.5 Sonnet",
    infoUrl: "https://www.anthropic.com/claude",
    hostingLocation: "EU",
    icon: claudeIcon,
    provider: "Google",
    quality: 4,
    speed: 5,
    capabilities: ["Complex Queries", "Technical Writing"],
  },
  "gemini-1.5-pro-preview-0409": {
    name: "Gemini 1.5 Pro",
    infoUrl:
      "https://blog.google/technology/ai/google-gemini-next-generation-model-february-2024/#sundar-note",
    hostingLocation: "EU",
    icon: googleIcon,
    provider: "Google",
    quality: 5,
    speed: 3,
    capabilities: ["Long inputs", "Documents", "Complex Queries"],
  },
  "gemini-1.5-flash": {
    name: "Gemini 1.5 Flash",
    infoUrl: "https://deepmind.google/technologies/gemini/flash/",
    hostingLocation: "EU",
    icon: googleIcon,
    provider: "Google",
    quality: 5,
    speed: 5,
    capabilities: ["Long inputs", "Documents", "Complex Queries"],
  },
};
