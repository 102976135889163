import {
  AutoAwesome,
  ImportExport,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Box,
  Card,
  IconButton,
  LinearProgress,
  Link,
  Option,
  Select,
  Sheet,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { trpc } from "../../../lib/api/trpc/trpc";
import { useCurrentOrganizationId } from "../../../lib/api/trpc/helpers/useCurrentOrganizationId";
import { useDebouncedCallback } from "use-debounce";
import {
  DEEPL_LANGUAGES_SOURCE,
  DEEPL_LANGUAGES_TARGET,
} from "apiTypes/src/tools/DeeplLanguages";
import type {
  DeepLSourceLanguage,
  DeepLTargetLanguage,
} from "apiTypes/src/tools/DeeplLanguages";

// DeepL API supports only en-us, en-gb, pt-br, pt-pt for en and pt as target languages
const sourceToTarget = (lang: DeepLSourceLanguage) => {
  switch (lang) {
    case "en":
    case "detect":
      return "en-GB";
    case "pt":
      return "pt-PT";
    default:
      return lang;
  }
};

// DeepL API supports only en and pt for en-US, en-GB, pt-BR, pt-PT as source languages
const targetToSource = (lang: DeepLTargetLanguage) => {
  switch (lang) {
    case "en-US":
    case "en-GB":
      return "en";
    case "pt-BR":
    case "pt-PT":
      return "pt";
    default:
      return lang;
  }
};

export default function TranslateText() {
  const { t } = useTranslation();
  const organizationId = useCurrentOrganizationId();
  const [sourceLanguage, setSourceLanguage] =
    useState<DeepLSourceLanguage>("de");
  const [targetLanguage, setTargetLanguage] =
    useState<DeepLTargetLanguage>("en-GB");
  const [sourceText, setSourceText] = useState("");
  const [loading, setLoading] = useState(false);
  const [targetText, setTargetText] = useState("");
  const translationMutation = trpc.tools.translateText.translate.useMutation();

  const loadingTimout = useRef<NodeJS.Timeout>(setTimeout(() => {}, 0));

  const debouncedTranslate = useDebouncedCallback((source) => {
    // If the response takes too long, after 500ms show the loading spinner
    clearTimeout(loadingTimout.current);
    loadingTimout.current = setTimeout(() => {
      setLoading(true);
    }, 500);
    void translationMutation
      .mutateAsync({
        organizationId,
        text: source,
        source_lang: sourceLanguage,
        target_lang: targetLanguage,
      })
      .then(() => {
        clearTimeout(loadingTimout.current);
        setLoading(false);
      });
  }, 500);

  const translate = async (source: string) => {
    setSourceText(source);
    debouncedTranslate(source);
  };

  //updates the translation text
  useEffect(() => {
    if (translationMutation.data) {
      setTargetText(translationMutation.data.text);
    } else if (translationMutation.isError) {
      setTargetText(t("errors.couldNotTranslateText"));
    }
  }, [t, translationMutation.data, translationMutation.isError, setTargetText]);
  //used for languages swap
  useEffect(() => {
    void translate(sourceText);
  }, [sourceLanguage, targetLanguage]);

  return (
    <Sheet variant="soft" sx={{ pt: 10, height: "100vh" }}>
      <Stack alignItems="center">
        <Typography level="h1" textAlign="center" marginBottom={4}>
          {t("translateText")}
        </Typography>
        <Box width="90%" maxWidth="1500px">
          <Card sx={{ p: 0 }}>
            <Stack>
              <Box
                py={1}
                display="flex"
                justifyContent="center"
                borderBottom="1px solid #CED4D9"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  position="absolute"
                  left="20px"
                >
                  <SourceLanguagePicker
                    sourceLanguage={sourceLanguage}
                    setSourceLanguage={setSourceLanguage}
                    targetLanguage={targetLanguage}
                    setTargetLanguage={setTargetLanguage}
                  />
                </Stack>
                <IconButton
                  disabled={sourceLanguage === "detect"}
                  onClick={() => {
                    setSourceLanguage(targetToSource(targetLanguage));
                    setTargetLanguage(sourceToTarget(sourceLanguage));
                    setSourceText(targetText);
                  }}
                >
                  <ImportExport sx={{ transform: "rotate(90deg)" }} />
                </IconButton>
                <Stack
                  direction="row"
                  alignItems="center"
                  position="absolute"
                  left="55%"
                >
                  <TargetLanguagePicker
                    sourceLanguage={sourceLanguage}
                    targetLanguage={targetLanguage}
                    setTargetLanguage={setTargetLanguage}
                  />
                </Stack>
              </Box>
              <Stack direction="row">
                <Textarea
                  sx={{
                    pl: 2,
                    pt: 2,
                    height: "300px",
                    border: 0,
                    borderRadius: "0 0 0 10px",
                    borderRight: "1px solid #CED4D9",
                    width: "50%",
                    direction: sourceLanguage === "ar" ? "rtl" : "ltr",
                    overflow: "hidden",
                    textarea: {
                      overflowY: "auto !important",
                    },
                  }}
                  value={sourceText}
                  placeholder={t("enterText")}
                  onChange={(e) => {
                    void translate(e.target.value);
                  }}
                />
                <Box
                  sx={{
                    p: 2,
                    height: "300px",
                    border: 0,
                    borderRadius: "0 0 10px 0",
                    width: "50%",
                    whiteSpace: "pre-wrap",
                    overflowY: "auto",
                    direction: targetLanguage === "ar" ? "rtl" : "ltr",
                    position: "relative",
                  }}
                >
                  {loading && (
                    <LinearProgress
                      sx={{
                        position: "absolute",
                        width: "100%",
                        left: 0,
                        top: 0,
                        "--LinearProgress-thickness": "4px",
                        "--LinearProgress-radius": "0px",
                        "--LinearProgress-progressRadius": "2px",
                      }}
                    />
                  )}
                  {targetText}
                </Box>
              </Stack>
            </Stack>
          </Card>
          <Typography
            level="body-sm"
            textAlign="right"
            width="100%"
            marginTop={2}
          >
            powered by{" "}
            <Link target="_blank" href="https://www.deepl.com/en/translator">
              DeepL
            </Link>
          </Typography>
        </Box>
      </Stack>
    </Sheet>
  );
}

function SourceLanguagePicker({
  sourceLanguage,
  setSourceLanguage,
  targetLanguage,
  setTargetLanguage,
}: {
  sourceLanguage: DeepLSourceLanguage;
  setSourceLanguage: (lang: DeepLSourceLanguage) => void;
  targetLanguage: DeepLTargetLanguage;
  setTargetLanguage: (lang: DeepLTargetLanguage) => void;
}) {
  const { t } = useTranslation();
  const sortedSourceLanguages = [...DEEPL_LANGUAGES_SOURCE].sort((a, b) =>
    a === "detect" || b === "detect"
      ? 1
      : t("languages." + a).localeCompare(t("languages." + b))
  );
  const defaultTargetLanguage = targetLanguage.includes("de") ? "en-GB" : "de";
  return (
    <Select
      value={t("languages." + sourceLanguage)}
      indicator={<KeyboardArrowDown />}
      renderValue={(o) => (
        <>
          {o?.value}
          {o?.value === t("languages.detect") && (
            <AutoAwesome color="primary" sx={{ fontSize: "20px", ml: 0.5 }} />
          )}
        </>
      )}
    >
      {sortedSourceLanguages.map((lang) => (
        <Option
          key={lang}
          value={t("languages." + lang)}
          onClick={() => {
            if (targetLanguage.includes(lang)) {
              setTargetLanguage(
                sourceLanguage === "detect"
                  ? defaultTargetLanguage
                  : sourceToTarget(sourceLanguage)
              );
            }
            setSourceLanguage(lang);
          }}
        >
          {t("languages." + lang)}
          {lang === "detect" && <AutoAwesome color="primary" />}
        </Option>
      ))}
    </Select>
  );
}
function TargetLanguagePicker({
  targetLanguage,
  setTargetLanguage,
  sourceLanguage,
}: {
  sourceLanguage: DeepLSourceLanguage;
  targetLanguage: DeepLTargetLanguage;
  setTargetLanguage: (lang: DeepLTargetLanguage) => void;
}) {
  const { t } = useTranslation();
  const sortedTargetLanguages = [...DEEPL_LANGUAGES_TARGET]
    .sort((a, b) => t("languages." + a).localeCompare(t("languages." + b)))
    .filter((l) => !l.includes(sourceLanguage));
  return (
    <Select
      value={t("languages." + targetLanguage)}
      indicator={<KeyboardArrowDown />}
    >
      {sortedTargetLanguages.map((lang) => (
        <Option
          key={lang}
          value={t("languages." + lang)}
          onClick={() => {
            setTargetLanguage(lang);
          }}
        >
          {t("languages." + lang)}
        </Option>
      ))}
    </Select>
  );
}
