import type { ImageRequest } from "apiTypes";
import { useState } from "react";
import { GenerateImage } from "../../../components/image/GenerateImage";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Alert,
  Button,
  Card,
  FormControl,
  FormLabel,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Sheet,
  Textarea,
  Typography,
} from "@mui/joy";
import { useTranslation } from "../../../lib/i18n";
import { SegmentDisplay } from "../../../components/input/SegmentDisplay";
import type { ImageStyle } from "../../../components/image/styles";
import { imageStyles } from "../../../components/image/styles";
import { Close, Info } from "@mui/icons-material";
import { MarkdownRenderer } from "../../../components/chat/MarkdownRenderer";
import { useTrackImageGeneration } from "../../../lib/api/images";
import { useClosableInfoModal } from "../../../lib/context/infoModalStore";

type Amount = 1 | 2 | 3 | 4;

export default function ImageFactory() {
  const { t, i18n } = useTranslation();
  const [lastSubmitted, setLastSubmitted] = useState<ImageRequest | null>(null);
  const [lastAmount, setLastAmount] = useState<Amount | null>(null);

  const moreInfoModal = useClosableInfoModal("imageFactory-tutorial");

  const [prompt, setPrompt] = useState("");
  const [selectedSize, setSelectedSize] =
    useState<ImageRequest["size"]>("1024x1024");
  const [selectedAmount, setSelectedAmount] = useState<Amount>(1);
  const [quality, setQuality] = useState<ImageRequest["quality"]>("standard");
  const [selectedStyle, setSelectedStyle] = useState<ImageStyle | null>(null);

  const trackPrompt = useTrackImageGeneration();

  const onGenerate = () => {
    const promptTemplate = (selectedStyle?.promptSuffix ?? "").includes(
      "{{prompt}}"
    )
      ? selectedStyle?.promptSuffix
      : "{{prompt}}" + selectedStyle?.promptSuffix;

    const imageRequest = {
      model: "dall-e-3",
      prompt: promptTemplate?.replace("{{prompt}}", prompt) ?? prompt,
      quality,
      size: selectedSize,
      style: "vivid",
    } as ImageRequest;

    setLastSubmitted(imageRequest);
    setLastAmount(selectedAmount);
    trackPrompt({
      ...imageRequest,
      prompt,
      template: selectedStyle?.nameKey ?? "null",
      variations: selectedAmount,
    });
  };

  const [step, setStep] = useState(0);

  return (
    <Sheet variant="soft" className="min-h-screen">
      <div className="flex flex-col items-center justify-center gap-10 p-20">
        <Typography level="h1">{t("generateImage")} </Typography>
        {/* <Card> */}
        <AccordionGroup size="lg" variant="plain" transition="0.4s ease-in-out">
          <StyleAccordion
            selectedStyle={selectedStyle}
            setSelectedStyle={setSelectedStyle}
            expanded={step == 0}
            onNext={() => setStep(1)}
            onOpen={() => setStep(0)}
          />
          <Accordion
            expanded={step == 1}
            onChange={(_, expanded) => {
              if (expanded) {
                setStep(1);
              } else {
                setStep(3);
              }
            }}
          >
            <AccordionSummary>
              <div className="font-bold">
                <Typography>
                  {t("step")} 2: {t("images.prompt")}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Card>
                <div className="flex flex-col gap-4 p-5">
                  {moreInfoModal.modalOpen && (
                    <Alert
                      startDecorator={<Info />}
                      endDecorator={
                        <IconButton>
                          <Close onClick={() => moreInfoModal.onModalClose()} />
                        </IconButton>
                      }
                      color="primary"
                      className="whitespace-pre"
                    >
                      {t("images.promptInfo")}
                      <br />
                      <MoreInfoModal />
                    </Alert>
                  )}
                  <FormControl>
                    <FormLabel>{t("prompt")}</FormLabel>
                    <Textarea
                      minRows={3}
                      maxRows={6}
                      value={prompt}
                      onChange={(e) => setPrompt(e.target.value)}
                      placeholder={
                        i18n.exists(selectedStyle?.promptRecommendation ?? "")
                          ? t("images.promptPlaceholder", {
                              example: t(selectedStyle!.promptRecommendation!),
                            })
                          : t("images.promptPlaceholderEmpty")
                      }
                      endDecorator={
                        <div className="flex w-full flex-row justify-end">
                          <Button
                            onClick={onGenerate}
                            disabled={prompt.length == 0}
                            size="lg"
                          >
                            {lastSubmitted ? t("regenerate") : t("generate")}
                          </Button>
                        </div>
                      }
                    />
                  </FormControl>
                </div>
                <AccordionGroup size="sm">
                  <Accordion>
                    <AccordionSummary>{t("images.advanced")}</AccordionSummary>
                    <AccordionDetails>
                      <div className="m-2 grid grid-cols-2 gap-2">
                        <Typography>{t("amount")}</Typography>
                        <SegmentDisplay<Amount>
                          size="md"
                          buttonFlex={1}
                          options={[
                            {
                              label: "1",
                              value: 1,
                            },
                            {
                              label: "2",
                              value: 2,
                            },
                            {
                              label: "3",
                              value: 3,
                            },
                            {
                              label: "4",
                              value: 4,
                            },
                          ]}
                          value={selectedAmount}
                          setValue={(value) => setSelectedAmount(value)}
                        />
                        <Typography>{t("images.size")}</Typography>
                        <SegmentDisplay<ImageRequest["size"]>
                          size="md"
                          buttonFlex={1}
                          options={[
                            {
                              label: t("square"),
                              value: "1024x1024",
                            },
                            {
                              label: t("images.wide"),
                              value: "1792x1024",
                            },
                            {
                              label: t("images.high"),
                              value: "1024x1792",
                            },
                          ]}
                          value={selectedSize}
                          setValue={(value) => setSelectedSize(value)}
                        />

                        <Typography>{t("images.quality")}</Typography>
                        <SegmentDisplay<ImageRequest["quality"]>
                          size="md"
                          buttonFlex={1}
                          options={[
                            {
                              label: "Standard",
                              value: "standard",
                            },
                            {
                              label: "HD",
                              value: "hd",
                            },
                          ]}
                          value={quality}
                          setValue={(value) => setQuality(value)}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </AccordionGroup>
              </Card>
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
        {/* </Card> */}
        {lastSubmitted && (
          <>
            <div className="flex flex-row gap-3">
              {new Array(lastAmount as number).fill({}).map((_, index) => (
                <GenerateImage prompt={lastSubmitted} key={index} width={256} />
              ))}
            </div>
            <Alert startDecorator={<Info />}>{t("images.historyHint")}</Alert>
          </>
        )}
      </div>
    </Sheet>
  );
}

function StyleAccordion({
  expanded,
  onNext,
  onOpen,
  selectedStyle,
  setSelectedStyle,
}: {
  expanded: boolean;
  onNext: () => void;
  onOpen: () => void;
  selectedStyle: ImageStyle | null;
  setSelectedStyle: (style: ImageStyle) => void;
}) {
  const { t } = useTranslation();
  return (
    <Accordion
      expanded={expanded}
      onChange={(_, newState) => {
        if (newState) {
          onOpen();
        } else {
          onNext();
        }
      }}
    >
      <AccordionSummary>
        <div className="font-bold">
          <Typography>
            {t("step")} 1:{" "}
            {selectedStyle
              ? t("images.style.selectedstyle", {
                  name: t(selectedStyle.nameKey),
                })
              : t("images.style.selectstyle")}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {/* <Card> */}
        <div className="grid grid-cols-1 gap-4 p-10 xl:grid-cols-2 2xl:grid-cols-3">
          {imageStyles.map((style) => (
            <StyleCard
              key={style.nameKey}
              style={style}
              selected={selectedStyle === style}
              onClick={() => {
                setSelectedStyle(style);
                onNext();
              }}
            />
          ))}
        </div>
        {/* </Card> */}
      </AccordionDetails>
    </Accordion>
  );
}

function StyleCard({
  style,
  selected,
  onClick,
}: {
  style: ImageStyle;
  selected: boolean;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="my-3 flex flex-col items-center gap-2">
      <Card
        onClick={onClick}
        className="cursor-pointer transition-all hover:shadow-lg"
        variant={selected ? "solid" : "outlined"}
        color={selected ? "primary" : "neutral"}
        sx={{
          width: 250,
          height: 250,
        }}
      >
        {typeof style.example === "string" ? (
          <img src={style.example} alt={style.nameKey} />
        ) : (
          style.example
        )}
      </Card>
      <Typography level="title-lg">{t(style.nameKey)}</Typography>
    </div>
  );
}

function MoreInfoModal() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="plain">
        {t("moreInfo")}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalOverflow>
          <ModalDialog>
            <ModalClose />
            <Typography level="h1">{t("images.tutorial.title")}</Typography>
            <MarkdownRenderer
              content={t("images.tutorial.body")}
            ></MarkdownRenderer>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
