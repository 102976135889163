import type { PatchApiDepartment } from "apiTypes";
import { ApiDepartment, ApiDepartmentUser } from "apiTypes";
import {
  useOrganizationApi,
  useOrganizationSchemaResource,
  useOrganizationSWR,
} from "../hooks/useApi";

export function useAdminDepartments() {
  return useOrganizationSchemaResource(
    `departments/admin`,
    ApiDepartment.array()
  );
}

export function useMutateAdminDepartments() {
  return useOrganizationSWR(`departments/admin`).mutate;
}

export function useDepartments() {
  return useOrganizationSchemaResource(`departments`, ApiDepartment.array());
}

export function useMutateDepartments() {
  return useOrganizationSWR(`departments`).mutate;
}

export function useDepartment(id: string) {
  return useOrganizationSchemaResource(`departments/${id}`, ApiDepartment);
}

export function useMutateDepartment(id: string = "personal") {
  const mutateDepartments = useMutateDepartments();
  const mutateDepartment = useOrganizationSWR(`departments/${id}`).mutate;
  return async () => {
    await mutateDepartments();
    await mutateDepartment();
  };
}

export function usePersonalDepartment() {
  return useOrganizationSchemaResource(`departments/personal`, ApiDepartment);
}

export function useCreateDepartment() {
  const api = useOrganizationApi();
  const mutateDepartments = useMutateDepartments();
  return async () => {
    await api.post(`departments`);
    await mutateDepartments();
  };
}

export function useUpdateDepartment(id: string) {
  const api = useOrganizationApi();
  const mutateDepartments = useMutateDepartments();
  const mutateDepartment = useMutateDepartments();
  return async (body: PatchApiDepartment) => {
    await api.patch(`departments/${id}`, body);
    await mutateDepartments();
    await mutateDepartment();
  };
}

export function useDeleteDepartment() {
  const api = useOrganizationApi();
  const mutateDepartments = useMutateDepartments();

  return async (id) => {
    await api.delete(`departments/${id}`);
    await mutateDepartments();
  };
}

export function useDepartmentUsers(
  departmentId: string
): ApiDepartmentUser[] | undefined {
  return useOrganizationSchemaResource(
    `departments/${departmentId}/users`,
    ApiDepartmentUser.array()
  );
}

export function useMutateDepartmentUsers(departmentId: string): () => void {
  const mutateDepartmentUsers = useOrganizationSWR(
    `departments/${departmentId}/users`
  ).mutate;
  return mutateDepartmentUsers;
}
