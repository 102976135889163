import { Sheet } from "@mui/joy";
import { Outlet } from "react-router-dom";

export default function CourseView() {
  return (
    <Sheet className="min-h-screen flex flex-col p-8" variant="soft">
      <Outlet />
    </Sheet>
  );
}
