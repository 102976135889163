import { useRef, useState } from "react";
import type { Payload } from "apiTypes";
import { useCompletion } from "../../api/completions";
import type { ApiMessage } from "apiTypes";
import { compilePrompt, prompts } from "../prompts";

export type EvaluationWithCompleted = Payload.ChallengeEvaluation & {
  completed: boolean;
};

export type ChallengeEvaluationsScore = {
  evaluations: EvaluationWithCompleted[];
  numSolved: number;
  hasResults: boolean;
  onMessageChange: (messages: ApiMessage[]) => Promise<void>;
  loading: boolean;
  resetResults: () => void;
  solved: boolean;
  solutionAttempt: number;
  reEvaluate: () => void;
};

export function useChallengeEvaluationsScore(
  evaluations: Payload.ChallengeEvaluation[]
): ChallengeEvaluationsScore {
  const complete = useCompletion();
  const [results, setResults] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(false);

  const [firstMessageSent, setFirstMessageSent] = useState(false);

  const [solved, setSolved] = useState(false);

  const [solutionAttempt, setSolutionAttempt] = useState<number>(0);

  const lastMessages = useRef<ApiMessage[]>([]);

  const onMessageChange = async (messages: ApiMessage[]) => {
    lastMessages.current = messages;
    setFirstMessageSent(true);
    if (evaluations.length === 0) {
      setSolutionAttempt((curr) => curr + 1);
      return;
    }
    // if there are no uncompelted evaluations, return
    if (evaluations.every((evaluation) => results[evaluation.title]) === true) {
      return;
    }

    setLoading(true);
    const messagesAsXml = `<messages>${messages
      .map(
        (message) =>
          `<message role="${message.fromAi ? "assistant" : "user"}">${
            message.content
          }</message>`
      )
      .join("")}</messages>`;

    const criteria = Object.fromEntries(
      evaluations
        .map((evaluation) => [evaluation.title, evaluation.prompt])
        .filter(
          //filter out criteria that are already solved
          ([title]) => !results[title as string]
        )
    );

    const prompt = compilePrompt(prompts.evaluation.prompt, {
      messages: messagesAsXml,
      criteria: JSON.stringify(criteria),
    });

    const response = await complete(
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        messages: prompt as any,
        response: "json_object",
      },
      "gpt-4o-us"
    );
    const parsedResponse = JSON.parse(response.message);
    const filteredResponse = Object.fromEntries(
      //@ts-expect-error worked before i tightened the linter, needs to be migrated to trpc, so not bothering now
      Object.entries(parsedResponse).filter(([key]) => key in criteria) // filter out responses that are not in the criteria
    ) as Record<string, boolean>;

    setResults((curr) => {
      const newResults = { ...curr, ...filteredResponse };
      // if there are no uncompleted evaluations, set solved to true
      if (
        evaluations.every((evaluation) => newResults[evaluation.title]) === true
      ) {
        setSolved(true);
      }
      return newResults;
    });
    setLoading(false);
    setSolutionAttempt((curr) => curr + 1);
  };

  const numSolved = Object.values(results).filter(Boolean).length;

  const resetResults = () => {
    setResults({});
    setFirstMessageSent(false);
    lastMessages.current = [];
  };

  const reEvaluate = () => {
    onMessageChange(lastMessages.current).catch(console.error);
  };

  const baseResponse = {
    onMessageChange,
    loading,
    resetResults,
    solved,
    solutionAttempt,
    reEvaluate,
  };

  if (evaluations.length === 0) {
    // if there are no evaluations, there will be only one evaluation, which is to send the first message
    if (!solved && firstMessageSent) {
      setSolved(true);
    }
    return {
      ...baseResponse,
      evaluations: [
        {
          title: "Schicke eine Nachricht",
          prompt:
            "Schicke deine erste Nachricht an den Chatbot, um diese Aufgabe zu lösen",
          completed: firstMessageSent,
        } as Payload.ChallengeEvaluation & {
          completed: boolean;
        },
      ],
      numSolved: firstMessageSent ? 1 : 0,
      hasResults: firstMessageSent,
    };
  }

  return {
    ...baseResponse,
    evaluations: evaluations.map((evaluation) => ({
      ...evaluation,
      completed: results[evaluation.title],
    })),
    numSolved,
    hasResults: Object.keys(results).length > 0,
  };
}
