import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IntroTourStore {
  step: number;
  setStep: (step: number) => void;
  run: boolean;
  setRun: (run: boolean) => void;
  completed: boolean;
  setCompleted: (completed: boolean) => void;
}

export const useGuide = create(
  persist<IntroTourStore>(
    (set) => ({
      step: 0,
      setStep: (step: number) => {
        set({ step });
        console.log("step set to", step);
      },
      run: true,
      completed: false,
      setRun: (run: boolean) => {
        set({ run });
        console.log("run set to", run);
      },
      setCompleted: (completed: boolean) => {
        set({ completed });
        console.log("completed set to", completed);
      },
    }),
    {
      name: "intro-tour",
    }
  )
);
