//Not sure if this is the right place to put this, but I'm going to put it here for now.
export const ALL_SERVICES = [
  "dalle3",
  "documentIntelligence",
  "creditSystem",
  "entraId",
  "helpdesk",
  "meetingSummarizer",
  "translateText",
];
