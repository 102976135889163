import { ChevronRight } from "@mui/icons-material";
import { Button, Card, Typography } from "@mui/joy";
import ConfettiExplosion from "react-confetti-explosion";
import { useNavigate, useParams } from "../../../router.ts";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import type { UserXP } from "../../../lib/api/gamification.ts";
import { calcUserXP, useFinishCourse } from "../../../lib/api/gamification.ts";
import { useEffect, useState } from "react";
import { XPBar } from "../../../components/gamification/XPBar.tsx";
import { useTranslation } from "react-i18next";

export default function DonePage() {
  const navigate = useNavigate();
  const finishCourse = useFinishCourse();

  const { t } = useTranslation();

  const { organizationId, courseId, workshopId } = useParams(
    "/:organizationId/learn/:workshopId/course/:courseId"
  );

  function navigateToFirstExercise() {
    navigate(`/:organizationId/learn/:workshopId/course/:courseId`, {
      params: { organizationId, courseId, workshopId },
    });
  }

  const [awardedXp, setAwardedXp] = useState(0);
  const [userXpData, setUserXPData] = useState(null as UserXP | null);

  useEffect(() => {
    const finishTheCourse = async () => {
      try {
        const resp = await finishCourse({ courseId: parseInt(courseId) }).catch(
          (error) => {
            console.error(error);
            throw error;
          }
        );
        if (!resp.data?.completedCourse || !resp.data?.userXp) {
          if (resp.status) {
            console.warn(
              "Course has already been completed, no further XP rewards"
            );
          } else {
            console.error("Error finishing course: ", resp);
          }
          return;
        }

        const userData = calcUserXP(
          resp.data.userXp,
          resp.data.completedCourse.awardedXp
        );

        // this is here since setting state takes a render, so it won't help to fetch awardedXp for userData
        setAwardedXp(resp.data.completedCourse.awardedXp);
        setUserXPData(userData);
      } catch (error) {
        console.error("Error finishing course: ", error);
      }
    };
    void finishTheCourse();
  }, []);

  return (
    <div className="flex w-max flex-grow flex-col items-center  justify-center gap-3">
      <ConfettiExplosion />
      <span className="text-7xl">🎉</span>
      <Typography level="h1">{t("youMadeIt")}</Typography>
      <div className="mb-8 max-w-md text-center">
        <Typography>{t("allExercisesDone")}</Typography>
      </div>
      {userXpData && (
        <Card className="mb-12 flex flex-col items-center gap-2">
          <Typography level="h4">
            {t("pointsReceived", { points: awardedXp })}
          </Typography>
          {userXpData.isNewLevel && (
            <Typography level="h4" className="whitespace-pre-wrap text-center">
              {t("newLevelUnlocked")} {userXpData.currentLevel}
            </Typography>
          )}
          <XPBar userXPData={userXpData} small={false} />
          <Typography level="body-sm">{t("pointsTopRight")}</Typography>
        </Card>
      )}
      <Button
        color="primary"
        sx={{
          marginBottom: "1rem",
        }}
        onClick={() => {
          navigate(`/:organizationId/learn/:workshopId`, {
            params: { organizationId, workshopId },
          });
        }}
      >
        <div className="flex flex-row items-center gap-2">
          <span>{t("backToCourseOverview")}</span>
          <ChevronRight />
        </div>
      </Button>
      <Button
        variant="plain"
        color="neutral"
        sx={{
          marginBottom: "1rem",
        }}
        onClick={() => {
          navigateToFirstExercise();
        }}
      >
        <div className="flex flex-row items-center gap-2">
          <RestartAltIcon />
          <span>{t("restartCourse")}</span>
        </div>
      </Button>
    </div>
  );
}
