import { CircularProgress, LinearProgress } from "@mui/joy";
import type { ComponentProps } from "react";
import { useState, useEffect } from "react";

export function CircularFakeLoader({
  timeTo90,
  ...rest
}: { timeTo90: number } & ComponentProps<typeof CircularProgress>) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((currentProgress) => Math.min(currentProgress + 10, 90));
    }, timeTo90 / 10);
    return () => clearInterval(interval);
  }, [timeTo90]);

  return <CircularProgress determinate value={progress} {...rest} />;
}

export function LinearFakeLoader({
  timeTo90,
  ...rest
}: { timeTo90: number } & ComponentProps<typeof LinearProgress>) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((currentProgress) => Math.min(currentProgress + 10, 90));
    }, timeTo90 / 10);
    return () => clearInterval(interval);
  }, [timeTo90]);

  return (
    <div className="w-full">
      <LinearProgress determinate value={progress} {...rest} />
    </div>
  );
}
