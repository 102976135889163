import { Add, AutoAwesome, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Dropdown,
  IconButton,
  Input,
  List,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/joy";
import type { ApiDepartment } from "apiTypes";
import Fuse from "fuse.js";
import { useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import { useDepartments, useMutateDepartments } from "../../lib/api/deparment";
import { useCurrentOrganizationId } from "../../lib/api/trpc/helpers/useCurrentOrganizationId.ts";
import { trpc } from "../../lib/api/trpc/trpc.ts";
import { useTranslation } from "../../lib/i18n";
import { optimisticWorkflowReorder } from "../../lib/optimistic/reordering";
import { useModals } from "../../router";
import { DelayedLoader } from "../util/DelayadLoader";

import { NewWorkflowButton } from "./tree/NewWorkflowButton.tsx";
import { WorkflowItem } from "./tree/WorkflowItem.tsx";

export function OrganizationTree() {
  const { t } = useTranslation();
  const modals = useModals();
  const orgId = useCurrentOrganizationId();

  const departments = useDepartments();
  const mutateDepartments = useMutateDepartments();

  const { mutateAsync: updateWorkflowDepartmentAndPostion } =
    trpc.workflows.updateDepartmentAndPosition.useMutation();
  const searchBarRef = useRef<HTMLInputElement | null>(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const filteredDepartments = useMemo(() => {
    if (searchValue.trim() === "") return departments;

    const departmentsWithFilteredWorkflows = departments?.map((department) => {
      const fuse = new Fuse(department.workflows, {
        keys: ["name", "description"],
      });

      const filteredWorkflows = fuse
        .search(searchValue.trim())
        .map((result) => result.item);

      return {
        ...department,
        workflows: filteredWorkflows,
      };
    });

    return departmentsWithFilteredWorkflows?.filter(
      (department) => department.workflows.length > 0
    );
  }, [departments, searchValue]);

  if (!searchVisible && searchValue) setSearchValue("");

  if (!departments) return <DelayedLoader />;

  const moveWorkflowToDepartment = async (
    workflowId: string,
    departmentId: string,
    index: number
  ) => {
    mutateDepartments(
      optimisticWorkflowReorder(departments, workflowId, departmentId, index),
      {
        revalidate: false,
      }
    ).catch(console.error);

    await updateWorkflowDepartmentAndPostion({
      organizationId: orgId,
      workflow: { id: workflowId, departmentId, index },
    }).then(async () => {
      await mutateDepartments();
      toast.success(t("workflowMoved"));
    });
  };

  return (
    <div>
      <div className="gap flex flex-row items-center">
        <Typography
          level="title-md"
          color="neutral"
          sx={{
            px: 2,
            py: 1,
          }}
        >
          {t("workflows")}
        </Typography>

        <Dropdown>
          <MenuButton
            slots={{
              root: "div",
            }}
            sx={{
              // icon font size
              "--Icon-fontSize": "18px",
            }}
          >
            <IconButton size="sm">
              <Add fontSize="small" />
            </IconButton>
          </MenuButton>

          <Menu>
            <MenuItem
              onClick={() => modals.open("/[organizationId]/workflows/wizard")}
            >
              <div className="flex flex-row items-center gap-2">
                <div className="text-blue-600">
                  <AutoAwesome htmlColor="inherit" />{" "}
                </div>
                {t("workflowEditor.newButton.auto")}
              </div>
            </MenuItem>
            <MenuItem>
              <NewWorkflowButton>
                {t("workflowEditor.newButton.manual")}
              </NewWorkflowButton>
            </MenuItem>
          </Menu>
        </Dropdown>
        <Tooltip title={t("search")} placement="top" arrow>
          <IconButton
            size="sm"
            variant={searchVisible ? "solid" : "plain"}
            color={searchVisible ? "primary" : "neutral"}
            onClick={() => {
              setSearchVisible((prev) => !prev);
              setSearchValue("");

              !searchVisible && searchBarRef.current?.focus();
            }}
          >
            <Search fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <div
        className="px-3 transition-all"
        style={{
          height: searchVisible ? "auto" : "0",
          opacity: searchVisible ? 1 : 0,
        }}
      >
        <Input
          type="text"
          size="sm"
          placeholder={t("search")}
          slotProps={{
            input: {
              ref: searchBarRef,
            },
          }}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setSearchVisible(false);
              setSearchValue("");
            }
          }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) return;
          if (
            result.destination.droppableId === result.source.droppableId &&
            result.destination.index === result.source.index
          )
            return;
          moveWorkflowToDepartment(
            result.draggableId,
            result.destination.droppableId,
            result.destination.index
          ).catch((e) => {
            console.error(e);
          });
        }}
      >
        <AccordionGroup size="sm" disableDivider>
          {filteredDepartments?.map((department) => (
            <DepartmentWorkflows key={department.id} department={department} />
          ))}
        </AccordionGroup>
      </DragDropContext>
      {searchVisible && filteredDepartments?.length === 0 && (
        <div className="p-4">
          <Typography level="body-sm" fontStyle="italic">
            {t("noResults")}
          </Typography>
        </div>
      )}
    </div>
  );
}

function DepartmentWorkflows({ department }: { department: ApiDepartment }) {
  const { t } = useTranslation();

  const displayName = department.isPersonal
    ? t("personalArea")
    : department.name;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (department.workflows.length === 0) setOpen(false);
    if (department.workflows.length > 0) setOpen(true);
  }, [department.workflows.length]);

  return (
    <Droppable
      droppableId={department.id}
      isCombineEnabled={false}
      isDropDisabled={!department.writePermission}
    >
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={twMerge(
              "rounded border border-transparent",
              snapshot.isDraggingOver && "border-gray-600 bg-gray-300"
            )}
          >
            <Accordion
              expanded={open}
              onChange={(_, isOpen) => setOpen(isOpen)}
            >
              <AccordionSummary
                slotProps={{
                  button: {
                    sx: {
                      flexDirection: "row-reverse",
                      justifyContent: "flex-end",
                      gap: 0,
                      backgroundColor: snapshot.isDraggingOver
                        ? "inherit !important"
                        : undefined,
                    },
                  },
                }}
              >
                <div className="flex flex-row items-center justify-between pl-2">
                  <Typography level="title-sm" color="neutral">
                    {displayName}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <List size="sm">
                  {department.workflows
                    .sort((a, b) => a.index - b.index)
                    .map(({ id: workflowId }, i) => (
                      <Draggable
                        draggableId={workflowId}
                        index={i}
                        key={workflowId}
                        isDragDisabled={!department.writePermission}
                      >
                        {(providedDraggable) => (
                          <div
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.dragHandleProps}
                            {...providedDraggable.draggableProps}
                          >
                            <WorkflowItem
                              workflowId={workflowId}
                              key={workflowId}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {department.workflows.length === 0 && (
                    <Typography
                      level="body-sm"
                      color="neutral"
                      sx={{
                        px: 2,
                        py: 1,
                        fontStyle: "italic",
                      }}
                    >
                      {t("noWorkflows")}
                    </Typography>
                  )}
                  {department.workflows.length > 0 && provided.placeholder}
                </List>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      }}
    </Droppable>
  );
}
