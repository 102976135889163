import type { ApiTextModel } from "apiTypes";
import { useState } from "react";
import { ChatInput } from "../../components/chat/ChatInput";
import { ChatSettingsMenu } from "../../components/chat/ChatSettingsMenu";
import { useMutateChats, useCreateChat, useChats } from "../../lib/api/chat";
import { useQueuedMessagesStore } from "../../lib/context/queuedMessagesStore";
import { useNavigate, useParams } from "../../router";
import { Card, Typography } from "@mui/joy";
import { useRelativeTime } from "../../lib/util";
import { ArrowForward } from "@mui/icons-material";
import { useTranslation } from "../../lib/i18n";
import { useGuide } from "../../components/onboarding/useGuide";

export function ChatIndexScreen() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 flex-col items-stretch">
      <div className="flex flex-1 flex-col items-center">
        <div className="flex flex-col items-center gap-10">
          <Typography level="h1" color="neutral">
            {t("continue")}
          </Typography>
          <LastChatsTiles />
        </div>
      </div>
      <div className="flex flex-col items-stretch justify-center gap-4 bg-gradient-to-t from-white from-50% to-transparent p-10 py-20">
        <QuickStart />
      </div>
    </div>
  );
}

function QuickStart() {
  const navigate = useNavigate();
  const params = useParams("/:organizationId");
  const mutateChats = useMutateChats();
  const createChat = useCreateChat();
  const queueMessage = useQueuedMessagesStore((s) => s.addQueuedMessage);
  const { setRun } = useGuide();

  const [selectedModel, setSelectedModel] = useState<ApiTextModel | null>(null);

  async function postMessage(firstMessage: string, attachmentIds: string[]) {
    const chat = await createChat({
      name: firstMessage,
      modelOverride: selectedModel ?? undefined,
    });

    await mutateChats();

    navigate(`/:organizationId/chats/:chatId`, {
      params: {
        ...params,
        chatId: chat.data.id,
      },
    });

    queueMessage({
      content: firstMessage,
      chatId: chat.data.id,
      attachmentIds,
    });
    setRun(false);
  }

  return (
    <ChatInput
      postMessage={postMessage}
      startDecorator={
        <ChatSettingsMenu
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
        />
      }
    />
  );
}

function LastChatsTiles() {
  const chats = useChats();
  const lastChats = chats?.slice(0, 3) ?? [];

  return (
    <div className="grid grid-cols-3 gap-3">
      {lastChats.map((chat) => (
        <ChatTile key={chat.id} chat={chat} />
      ))}
    </div>
  );
}

function ChatTile({ chat }: { chat: ReturnType<typeof useChats>[number] }) {
  const navigate = useNavigate();
  const params = useParams("/:organizationId");
  const time = useRelativeTime(new Date(chat.updatedAt));

  return (
    <Card
      className="flex w-64 cursor-pointer flex-col justify-between transition-all hover:shadow-lg"
      onClick={() => {
        navigate(`/:organizationId/chats/:chatId`, {
          params: {
            ...params,
            chatId: chat.id,
          },
        });
      }}
    >
      <Typography level="title-lg">{chat.name}</Typography>
      <div className="flex flex-row items-center justify-between">
        <Typography>{time}</Typography>
        <ArrowForward />
      </div>
    </Card>
  );
}
