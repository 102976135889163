import { Link, useNavigate, useParams } from "../../router";
import { trpc } from "../../lib/api/trpc/trpc";
import { useEffect } from "react";
import { useAuthStore } from "../../lib/context/authStore";
import { DelayedLoader } from "../../components/util/DelayadLoader";
import { Alert, Button } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { ChevronLeft } from "@mui/icons-material";

export default function MagicLinkConsumer() {
  const params = useParams("/magic-link/:token");
  const navigate = useNavigate();
  const setLoggedIn = useAuthStore((s) => s.setLoggedIn);

  const { t } = useTranslation();

  const consumeToken = trpc.auth.magicLinks.consumeMagicLink.useMutation({
    trpc: {
      context: {
        disableStreaming: true,
      },
    },
  });

  const checkToken = trpc.auth.magicLinks.checkMagicLink.useQuery({
    token: params?.token,
  });

  useEffect(() => {
    if (consumeToken.isSuccess) {
      setLoggedIn(true);
      navigate("/");
    }
  }, [consumeToken.isSuccess, setLoggedIn, navigate]);

  if (checkToken.isPending) {
    return <DelayedLoader />;
  } else if (checkToken.error) {
    return (
      <div>
        <div className="flex flex-col gap-2">
          <Alert color="danger">{t(checkToken.error.message)}</Alert>
          <Link to="/">
            <Button variant="plain" startDecorator={<ChevronLeft />}>
              {t("backToLogin")}
            </Button>
          </Link>
        </div>
      </div>
    );
  } else if (checkToken.data?.valid) {
    return (
      <Alert>
        <div className="flex flex-col items-start gap-2">
          {t("magicLinkValid")}{" "}
          <Button
            onClick={() => {
              consumeToken.mutate({ token: params?.token });
            }}
            loading={consumeToken.isPending}
          >
            {t("login")}
          </Button>
        </div>
      </Alert>
    );
  }

  return null;
}
