import Joyride, { EVENTS, ACTIONS, STATUS } from "react-joyride";
import { usePrimaryColor } from "../../lib/hooks/useTheme";
import { useTranslation } from "../../lib/i18n";
import { useNavigate, useParams } from "../../router";
import { useQueuedPlaceholderStore } from "../chat/ChatInput";
import { useGuide } from "./useGuide";

export function IntroTour() {
  const params = useParams("/:organizationId");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor() ?? "#0B6BCB";
  const { step, setStep, run, setRun, setCompleted, completed } = useGuide();

  const { queuePlaceholder } = useQueuedPlaceholderStore();

  const handleCallback = (event) => {
    const { action, index, status, type } = event;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      if (action !== ACTIONS.PREV && type === EVENTS.STEP_AFTER) {
        if (index === 1) {
          navigate(`/:organizationId`, { params });
        } else if (index === 2) {
          queuePlaceholder(t("intro.step4.example"));
          document.getElementById("messageInput")?.focus();
        }
      }
      setStep(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRun(false);
      if (status === STATUS.FINISHED) {
        setCompleted(true);
      }
    }
  };

  return (
    <Joyride
      locale={{
        back: t("back"),
        close: t("close"),
        last: t("next"),
        next: t("next"),
        skip: t("skip"),
        open: t("open"),
      }}
      debug
      disableOverlayClose
      styles={{
        options: {
          primaryColor,
        },
        buttonNext: {
          backgroundColor: primaryColor,
          padding: "6px 16px",
          fontWeight: 600,
          lineHeight: "14px",
          minHeight: "36px",
          borderRadius: "6px",
        },
        tooltipTitle: {
          fontSize: "1.5rem",
          fontWeight: "bold",
        },
      }}
      continuous
      stepIndex={step}
      callback={handleCallback}
      disableScrollParentFix
      disableScrolling
      run={run && !completed}
      steps={[
        // first step, no element, just title and welcome
        {
          // 0
          title: t("intro.step1.title"),
          content: t("intro.step1.content"),
          placement: "center",
          target: "body",
        },
        {
          // 1
          title: t("intro.step2.title"),
          content: t("intro.step2.content"),
          target: "#sidebar",
          placement: "right",
        },
        {
          // 2
          title: t("intro.step3.title"),
          content: t("intro.step3.content"),
          target: "#newChatButton",
          spotlightClicks: true,
          //   styles: {
          //     buttonNext: {
          //       display: "none",
          //     },
          //   },
        },
        {
          // 3
          title: t("intro.step4.title"),
          content: t("intro.step4.content"),
          target: "#messageInput",
          spotlightClicks: true,
          placement: "bottom",
          styles: {
            buttonNext: {
              display: "none",
            },
          },
        },
        // {
        //   // 4
        //   title: "AI response",
        //   content: "The AI will respond to your message here",
        //   target: ".aiMessage",
        //   placement: "bottom",
        // },
        // {
        //   // 5
        //   title: "Follow up",
        //   content: "Type another message here",
        //   target: "#messageInput",
        //   spotlightClicks: true,
        //   placement: "top",
        //   styles: {
        //     buttonNext: {
        //       display: "none",
        //     },
        //   },
        // },
        // {
        //   // 6
        //   title: "Done",
        //   content: "Thats the tour done",
        //   target: "body",
        //   placement: "center",
        // },
      ]}
    />
  );
}
