import styled from "@emotion/styled";
import { ChevronLeft } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Chip,
  CircularProgress,
  Sheet,
  Typography,
} from "@mui/joy";
import type { Course } from "apiTypes/dist/payload-types";
import { useTranslation } from "react-i18next";
import LogoSolid from "../../assets/logo_solid.svg";
import { UserMenu } from "../../components/auth/UserMenu.tsx";
import Gradient from "../../components/elearning/header-bg.svg";
import { LiveWorkshopCard } from "../../components/elearning/LiveWorkshopCard.tsx";
import { WorkshopSwitcher } from "../../components/elearning/WorkshopSwitcher.tsx";
import { DelayedLoader } from "../../components/util/DelayadLoader.tsx";
import {
  useCaptureAnalyticsEvent,
  useCourse,
  useCourseProgress,
} from "../../lib/api/learning";
import { useOrganization } from "../../lib/api/organization.ts";
import {
  useRecommendedWorkshopCourses,
  useWorkshopCourses,
} from "../../lib/api/workshop.ts";
import { Link, useNavigate, useParams } from "../../router";

export const Header = styled.div`
  background-image: url(${Gradient});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export default function LearningHome() {
  const params = useParams("/:organizationId/learn/:workshopId");
  const courses = useWorkshopCourses(params.workshopId);
  const recommendedCourses = useRecommendedWorkshopCourses(params.workshopId);
  const organization = useOrganization();
  const workshopId = params.workshopId;

  const { t } = useTranslation();

  if (!courses) {
    return <DelayedLoader />;
  }

  const isWorkshop = params.workshopId !== "none";

  const recommendedId = recommendedCourses?.recommendedCourse?.id;

  const noCoursesFound =
    recommendedCourses?.easyCourses.length == 0 &&
    recommendedCourses?.mediumCourses.length == 0 &&
    recommendedCourses.hardCourses.length == 0 &&
    recommendedCourses.otherCourses.length == 0;

  return (
    <Sheet className="min-h-screen" variant="soft">
      <Header className="px-8 py-4 shadow-md">
        <div className="left-right-center">
          {params.organizationId != "academy" ? (
            <Button
              variant="plain"
              color="neutral"
              component={Link}
              to="/:organizationId"
              params={params}
              sx={{ justifySelf: "start", pl: 0 }}
            >
              <div className="flex flex-row items-center gap-2">
                <ChevronLeft />
                <span>{t("backToMeinGPT")}</span>
              </div>
            </Button>
          ) : (
            <div />
          )}
          {isWorkshop && workshopId !== organization?.defaultWorkshopId ? (
            <LiveWorkshopCard workshopId={params.workshopId} />
          ) : (
            <div />
          )}
          <div className="justify-self-end">
            <UserMenu isAcademy={true} languageSelector />
          </div>
        </div>
        <div className="my-8 flex flex-col items-center gap-5 text-white">
          <img src={LogoSolid} alt="Logo" className="h-20 w-20" />
          <Typography level="h1" className="!text-white">
            meinGPT Academy
          </Typography>
        </div>
      </Header>
      <div className="flex justify-center px-10 py-20">
        {noCoursesFound ? (
          <div className="flex flex-col items-center gap-5">
            <Typography level="h2" fontWeight={300} color="neutral">
              {t("workshopStartsSoon")}
            </Typography>
            <Typography color="neutral">{t("coursesAppearHere")}</Typography>
          </div>
        ) : null}
        <div className="flex flex-col gap-10">
          <CourseSection
            courses={recommendedCourses?.easyCourses ?? []}
            header={t("beginnerCourses")}
            recommendedId={recommendedId}
          />
          <CourseSection
            courses={recommendedCourses?.mediumCourses ?? []} // 100-200
            header={t("intermediateCourses")}
            recommendedId={recommendedId}
          />
          <CourseSection
            courses={recommendedCourses?.hardCourses ?? []} // 200-300
            header={t("advancedCourses")}
            recommendedId={recommendedId}
          />
          {(recommendedCourses?.otherCourses?.length ?? 0) > 0 && (
            <Accordion>
              <AccordionSummary>
                <Typography level="h4">{t("other courses")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="h-10"></div>
                <CourseSection
                  courses={recommendedCourses?.otherCourses ?? []}
                  recommendedId={recommendedId}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </div>
      <WorkshopSwitcher sx={{ position: "absolute", bottom: 20, right: 30 }} />
    </Sheet>
  );
}

export function CourseSection({
  courses,
  header,
  recommendedId,
}: {
  courses: Course[];
  workshopId?: string;
  header?: string;
  recommendedId?: number;
}) {
  if (courses?.length === 0) {
    return null;
  }

  const sorted = courses.sort(
    (a, b) => (a.difficultyRank ?? 0) - (b.difficultyRank ?? 0)
  );
  return (
    <>
      {header && (
        <Typography level="h4" className="w-full">
          {header}
        </Typography>
      )}
      <div className="grid max-w-5xl grid-cols-1 items-stretch gap-4 md:grid-cols-2 xl:grid-cols-3">
        {sorted.map((course) => (
          <CourseCard
            key={course.id}
            courseId={course.id + ""}
            recommended={course.id === recommendedId}
          />
        ))}
      </div>
    </>
  );
}

function CourseCard({
  courseId,
  recommended,
}: {
  courseId: string;
  recommended?: boolean;
}) {
  const captureAnalyticsEvent = useCaptureAnalyticsEvent();
  const course = useCourse(courseId);
  const params = useParams("/:organizationId/learn/:workshopId");
  const navigate = useNavigate();

  const { t } = useTranslation();

  const courseProgress = useCourseProgress(courseId);
  const progressRelative =
    (courseProgress?.numCompleted ?? 0) / (courseProgress.numTotal ?? 0);

  function onCardClick() {
    void captureAnalyticsEvent("COURSE_STARTED", {
      courseId: Number(courseId),
    });
    if (courseProgress.numCompleted === 0) {
      navigate(`/:organizationId/learn/:workshopId/course/:courseId`, {
        params: {
          organizationId: params.organizationId,
          workshopId: params.workshopId,
          courseId,
        },
      });
    } else if (courseProgress.numCompleted === courseProgress.numTotal) {
      navigate(`/:organizationId/learn/:workshopId/course/:courseId/done`, {
        params: {
          organizationId: params.organizationId,
          workshopId: params.workshopId,
          courseId,
        },
      });
    } else {
      navigate(
        `/:organizationId/learn/:workshopId/course/:courseId/exercise/:exerciseId`,
        {
          params: {
            organizationId: params.organizationId,
            workshopId: params.workshopId,
            courseId,
            exerciseId: courseProgress.firstUnsolvedExerciseId + "",
          },
        }
      );
    }
  }

  if (!course) {
    return null;
  }
  return (
    <Card
      color={recommended ? "success" : "neutral"}
      variant={recommended ? "outlined" : "outlined"}
      className="min-w-[300px] cursor-pointer transition-shadow hover:shadow-xl"
      onClick={onCardClick}
    >
      <div className="flex flex-row justify-between gap-2">
        <div className="flex flex-col">
          {recommended ? (
            <Chip color="success" variant="solid" size="sm" className="mb-1">
              {t("recommended")}
            </Chip>
          ) : null}
          <Typography level="title-lg">{course.title} </Typography>
          <Typography level="body-sm">{t("from")} meinGPT</Typography>
        </div>
        <CircularProgress
          value={progressRelative * 100}
          determinate
          size="lg"
          color={progressRelative === 1 ? "success" : "neutral"}
        >
          {Math.round(progressRelative * 100)}%
        </CircularProgress>
      </div>
      <Typography level="body-md">{course.description}</Typography>
    </Card>
  );
}
