import { z } from "zod";
import { ApiDate } from "../Date";

export const SupportRequest = z.object({
  problemDescription: z.string(),
});

export type SupportRequest = z.infer<typeof SupportRequest>;

export const SupportBotEvent = z.object({
  id: z.string(),
  timestamp: ApiDate,
  retries: z.number().int(),
  issueSolved: z.boolean(),
  ticketCreated: z.boolean(),
  supportRequest: z.string().optional().nullable(),
  suggestedSolution: z.string().optional().nullable(),
});

export type SupportBotEvent = z.infer<typeof SupportBotEvent>;

export const CreateSupportBotEvent = SupportBotEvent.pick({
  supportRequest: true,
});

export type CreateSupportBotEvent = z.infer<typeof CreateSupportBotEvent>;

export const UpdateSupportBotEvent = SupportBotEvent.omit({
  id: true,
  timestamp: true,
}).partial();

export type UpdateSupportBotEvent = z.infer<typeof UpdateSupportBotEvent>;

export const SupportBotAnalytics = z.object({
  totalRequests: z.number().int(),
  totalIssuesSolved: z.number().int(),
  totalTicketsCreated: z.number().int(),
  unknownOutcome: z.number().int(),
  solvedRequestsByDay: z.array(
    z.object({
      day: z.string(),
      solved_requests: z.number().int(),
      tickets_created: z.number().int(),
      unknown_outcome: z.number().int(),
    }),
  ),
});

export type SupportBotAnalytics = z.infer<typeof SupportBotAnalytics>;
