import { ChatIndexScreen } from "./_index.chat";

// const pages = [
//   {
//     name: (t: (key: string) => string) => t("chat"),
//     component: ChatIndexScreen,
//   },
//   {
//     name: (t: (key: string) => string) => t("workflows"),
//     component: WorkflowIndexScreen,
//   },
// ];

export default function OrganizationHome() {
  // const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);

  // const currentPage = pages[currentPageIndex]!;

  return (
    <div className="flex flex-col h-full w-full items-stretch">
      <div className="p-20 flex flex-col items-center">
        {/* <SegmentDisplay
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
        /> */}
      </div>
      <ChatIndexScreen />
    </div>
  );
}

// function SegmentDisplay({
//   currentPageIndex,
//   setCurrentPageIndex,
// }: {
//   currentPageIndex: number;
//   setCurrentPageIndex: (page: number) => void;
// }) {
//   const { t } = useTranslation();

//   return (
//     <ButtonGroup size="lg">
//       {pages.map((page, i) => (
//         <Button
//           key={i}
//           variant={i === currentPageIndex ? "solid" : "outlined"}
//           color="primary"
//           onClick={() => setCurrentPageIndex(i)}
//         >
//           {page.name(t)}
//         </Button>
//       ))}
//     </ButtonGroup>
//   );
// }
