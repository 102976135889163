import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  ListItem,
  ListItemButton,
  ListItemDecorator,
  ListItemContent,
  Stack,
  List,
  Typography,
  Tooltip,
  Modal,
  ModalDialog,
  ModalClose,
  Button,
} from "@mui/joy";
import React, { useState } from "react";
import { useTranslation } from "../../../lib/i18n";

export function TreeItem({
  selected,
  icon,
  onClick,
  onDelete,
  canDelete = true,
  name,
  isFolder = false,
  children,
  endDecorator,
  isEmpty,
  isButton = false,
}: {
  selected: boolean;
  canDelete?: boolean;
  icon: React.ReactNode | ((open: boolean) => React.ReactNode);
  onClick?: () => void;
  onDelete?: () => void;
  name: string;
  isFolder?: boolean;
  children?: React.ReactNode;
  endDecorator?: React.ReactNode;
  isEmpty?: boolean;
  isButton?: boolean;
}) {
  const { t } = useTranslation();

  const [folderOpen, setFolderOpen] = useState(true);

  const [areYouSureOpen, setAreYouSureOpen] = useState(false);

  const onClickAction = isFolder
    ? () => setFolderOpen(!folderOpen)
    : () => onClick?.();

  const loadChatsFormatting = isButton ? "pl-2 hover:underline" : "pl-2";

  return (
    <div className={loadChatsFormatting}>
      <ListItem nested={isFolder}>
        <ListItemButton
          onClick={onClickAction}
          selected={selected}
          className="group"
          sx={{
            borderRadius: "md",
            "&:hover": {
              backgroundColor: "neutral-light",
            },
          }}
        >
          <ListItemDecorator
            sx={{
              mr: isFolder ? 1.4 : 0,
            }}
          >
            {isFolder &&
              (folderOpen ? (
                <KeyboardArrowDown fontSize="small" />
              ) : (
                <KeyboardArrowRight fontSize="small" />
              ))}
            {icon instanceof Function ? icon(folderOpen) : icon}
          </ListItemDecorator>
          <ListItemContent className={loadChatsFormatting}>
            {name}
          </ListItemContent>
          <Stack
            direction="row"
            spacing={1}
            className="opacity-0 transition-all group-hover:opacity-100"
          >
            {canDelete && onDelete && (
              <Tooltip title={t("delete")}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setAreYouSureOpen(true);
                  }}
                >
                  <Delete fontSize="small" />
                </div>
              </Tooltip>
            )}
            {endDecorator}
          </Stack>
        </ListItemButton>
        {isFolder && folderOpen && (
          <List
            size="sm"
            sx={{
              pl: 2,
            }}
          >
            {children}
            {isEmpty && (
              <Typography
                sx={{
                  pl: 2,
                }}
                level="body-sm"
                color="neutral"
              >
                {t("empty")}
              </Typography>
            )}
          </List>
        )}
      </ListItem>
      <AreYouSure
        open={areYouSureOpen}
        onClose={() => setAreYouSureOpen(false)}
        onSure={onDelete ?? (() => {})}
      />
    </div>
  );
}

export function AreYouSure({
  open,
  onClose,
  onSure,
  customTitle,
  customMessage,
  customConfirmText,
}: {
  open: boolean;
  customMessage?: React.ReactNode;
  customTitle?: React.ReactNode;
  customConfirmText?: string;
  onClose: () => void;
  onSure: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <Typography level="title-lg">
          {customTitle ?? t("areYouSure")}
        </Typography>
        <Typography level="body-sm" className="max-w-md">
          {customMessage}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Button
            color="danger"
            onClick={() => {
              onSure();
              onClose();
            }}
          >
            {customConfirmText ?? t("deletePermanently")}
          </Button>
          <Button color="neutral" onClick={onClose}>
            {t("cancel")}
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
