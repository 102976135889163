import ColorizeIcon from "@mui/icons-material/Colorize";
import React, { useCallback, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useDebounceCallback } from "usehooks-ts";
import useClickOutside from "./useClickOutside";

interface PopoverPickerProps {
  color: string;
  onChange?: (color: string) => void;
}

export const PopoverPicker: React.FC<PopoverPickerProps> = ({
  color,
  onChange,
}: PopoverPickerProps) => {
  const popover = useRef<HTMLDivElement>(null);
  const [isOpen, toggle] = useState(false);
  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const popoverStyle: React.CSSProperties = {
    position: "absolute",
    top: "calc(100% + 2px)",
    left: 0,
    borderRadius: "9px",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  };
  const swatchStyle: React.CSSProperties = {
    width: "28px",
    height: "28px",
    borderRadius: "8px",
    outline: "thin solid gray",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color ?? "#ffffff",
  };

  const handleColorChange = (newColor: string) => {
    if (onChange) onChange(newColor);
  };

  const debouncedOnChange = useDebounceCallback(handleColorChange, 1000);

  return (
    <div className="relative z-10 mr-2">
      <div className="picker">
        <div style={swatchStyle} onClick={() => toggle(true)}>
          {color === null ? <ColorizeIcon sx={{ ml: 0 }} /> : null}
        </div>

        {isOpen && (
          <div ref={popover} style={popoverStyle}>
            <HexColorPicker
              color={color ?? "#ffffff"}
              onChange={debouncedOnChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};
