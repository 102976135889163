import type {
  ApiTextModel,
  CompletionRequest,
  CompletionResponse,
} from "apiTypes";
import { useOrganizationApi } from "../hooks/useApi";

export function useCompletion() {
  const orgApi = useOrganizationApi();

  return async (request: CompletionRequest, model: ApiTextModel) => {
    const response = await orgApi.post(`/ai/llm/${model}/completions`, request);
    return response.data as CompletionResponse;
  };
}
