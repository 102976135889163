import { Button, Stack } from "@mui/joy";
import { useParams } from "../../router";
import { useQueuedMessagesStore } from "../../lib/context/queuedMessagesStore";
import { useTranslation } from "../../lib/i18n";

export function SmartIterations({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslation();

  const iterationOptions = [
    {
      label: t("iterations.shorter.label"),
      emoji: "➖",
      prompt: t("iterations.shorter.prompt"),
    },
    {
      label: t("iterations.detail.label"),
      emoji: "➕",
      prompt: t("iterations.detail.prompt"),
    },
    {
      label: t("iterations.improve.label"),
      emoji: "🔧",
      prompt: t("iterations.improve.prompt"),
    },
    {
      label: t("iterations.precise.label"),
      emoji: "🎯",
      prompt: t("iterations.precise.prompt"),
    },
    {
      label: t("iterations.formal.label"),
      emoji: "👔",
      prompt: t("iterations.formal.prompt"),
    },
    {
      label: t("iterations.informal.label"),
      emoji: "👋",
      prompt: t("iterations.informal.prompt"),
    },
    ...(t("iterations.duzensiezen.enabled") == "true"
      ? [
          {
            label: "Duzen/Siezen",
            emoji: "👥",
            prompt: "Vertausche duzen und siezen",
          },
        ]
      : []),
  ];

  const { chatId } = useParams("/:organizationId/chats/:chatId");
  const enqueueMessage = useQueuedMessagesStore((s) => s.addQueuedMessage);

  return (
    <Stack direction="row" spacing={1}>
      {iterationOptions.map((option) => (
        <Button
          disabled={disabled}
          key={option.label}
          size="sm"
          variant="outlined"
          startDecorator={
            <span role="img" aria-label={option.label}>
              {option.emoji}
            </span>
          }
          onClick={() => {
            enqueueMessage({
              chatId,
              content: option.prompt,
            });
          }}
        >
          {option.label}
        </Button>
      ))}
    </Stack>
  );
}
