import { ApiTextModel, EnabledServices, ProductConfig } from "apiTypes";
import {
  useOrganizationApi,
  useOrganizationSWR,
  useOrganizationSchemaResource,
} from "../hooks/useApi";
import z from "zod";

const baseModelPath = "/services/disabled-models";

export function useEnabledServices() {
  return useOrganizationSchemaResource("services/enabled", EnabledServices);
}
//Enabled by global admins
export function useAvailableServices() {
  return useOrganizationSchemaResource("services/available", ProductConfig);
}

export function useAvailableModels() {
  return useOrganizationSchemaResource(
    "services/available-models",
    z.array(ApiTextModel)
  );
}

export function useDisableModel() {
  const api = useOrganizationApi();
  const mutateServices = useOrganizationSWR(`services/enabled`).mutate;

  return async (modelName: string) => {
    await api.post(`${baseModelPath}/${modelName}`);
    await mutateServices();
  };
}

export function useEnableModel() {
  const api = useOrganizationApi();
  const mutateServices = useOrganizationSWR(`services/enabled`).mutate;
  return async (modelName: string) => {
    await api.delete(`${baseModelPath}/${modelName}`);
    await mutateServices();
  };
}
