import { Button, Link, Tooltip, Typography } from "@mui/joy";
import { useTranslation } from "../../lib/i18n";
import {
  Card,
  Metric,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Title,
} from "@tremor/react";
import { useCreditsUsage } from "../../lib/api/credits";
import { DelayedLoader } from "../util/DelayadLoader";
import type { ColumnDef, PaginationState } from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { ApiCreditTransaction } from "apiTypes";
import { format } from "date-fns";
import { UserDisplay } from "../auth/UserMenu";
import { Info } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { useOrganizationFetcher } from "../../lib/hooks/useApi";
import useSWR from "swr";

const TransactionTable = () => {
  const { t } = useTranslation();
  const columns: ColumnDef<ApiCreditTransaction>[] = useMemo(
    () => [
      {
        accessorKey: "type",
        header: t(`credits.transactions.type.header`),
        cell: ({ row }) =>
          t(`credits.transactions.type.${row.getValue("type")}`),
      },
      {
        accessorKey: "creditAmount",
        header: t(`credits.transactions.amount.header`),
      },
      {
        accessorKey: "generationModel",
        header: t(`credits.transactions.model.header`),
      },
      {
        accessorKey: "userId",
        header: t(`users`),
        cell: ({ row }) => <UserDisplay userId={row.getValue("userId")} />,
      },
      {
        accessorKey: "date",
        header: t(`credits.transactions.date.header`),
        cell: ({ row }) => {
          const usedAt = row.getValue("date") as string;
          return format(new Date(usedAt), "yyyy-MM-dd HH:mm:ss");
        },
      },
    ],
    [t]
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const dataQuery = useSWR(
    `credits/transactions?page=${pagination.pageIndex + 1}`,
    useOrganizationFetcher(),
    { keepPreviousData: true }
  );

  const defaultData = useMemo(() => [], []);

  const table = useReactTable({
    columns,
    data: dataQuery?.data?.rows ?? defaultData,
    getCoreRowModel: getCoreRowModel(),
    pageCount: dataQuery.data?.pageCount ?? -1,
    getPaginationRowModel: getPaginationRowModel(),
    state: { pagination },
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  return (
    <Card>
      <Title>{t("credits.transactions.title")}</Title>
      <Table>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              className="border-b border-tremor-border"
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeaderCell key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHeaderCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </Card>
  );
};

const CREDITS_DOCS_URL = "https://docs.meingpt.com/cloud/credits";

export const CreditDisplay = () => {
  const { t } = useTranslation();
  const creditsUsage = useCreditsUsage();

  if (!creditsUsage) return <DelayedLoader />;

  return (
    <div className="flex flex-col gap-3 p-1">
      <Typography level="h3">
        <div className="flex flex-row items-center gap-2">
          <span>{t("settings.tabs.credits")}</span>
          <a
            href={CREDITS_DOCS_URL}
            target="_blank"
            rel="noreferrer"
            style={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              "--Icon-fontSize": "1.4rem",
            }}
          >
            <Tooltip title="Dokumentation" placement="right" size="sm">
              <Info fontSize="small" />
            </Tooltip>
          </a>
        </div>
      </Typography>

      <Card>
        <Title>{t("credits.balance")}</Title>
        <Metric>{creditsUsage.balance.toFixed(2)} Credits</Metric>
        <Link href={CREDITS_DOCS_URL} target="_blank" rel="noreferrer">
          {t("moreInfo")}
        </Link>
      </Card>

      <TransactionTable />
    </div>
  );
};
