import z from "zod";
import { ApiDate } from "./Date";

export const ApiTextModelEnum = z.enum([
  // openai
  "gpt-3.5-turbo",
  "gpt-4",

  // openai us
  "gpt-3.5-turbo-us",
  "gpt-4-us",
  "gpt-4o-us", // currently all gpt-4o requests are forwarded to gpt-4o-us, since we dont have the eu version yet. As soon as we add it, the redirect has to be removed!!!

  // perplexity
  "llama-3-sonar-large-32k-online",

  // mistral
  "mistral-small-latest",
  "mistral-medium-latest",
  "mistral-large-latest",

  // claude (all 3.0 models deprecated, use 3.5 instead)
  "claude-3-5-sonnet-20240620",

  // gemini
  "gemini-1.5-pro-preview-0409",
  "gemini-1.5-flash",
]);

export const ApiTextModel = ApiTextModelEnum.catch((ctx) => {
  switch (ctx.input as string) {
    case "gpt-4o": // currently all gpt-4o requests are forwarded to gpt-4o-us, since we dont have the eu version yet. As soon as we add it, the redirect has to be removed!!!
      return "gpt-4o-us";
    case "claude-3-opus-20240229": // deprecated
    case "claude-3-sonnet-20240229": // deprecated
    case "claude-3-haiku-20240307": // deprecated
      return "claude-3-5-sonnet-20240620"; // use 3.5 instead
    case "pplx-70b-online": // deprecated, use sonar-medium-online
    case "sonar-small-online":
    case "sonar-medium-online":
      return "llama-3-sonar-large-32k-online";
    case "codellama-70b-instruct": // deprecated
      return "gemini-1.5-pro-preview-0409";
    case "llama-2-70b-chat": // deprecated
    case "mixtral-8x7b-instruct":
      return "mistral-large-latest";
    default: {
      console.log("WARN: Unknown model", ctx.input);
      return "gpt-3.5-turbo";
    }
  }
});
export type ApiTextModel = z.infer<typeof ApiTextModel>;

// const deprecatedModelsMap: Record<string, ApiTextModel> = {
//   "pplx-70b-online": "sonar-small-online",
// };

export const ApiMessage = z.object({
  id: z.string(),
  content: z.string(),
  createdAt: ApiDate,
  fromAi: z.boolean(),
  responseCompleted: z.boolean().nullable(),
  authorId: z.string().nullable(),
  chatId: z.string(),
  generationModel: ApiTextModel.default("gpt-3.5-turbo").nullable(),
  attachmentIds: z.array(z.string()).default([]),
  citations: z.array(z.string()).default([]),
  cancelled: z.boolean().nullable(),
});

export type ApiMessage = z.infer<typeof ApiMessage>;
