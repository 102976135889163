type MimeMap = Record<string, string[]>;

export const extensionsByMimeType: MimeMap = {
  "application/pdf": [".pdf"],
  "application/msword": [".docx"],
  "application/msexcel": [".xlsx"],
  "application/mspowerpoint": [".pptx"],
  "text/plain": [".txt"],
  "text/csv": [".csv"],
  "text/vtt": [".vtt"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/bmp": [".bmp"],
  "image/tiff": [".tiff"],
  "image/heif": [".heif"],
};

export function getMimeListFromMap(map: MimeMap) {
  return Array.from(
    new Set([...Object.keys(map), ...Object.values(map).flat()])
  ).join(",");
}

export function getExtensionsFromMimeMap(map: MimeMap) {
  return Object.values(map).flat();
}

export const allowedMimeTypesForAdiDocuments =
  getMimeListFromMap(extensionsByMimeType);
