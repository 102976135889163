import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Chip, IconButton, Stack, Table, Typography } from "@mui/joy";
import type { ApiDepartment, PatchApiDepartment } from "apiTypes";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  useAdminDepartments,
  useCreateDepartment,
  useDeleteDepartment,
  useMutateAdminDepartments,
  useUpdateDepartment,
} from "../../../lib/api/deparment";
import { useEnabledServices } from "../../../lib/api/services.ts";
import { useTranslation } from "../../../lib/i18n";
import { DelayedLoader } from "../../util/DelayadLoader";
import { DepartmentEditor } from "./DepartmentEditor";

export function DepartmentsEditor() {
  const { t } = useTranslation();

  const departments = useAdminDepartments();
  const mutateDepartments = useMutateAdminDepartments();

  const createDepartment = useCreateDepartment();

  const enabledServices = useEnabledServices();

  if (!departments) return <DelayedLoader />;

  return (
    <Stack spacing={3}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center justify-between">
          <Typography level="h3">{t("department.plural")}</Typography>
          <Button
            onClick={async () => {
              await Promise.all([createDepartment(), mutateDepartments()]);
              toast.success("Neue Abteilung erstellt");
            }}
          >
            {t("department.create")}
          </Button>
        </div>
        <Typography color="neutral">{t("department.description")}</Typography>
      </div>
      <Table>
        <thead>
          <tr>
            <th>{t("name")}</th>
            <th>{t("members")}</th>
            {enabledServices?.entraId && (
              <th>{t("department.assignedEntraGroups")}</th>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {departments.map((department) => (
            <DepartmentListItem
              key={department.id}
              department={department}
              entraIdEnabled={enabledServices?.entraId}
            />
          ))}
        </tbody>
      </Table>
    </Stack>
  );
}

function DepartmentListItem({
  department,
  entraIdEnabled = false,
}: {
  department: ApiDepartment;
  entraIdEnabled?: boolean;
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const updateDepartment = useUpdateDepartment(department.id);
  const deleteDepartment = useDeleteDepartment();
  const mutateDepartments = useMutateAdminDepartments();

  function onSubmit(value: PatchApiDepartment) {
    updateDepartment(value)
      .then(() => {
        toast.success(t("department.saved"));
        void mutateDepartments();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t("department.saveFailed"));
      });
    setOpen(false);
  }

  function onDelete() {
    deleteDepartment(department.id)
      .then(() => {
        toast.success(t("department.deleted"));
        void mutateDepartments();
      })
      .catch((err) => {
        console.error(err);
        toast.error(t("department.deleteFailed"));
      });
  }

  if (!department) return <DelayedLoader />;

  return (
    <>
      <tr>
        <td>{department.name}</td>
        <td>
          {department.default
            ? t("department.visibleForAll")
            : department.userCount}
        </td>
        {entraIdEnabled ? (
          <td>
            {department.isPersonal ||
            !department.entraGroups ||
            department.entraGroups.length == 0
              ? "—"
              : department.entraGroups?.map((group) => (
                  <Chip key={group.id} variant="outlined" sx={{ mr: 1, mb: 1 }}>
                    {group.displayName}
                  </Chip>
                ))}
          </td>
        ) : null}
        <td>
          <div className="flex flex-row justify-end">
            <IconButton
              onClick={() => setOpen(true)}
              className="align-right"
              variant="soft"
            >
              <EditIcon />
            </IconButton>
            {department.id && (
              <IconButton onClick={onDelete} color="danger" variant="plain">
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </td>
      </tr>
      <DepartmentEditor
        defaultValues={department}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={onSubmit}
        entraIdEnabled={entraIdEnabled}
      />
    </>
  );
}
