import { z } from "zod";
import { Workflow } from "./Workflow";

export const ApiFolder = z.object({
  id: z.string(),
  name: z.string(),
  organizationId: z.string(),
  departmentId: z.string().nullable(),
  parentId: z.string().nullable(),
  workflows: Workflow.array(),
  folders: z
    .object({
      id: z.string(),
    })
    .array(),
});

export const CreateApiFolder = ApiFolder.omit({
  id: true,
  workflows: true,
  folders: true,
}).partial();

export const UpdateApiFolder = ApiFolder.partial().omit({
  id: true,
  workflows: true,
  folders: true,
});

export type ApiFolder = z.infer<typeof ApiFolder>;
export type CreateApiFolder = z.infer<typeof CreateApiFolder>;
export type UpdateApiFolder = z.infer<typeof UpdateApiFolder>;
