import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";

import "highlight.js/styles/github.css";
import { ContentCopy } from "@mui/icons-material";
import { Alert, CircularProgress, IconButton } from "@mui/joy";
import type { CSSProperties, ComponentProps } from "react";
import { useEffect, useRef, useState } from "react";
import { useCopySafe } from "../../lib/hooks/useCopySafe";
import React from "react";
import RehypeHighlight from "rehype-highlight";
import mermaid from "mermaid";
import { useDebouncedCallback } from "use-debounce";

const remarkPlugins = [remarkGfm, remarkBreaks];
const rehypePlugins = [
  [
    RehypeHighlight,
    {
      ignoreMissing: true,
      detect: true,
    },
  ],
] as ComponentProps<typeof ReactMarkdown>["rehypePlugins"];

function _MarkdownRenderer({
  content,
  style,
}: {
  content: string;
  style?: CSSProperties;
}) {
  return (
    <div className="markdown-body .light" style={style ?? {}}>
      <ReactMarkdown
        remarkPlugins={remarkPlugins}
        rehypePlugins={rehypePlugins}
        className="white-space-pre-wrap leading-loose"
        components={{
          p: (pProps) => <p {...pProps} dir="auto" />,
          a: (aProps) => {
            const href = aProps.href || "";
            const isInternal = /^\/#/i.test(href);
            const target = isInternal ? "_self" : aProps.target ?? "_blank";
            return <a {...aProps} target={target} />;
          },
          pre: CodeBlock,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          progress: CircularProgress as any,
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}

export const MarkdownRenderer = React.memo(_MarkdownRenderer);

function CodeBlock(props: ComponentProps<"pre">) {
  const copySafe = useCopySafe();
  const ref = useRef<HTMLSpanElement>(null);
  const refText = ref.current?.innerText;

  const [mermaidCode, setMermaidCode] = useState("");

  const renderMermaid = useDebouncedCallback(() => {
    if (!ref.current) return;
    const mermaidDom = ref.current.querySelector("code.language-mermaid");
    if (mermaidDom) {
      setMermaidCode((mermaidDom as HTMLElement).innerText);
    }
  }, 600);

  useEffect(() => {
    setTimeout(renderMermaid, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refText]);

  if (mermaidCode.length > 0) {
    return <Mermaid code={mermaidCode} key={mermaidCode} />;
  }

  return (
    <pre className="group/code relative">
      <div className="absolute right-0 top-0 p-2 opacity-0 transition-all group-hover/code:opacity-100">
        <IconButton
          size="sm"
          onClick={() => {
            copySafe(ref.current?.innerText ?? "");
          }}
        >
          <ContentCopy />
        </IconButton>
      </div>
      <span ref={ref}>{props.children}</span>
    </pre>
  );
}

export function Mermaid(props: { code: string }) {
  const ref = useRef<HTMLDivElement>(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (props.code && ref.current) {
      mermaid
        .run({
          nodes: [ref.current],
          // suppressErrors: true,
        })
        .catch((e) => {
          setHasError(true);
          console.error("[Mermaid] ", e.message);
        });
    }
  }, [props.code]);

  function viewSvgInNewWindow() {
    const svg = ref.current?.querySelector("svg");
    if (!svg) return;
    const text = new XMLSerializer().serializeToString(svg);
    const blob = new Blob([text], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);
    // open new window
    window.open(url, "_blank");
  }

  if (hasError) {
    return <Alert color="danger">Error rendering diagram.</Alert>;
  }

  return (
    <div
      className="no-dark mermaid"
      style={{
        cursor: "pointer",
        overflow: "auto",
      }}
      ref={ref}
      onClick={() => viewSvgInNewWindow()}
    >
      {props.code}
    </div>
  );
}
