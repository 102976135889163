import { useEffect, useState } from "react";
import { useMe } from "../../lib/api/user";
import formbricks from "@formbricks/js";
import { useOrganization } from "../../lib/api/organization";

export function FormBricks() {
  const user = useMe();
  const organization = useOrganization();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) return;
    if (!user) return;
    if (!organization) return;

    formbricks
      .init({
        environmentId: "clrnlzo3k000chxf6ibp5mmqp",
        apiHost: "https://forms.selectcode.dev",
        debug: true, // remove when in production
        userId: user.id,
        attributes: {
          email: user.primaryEmail ?? "unknown",
          tenantId: organization.tenantId ?? "unknown",
        },
      })
      .catch(console.error);

    setInitialized(true);
  }, [user, initialized, organization]);

  return null;
}
