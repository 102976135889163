import {
  IconButton,
  Input,
  List,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useOrganization } from "../../../lib/api/organization";
import { Add, AlternateEmail, Close } from "@mui/icons-material";
import { AreYouSure } from "../../sidebar/tree/LeafItem";
import { useBoolean } from "usehooks-ts";
import { z } from "zod";
import { toast } from "react-toastify";
import { trpc } from "../../../lib/api/trpc/trpc";
import { useCurrentOrganizationId } from "../../../lib/api/trpc/helpers/useCurrentOrganizationId";

export function DomainsEditor() {
  const { t } = useTranslation();

  const org = useOrganization();
  const utils = trpc.useUtils();
  const currOrgId = useCurrentOrganizationId();
  const mutateOrganization = trpc.organization.mutateOrganization.useMutation();

  const onAddDomain = async (domain: string) => {
    if (!org) return false;
    // cleanse, cleanup, and validate domain
    const cleanedDomain = domain
      .trim()
      .toLowerCase()
      // remove protocol
      .replace(/https?:\/\//, "")
      // remove path
      .split("/")[0];

    const parsedDomain = z
      .string()
      .min(1)
      .regex(
        // regex to validate domains with optional subdomains
        /^((?!:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?)?$/
      )
      .safeParse(cleanedDomain);

    if (!parsedDomain.success) {
      toast.error(t("settings.domainsEditor.invalidDomain"));
      return false;
    }

    if (org?.domain?.includes(parsedDomain.data)) {
      toast.info(t("settings.domainsEditor.alreadyAdded"));
      return false;
    }

    await mutateOrganization.mutateAsync({
      organizationId: currOrgId,
      domain: [...org.domain, parsedDomain.data],
    });
    void utils.organization.getOrganization.invalidate();
    toast.success(
      t("settings.domainsEditor.addedDomain", { domain: parsedDomain.data })
    );
    return true;
  };

  return (
    <div className="">
      <div className="flex flex-col gap-4">
        <Typography level="h3">{t("settings.tabs.domains")}</Typography>
        <Typography level="body-sm">
          {t("settings.domainsEditor.description")}
        </Typography>
        <div className="w-min">
          <List>
            {org?.domain?.map((domain) => (
              <DomainEntry domain={domain} key={domain} />
            ))}
          </List>
        </div>
        <div className="flex flex-col gap-1">
          <Typography level="title-md">
            {t("settings.domainsEditor.addDomain")}
          </Typography>
          <form
            className="flex flex-row items-center gap-2"
            onSubmit={(e) => {
              e.preventDefault();
              onAddDomain(e.target[0].value)
                .then((worked) => {
                  if (worked) e.target[0].value = "";
                })
                .catch(console.error);
            }}
          >
            <Input size="sm" placeholder="example.com" />
            <IconButton size="sm" variant="soft" color="primary" type="submit">
              <Add fontSize="small" />
            </IconButton>
          </form>
        </div>
      </div>
    </div>
  );
}

function DomainEntry({ domain }: { domain: string }) {
  const { t } = useTranslation();
  const modal = useBoolean(false);
  const org = useOrganization();
  const currOrgId = useCurrentOrganizationId();
  const utils = trpc.useUtils();
  const mutateOrganization = trpc.organization.mutateOrganization.useMutation();

  return (
    <ListItem key={domain} className="flex flex-row">
      <ListItemDecorator>
        <AlternateEmail />
      </ListItemDecorator>
      <ListItemContent>{domain}</ListItemContent>
      <AreYouSure
        open={modal.value}
        customMessage={t("settings.domainsEditor.deleteMessage", {
          domain,
        })}
        customConfirmText={t("delete")}
        customTitle={t("warning")}
        onClose={modal.setFalse}
        onSure={() => {
          mutateOrganization
            .mutateAsync({
              organizationId: currOrgId,
              domain: org?.domain?.filter((d) => d !== domain),
            })
            .then(() => {
              void utils.organization.getOrganization.invalidate();
              toast.success(
                t("settings.domainsEditor.deletedDomain", { domain })
              );
            })
            .catch(console.error);
        }}
      />
      <Tooltip title={t("delete")}>
        <IconButton onClick={modal.toggle}>
          <Close />
        </IconButton>
      </Tooltip>
    </ListItem>
  );
}
