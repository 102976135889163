import { BarChart, RestartAlt, ThumbDown, ThumbUp } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import {
  Alert,
  Button,
  Card,
  List,
  ListItem,
  Sheet,
  Textarea,
  Typography,
} from "@mui/joy";
import type { SupportBotEvent } from "apiTypes";
import { useCallback, useRef, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { BounceLoader } from "react-spinners";
import { toast } from "react-toastify";
import { MarkdownRenderer } from "../../../components/chat/MarkdownRenderer.tsx";
import {
  useCreateBotEvent,
  useUpdateBotEvent,
} from "../../../lib/api/supportAssistantAnalytics.ts";
import { useCurrentOrganizationId } from "../../../lib/api/trpc/helpers/useCurrentOrganizationId.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useUser } from "../../../lib/api/user.ts";
import { useStreamingOrganizationApi } from "../../../lib/hooks/useStreamingOrganizationApi";
import { usePrimaryColor } from "../../../lib/hooks/useTheme.ts";
import { useTranslation } from "../../../lib/i18n";
import { useModals } from "../../../router.ts";

enum ProblemSolutionState {
  Unknown,
  Solved,
  NotSolved,
}

export default function SupportAssistantPage() {
  const modals = useModals();
  const { t } = useTranslation();
  const user = useUser("me");

  const [creatingTicket, setCreatingTicket] = useState(false);
  const createBotEvent = useCreateBotEvent();
  const updateBotEvent = useUpdateBotEvent();

  const [botEvent, setBotEvent] = useState<SupportBotEvent | null>(null);
  const [response, setResponse] = useState<string>("");
  const [generating, setGenerating] = useState<boolean>(false);
  const [problemDescription, setProblemDescription] = useState<string>("");
  const [edited, setEdited] = useState<boolean>(false);

  const primaryColor = usePrimaryColor();

  const { mutateAsync: summarizeHelpRequest } =
    trpc.tools.supportAssistant.summarizeHelpRequest.useMutation();

  const [solved, setSolved] = useState<ProblemSolutionState>(
    ProblemSolutionState.Unknown
  );

  const orgId = useCurrentOrganizationId();

  const streamApi = useStreamingOrganizationApi();

  const cancelCurrent = useRef<() => void>(() => {});

  function reset() {
    if (cancelCurrent.current) {
      cancelCurrent.current();
    }
    setBotEvent(null);
    setSolved(ProblemSolutionState.Unknown);
    setResponse("");
    setProblemDescription("");
  }

  const generateSolution = useCallback(
    async (problem: string) => {
      if (!problem || generating || !edited) return;
      setEdited(false);
      setSolved(ProblemSolutionState.Unknown);
      let analyticsEvent: SupportBotEvent | null = null;
      if (botEvent === null) {
        analyticsEvent = await createBotEvent({ supportRequest: problem });
      } else {
        analyticsEvent = await updateBotEvent(botEvent.id, {
          supportRequest: problem,
          retries: botEvent.retries + 1,
        });
      }
      setBotEvent(analyticsEvent);

      cancelCurrent.current();

      let cancelled = false;

      cancelCurrent.current = () => {
        if (cancelled) return;
        cancelled = true;
        setGenerating(false);
      };

      setResponse("");

      setGenerating(true);

      const stream = await streamApi(`ai/tools/support`, {
        method: "POST",
        body: JSON.stringify({ problemDescription: problem }),
      });

      const decoder = new TextDecoder();

      let streamingResponse = "";
      let lastUpdate = Date.now();

      await stream!
        .pipeTo(
          new WritableStream({
            write(chunk) {
              console.log("chunk received");
              const textChunk = decoder.decode(chunk);
              streamingResponse += textChunk;
              if (Date.now() - lastUpdate < 200) return;

              lastUpdate = Date.now();
              setResponse(streamingResponse);
            },
            close() {
              setResponse(streamingResponse);
            },
          })
        )
        .finally(() => {
          if (cancelled) return;
          void updateBotEvent(analyticsEvent.id, {
            suggestedSolution: streamingResponse,
          });
          setGenerating(false);
        });
    },
    [botEvent, createBotEvent, streamApi, updateBotEvent]
  );

  const hasAnswer = Boolean(response) || generating;

  async function createSupportTicket() {
    setCreatingTicket(true);
    try {
      if (botEvent != null) {
        await updateBotEvent(botEvent.id, {
          issueSolved: false,
          ticketCreated: true,
        });
      }
      const ticketContent = await summarizeHelpRequest({
        helpRequest: problemDescription,
        suggestedSolutions: response,
        organizationId: orgId,
      });
      window.open(
        `mailto:it_helpdesk@lauda.de?subject=${ticketContent.subject}&body=${encodeURIComponent(ticketContent.body)}`,
        "_self"
      );
    } catch (e) {
      toast.error(t("ticketCreateFailed"));
    } finally {
      setCreatingTicket(false);
    }
  }

  async function onProblemSolved() {
    if (botEvent != null) {
      setBotEvent(
        await updateBotEvent(botEvent.id, {
          issueSolved: true,
        })
      );
    }
    setSolved(ProblemSolutionState.Solved);
  }

  return (
    <Sheet variant="soft" className="min-h-screen p-8">
      <div className="flex w-full flex-col gap-6">
        <div className="flex items-center justify-between">
          <Typography level="h2">
            <SupportAgentIcon sx={{ mr: 2, fontSize: "1.8em" }} />
            {t("supportAssistant.title")}
          </Typography>
          {(user?.isSuperAdmin || user?.isGlobalAdmin) && (
            <Button
              onClick={() =>
                modals.open("/[organizationId]/tools/supportAssistantAnalytics")
              }
              startDecorator={<BarChart />}
            >
              {t("supportAssistant.analytics.title")}
            </Button>
          )}
        </div>

        <div className="flex w-full flex-col gap-6">
          <Typography level="h4">{t("supportAssistant.inputLabel")}</Typography>
          <Alert color="primary">
            <div>
              <Typography level="body-md">
                {t("supportAssistant.tipps.title")}
              </Typography>
              <List marker="disc" size="sm">
                {(
                  t("supportAssistant.tipps.bulletPoints", {
                    returnObjects: true,
                    defaultValue: [],
                  }) as string[]
                ).map((bulletPoint, index) => (
                  <ListItem key={index}>{bulletPoint}</ListItem>
                ))}
              </List>
            </div>
          </Alert>
          <Textarea
            disabled={generating}
            minRows={6}
            value={problemDescription}
            onChange={(e) => {
              setEdited(true);
              setProblemDescription(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !(e.shiftKey || e.altKey)) {
                void generateSolution(problemDescription);
              }
            }}
          />
          <div className="mb-8 flex justify-end gap-2">
            {generating ? (
              <Button
                variant="solid"
                startDecorator={<ClearIcon />}
                onClick={() => {
                  cancelCurrent.current();
                }}
              >
                {t("cancel")}
              </Button>
            ) : (
              <Button
                variant="solid"
                endDecorator={<SendSharpIcon />}
                onClick={() => {
                  setEdited(false);
                  return generateSolution(problemDescription);
                }}
                disabled={(hasAnswer || !problemDescription) && !edited}
              >
                {t("supportAssistant.generate")}
              </Button>
            )}
          </div>
        </div>
        {hasAnswer && (
          <>
            <Typography level="h4">
              {t("supportAssistant.proposedSolution")}
            </Typography>
            <Card>
              {generating && (
                <div className="absolute bottom-0 left-0 px-4 py-2">
                  <BounceLoader color={primaryColor} key="spinner" size={20} />
                </div>
              )}
              <MarkdownRenderer content={response} />
            </Card>
          </>
        )}
        {hasAnswer &&
          !generating &&
          {
            [ProblemSolutionState.Unknown]: (
              <div className="flex justify-end gap-4">
                <Button startDecorator={<ThumbUp />} onClick={onProblemSolved}>
                  {t("supportAssistant.problemSolved")}
                </Button>
                <Button
                  startDecorator={<ThumbDown />}
                  onClick={() => setSolved(ProblemSolutionState.NotSolved)}
                >
                  {t("supportAssistant.problemNotSolved")}
                </Button>
              </div>
            ),
            [ProblemSolutionState.NotSolved]: (
              <div className="flex justify-between gap-4">
                <Typography>
                  {t("supportAssistant.tryAddingDetails")}
                </Typography>
                <Button
                  startDecorator={<ContactSupportIcon />}
                  onClick={createSupportTicket}
                  loading={creatingTicket}
                >
                  {t("supportAssistant.createTicket")}
                </Button>
              </div>
            ),
            [ProblemSolutionState.Solved]: (
              <div className="flex gap-4">
                <Typography>
                  {t("supportAssistant.solvedSuccessfully")}
                </Typography>
                <div className="grow"></div>
                <ConfettiExplosion />
                <Button onClick={reset} startDecorator={<RestartAlt />}>
                  {t("supportAssistant.newProblem")}
                </Button>
              </div>
            ),
          }[solved]}
      </div>
    </Sheet>
  );
}
