import {
  AutoAwesome,
  Groups,
  Memory,
  PlayCircle,
  SupportAgent,
  Wallpaper,
  WhatsApp,
  WifiOff,
} from "@mui/icons-material";

// AI Workflows
// AI Workflow Assistant
// Image Generation
// Azure AD Sync
// "Further extensions" (Talk to us)
// Website Chatbot
// Custom Data Integration
// WhatsApp Integration
// Vector Image Creator
// Local LLM

export const mockModules = [
  {
    name: "AI Workflows",
    icon: <PlayCircle />,
    description:
      "Create and run AI workflows to automate your tasks and save time",
    version: "3.2.1",
  },
  {
    name: "AI Workflow Assistant",
    icon: <AutoAwesome />,
    description:
      "Automatically generate fully custom AI workflows from a simple prompt",
    version: "1.0.2",
  },
  {
    name: "Image Generation",
    icon: <Wallpaper />,
    description:
      "Generate images with AI using different preset styles and sizes",
    version: "2.7.0",
  },
  {
    name: "Azure AD Sync",
    icon: <Groups />,
    description: "Sync your Azure AD (EntraID) groups with meinGPT departments",
    version: "1.0.0",
  },
  {
    name: "Website Chatbot",
    icon: <SupportAgent />,
    description:
      "Add a customer facing support chatbot powered by meinGPT to your website",
    version: "1.0.0",
    disabled: true,
  },
  {
    name: "Custom Data Integration",
    icon: <Memory />,
    description: "Import various data sources into meinGPT for AI workflows",
    version: "1.0.0",
    disabled: true,
  },
  {
    name: "WhatsApp Integration",
    icon: <WhatsApp />,
    description: "Integrate the meinGPT AI assistant with WhatsApp for support",
    version: "1.0.0",
    disabled: true,
  },
  {
    name: "Vector Image Creator",
    icon: <Wallpaper />,
    description: "Create vector images with AI",
    version: "1.0.0",
    disabled: true,
  },
  {
    name: "Local LLM",
    icon: <WifiOff />,
    description:
      "Run your own local instances of LLMs. Max privacy and control.",
    version: "1.0.0",
    disabled: true,
  },
];
