import { toast } from "react-toastify";

import {
  useOrganizationApi,
  useOrganizationSWR,
  useOrganizationResource,
  useOrganizationSchemaResource,
} from "../hooks/useApi";
import type { CreateApiChat, CreatePrefilledApiChat } from "apiTypes";
import { ApiChat } from "apiTypes";
import { useNavigate, useParams } from "../../router";

export function useChats() {
  return useOrganizationResource(`chats`, {
    refreshInterval: 2000,
  });
}

export function useMutateChats() {
  return useOrganizationSWR(`chats`).mutate;
}

export function useUpdateChat(chatId: string) {
  const api = useOrganizationApi();

  const mutateChat = useOrganizationSWR(`chats/${chatId}`).mutate;

  return async (props) => {
    return await api.patch(`chats/${chatId}`, props).then(() => mutateChat());
  };
}
export function useChat(chatId: string) {
  return useOrganizationSchemaResource(`chats/${chatId}`, ApiChat, {
    refreshInterval: 2000,
  });
}

export function useDeleteChat() {
  const api = useOrganizationApi();

  return async (chatId: string) => {
    await api.delete(`chats/${chatId}`);
  };
}

export function useDeleteAllChats() {
  const mutateChats = useMutateChats();
  const api = useOrganizationApi();
  const params = useParams("/:organizationId");
  const navigate = useNavigate();

  return async () => {
    await api.delete(`chats`);
    mutateChats().catch(console.error);
    toast.success("Deleted all chats");
    return navigate(`/:organizationId`, { params });
  };
}

export function useCreateChat() {
  const api = useOrganizationApi();

  return async (props: CreateApiChat) => {
    return await api.post(`chats`, props);
  };
}

export function useCreatePrefilledChat() {
  const api = useOrganizationApi();

  return async (props: CreatePrefilledApiChat) => {
    return await api.post(`chats/prefilled`, props);
  };
}
