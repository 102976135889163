import { useRef, useState } from "react";
import { useClosableInfoModal } from "../../lib/context/infoModalStore";
import type { ApiTextModel } from "apiTypes";
import {
  Button,
  Checkbox,
  FormControl,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";

export function useUnsafeModelWarning(
  setModel: (model: ApiTextModel) => void,
  closeModal: () => void,
  dontShowAgainEnabled: boolean
) {
  const { t } = useTranslation();
  const closableModal = useClosableInfoModal("unsaved-model-warning");
  const hasClosedForever = !closableModal.modalOpen;
  const [modalOpen, setModalOpen] = useState(false);
  const [checkedForever, setCheckedForever] = useState(false);

  const currentModelRef = useRef<ApiTextModel | null>(null);

  const onChooseUnsafeModel = (model: ApiTextModel) => {
    if (!dontShowAgainEnabled && hasClosedForever) {
      setModel(model);
      closeModal();
    } else {
      currentModelRef.current = model;
      setModalOpen(true);
    }
  };

  const renderModal = () => (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      title="Unsaved Model Warning"
    >
      <ModalDialog>
        <div className="flex flex-col gap-2">
          <Typography level="h4">{t("warning")}</Typography>
          <Typography className="max-w-md">{t("nonEuModelWarning")}</Typography>
          <div className="h-1"></div>
          {!dontShowAgainEnabled && (
            <FormControl>
              <Checkbox
                label={t("unsafeModel.continueForever")}
                checked={checkedForever}
                onChange={(e) => setCheckedForever(e.target.checked)}
              />
            </FormControl>
          )}
          <div className="h-1"></div>
          <div className="flex flex-row items-center justify-end gap-4 pt-4">
            <Button
              color="warning"
              variant="soft"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {t("unsafeModel.cancel")}
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                setModel(currentModelRef.current!);
                setModalOpen(false);
                closeModal();
                if (checkedForever) {
                  closableModal.onModalClose();
                }
              }}
            >
              {t("unsafeModel.continue")}
            </Button>
          </div>
        </div>
      </ModalDialog>
    </Modal>
  );

  return {
    onChooseUnsafeModel,
    renderModal,
  };
}
