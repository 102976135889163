// For now we have the following modules:

import { Card, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { mockModules } from "./mockModules.1";

export function MockModulesDisplay() {
  const { t } = useTranslation();

  const ModuleGrid = ({ modules }: { modules: typeof mockModules }) => (
    <div className="grid lg:grid-cols-2 grid-cols-1 xl:grid-cols-3 gap-6">
      {modules.map((module) => (
        <Card key={module.name}>
          <div>
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center gap-3">
                {module.icon}
                <Typography level="h4">{module.name}</Typography>
              </div>
              <Typography level="body-md" color="neutral">
                v{module.version}
              </Typography>
            </div>
            <Typography level="body-sm" color="neutral">
              meinGPT Team
            </Typography>
          </div>
          <Typography level="body-md" color="neutral">
            {module.description}
          </Typography>
        </Card>
      ))}
    </div>
  );

  return (
    <div className="flex flex-col gap-6">
      <Typography level="h3">{t("settings.modules.enabledModules")}</Typography>
      <ModuleGrid modules={mockModules.filter((m) => !m.disabled)} />
      <Typography level="h3">
        {t("settings.modules.furtherExtensions")}
      </Typography>
      <ModuleGrid modules={mockModules.filter((m) => m.disabled)} />
    </div>
  );
}
