import ClearIcon from "@mui/icons-material/Clear";
import {
  Card,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { z } from "zod";
import { useOrganization } from "../../lib/api/organization";
import {
  useAvailableModels,
  useDisableModel,
  useEnabledServices,
  useEnableModel,
} from "../../lib/api/services";
import { useCurrentOrganizationId } from "../../lib/api/trpc/helpers/useCurrentOrganizationId";
import { trpc } from "../../lib/api/trpc/trpc";
import { useMe } from "../../lib/api/user";
import { useTranslation } from "../../lib/i18n";
import { EnabledModelsSelector } from "../input/ModelSelectorModal";
import { DelayedLoader } from "../util/DelayadLoader";
import { PopoverPicker } from "./PopoverPicker";

const urlSettingsZod = (error: string) =>
  z
    .union([z.string().url(), z.literal("")], {
      invalid_type_error: error,
    })
    .transform((value) => (value === "" ? null : value));

const Settings = ({
  name,
  children,
}: {
  name: string;
  children: React.ReactNode;
}) => {
  return (
    <tr className="h-4">
      <td className="w-1/4 py-3 align-top">
        <Typography level="title-md">{name}</Typography>
      </td>
      <td className="py-3">{children}</td>
    </tr>
  );
};

export function GeneralSettings() {
  const { t } = useTranslation();

  const organization = useOrganization();
  const updateOrganization = trpc.organization.mutateOrganization.useMutation();
  const currentOrgId = useCurrentOrganizationId();
  const utils = trpc.useUtils();
  const services = useEnabledServices();
  const availableModels = useAvailableModels();
  const enableModel = useEnableModel();
  const disableModel = useDisableModel();
  const user = useMe();

  const [orgColor, setOrgColor] = useState<string | null>(
    organization?.customPrimaryColor ?? null
  );

  if (!organization) return <DelayedLoader />;

  const handleUpdateOrganization = async (
    value,
    name,
    dbKey,
    toastMessage?
  ) => {
    try {
      await updateOrganization
        .mutateAsync({ [dbKey]: value, organizationId: currentOrgId })
        .then(() => utils.organization.getOrganization.invalidate());
      toast.success(toastMessage ?? `${t(name)} ${t("settingsChange")}`);
    } catch (error) {
      console.error(error);
      toast.error(t("saveFailed"));
    }
  };

  /**
   * Wrapper for input settings, including event handling to reduce code
   * @param name the language file name reference for display, e.g. organizationTitle
   * @param dbKey the key of the setting in the database, e.g. domain; The value is organization[dbKey]
   * @param altValue an optional alternative value in case the value is not found
   * @param toastMessage the message to display in the toast when the setting is updated
   * @param type
   * @param disabledMessage
   */
  function OrganizationSettings({
    name,
    dbKey,
    altValue = "",
    toastMessage,
    type = "text",
    disabledMessage,
    validation,
  }: {
    name: string;
    dbKey: string;
    altValue?: string;
    toastMessage?: string;
    type?: string;
    disabledMessage?: string;
    tooltipMessage?: string;
    validation: z.ZodType;
  }) {
    return (
      <Settings name={t(name)}>
        {disabledMessage ? (
          <Typography>{disabledMessage}</Typography>
        ) : (
          <Input
            type={type}
            defaultValue={organization![dbKey] ?? altValue}
            onBlur={(event) => {
              if (event.target.value === (organization![dbKey] ?? "")) return; // No change, and all falsy values are considered equal
              const parsed = validation.safeParse(event.target.value);

              if (parsed.success) {
                handleUpdateOrganization(
                  parsed.data,
                  name,
                  dbKey,
                  toastMessage
                ).catch((error) => {
                  console.error(error);
                  toast.error(t("saveFailed"));
                });
              } else {
                event.target.value = organization![dbKey] ?? altValue;
                toast.error(parsed.error.format()._errors.join(", "));
              }
            }}
          />
        )}
      </Settings>
    );
  }

  async function handleOrgColorUpdate(color: string | null) {
    //Check if the color is a valid hex color
    if (color === null || /^#[0-9A-F]{6}$/i.test(color)) {
      await handleUpdateOrganization(
        color,
        t("organizationColor"),
        "customPrimaryColor"
      );
      setOrgColor(null);
    }
  }

  /**
   * @returns If the model was actually updated
   */
  async function handleUpdateModel(
    modelKey: string,
    enabled: boolean
  ): Promise<boolean> {
    if (enabled) {
      await enableModel(modelKey);
      return true;
    } else {
      if ((services?.textModels?.length ?? 0) <= 1) {
        toast.error(t("cantDisableLastModel"));
        return false;
      }
      if (organization!.defaultModel === modelKey) {
        // If the default model is disabled, set the first available model as the new default
        const newDefaultModel = services?.textModels.find(
          (model) => model !== modelKey
        );
        await updateOrganization.mutateAsync({
          defaultModel: newDefaultModel,
          organizationId: currentOrgId,
        });
      }
      await disableModel(modelKey);
      return true;
    }
  }

  return (
    <Card>
      <Stack spacing={3}>
        {user?.isGlobalAdmin && (
          <>
            <Typography level="title-lg">{t("generalSettings")}</Typography>
            <table className="!mb-8 table-auto">
              <tbody>
                <OrganizationSettings
                  name="organizationName"
                  dbKey="name"
                  validation={z
                    .string()
                    .min(1, t("errors.nameCannotBeEmpty"))
                    .max(100, t("errors.nameTooLong"))}
                />
                <Settings name={t("organizationColor")}>
                  <Input
                    startDecorator={
                      <PopoverPicker
                        color={organization.customPrimaryColor!}
                        onChange={async (color) => {
                          setOrgColor(color);
                          await handleUpdateOrganization(
                            color,
                            t("organizationColor"),
                            "customPrimaryColor"
                          );
                        }}
                      />
                    }
                    endDecorator={
                      orgColor !== null ? (
                        <IconButton onClick={() => handleOrgColorUpdate(null)}>
                          <ClearIcon />
                        </IconButton>
                      ) : null
                    }
                    value={orgColor ?? ""}
                    onChange={(e) => setOrgColor(e.target.value)}
                    onBlur={(e) => handleOrgColorUpdate(e.target.value)}
                  />
                </Settings>
                <OrganizationSettings
                  name="organizationAvatarUrl"
                  dbKey="avatarUrl"
                  validation={urlSettingsZod(t("errors.invalidUrl"))}
                />
                <OrganizationSettings
                  name="organizationHeaderUrl"
                  dbKey="logoUrl"
                  validation={urlSettingsZod(t("errors.invalidUrl"))}
                />
              </tbody>
            </table>
          </>
        )}

        <Typography level="title-lg">{t("enabledLLMs")}</Typography>

        <FormControl key="nonEuWarningSkippable">
          <Checkbox
            label={t("removeNonEuDontShow")}
            checked={organization?.nonEuWarningSkippable ?? false}
            onChange={async (e) => {
              await handleUpdateOrganization(
                e.target.checked,
                t("removeNonEuDontShow"),
                "nonEuWarningSkippable"
              );
            }}
          />
          <FormHelperText>{t("removeNonEuDontShowHelperText")}</FormHelperText>
        </FormControl>
        <EnabledModelsSelector
          availableModels={availableModels ?? []}
          selectedModels={services?.textModels ?? []}
          updateModel={handleUpdateModel}
          setDefaultModel={async (model) => {
            await updateOrganization.mutateAsync({
              defaultModel: model,
              organizationId: currentOrgId,
            });
            toast.success(t("changeDefaultLLMConfirm"));
          }}
        />
      </Stack>
    </Card>
  );
}
