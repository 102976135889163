import { Typography } from "@mui/joy";
import { useTranslation } from "../../lib/i18n";

export function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex flex-col gap-3 items-center justify-center h-full">
        <Typography>{t("errorDisplay.404.title")}</Typography>
        <Typography>{t("errorDisplay.404.helpText")}</Typography>
      </div>
    </div>
  );
}
