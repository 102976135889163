import type { ImageRequest, MultiImageRequest } from "apiTypes";
import { useOrganizationApi } from "../hooks/useApi";

export const useGenerateImage = () => {
  const api = useOrganizationApi();

  return (options: ImageRequest) => {
    return api.post("images/generate", options);
  };
};

export function useTrackImageGeneration() {
  const api = useOrganizationApi();

  return (options: MultiImageRequest) => {
    api.post("images/track", options).catch(console.error);
  };
}
