import { Add } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/joy";
import { toast } from "react-toastify";
import type { CreateWorkflowInput } from "../../../../../backend/src/api/routers/workflows/workflowRouter.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useNavigate, useParams } from "../../../router";
import { useTranslation } from "../../../lib/i18n";
import {
  useMutateDepartment,
  usePersonalDepartment,
} from "../../../lib/api/deparment";
import type { ReactNode, MouseEvent } from "react";

export function NewWorkflowButton({
  children,
  onCreated,
}: {
  children?: ReactNode;
  onCreated?: () => void;
}) {
  const { t } = useTranslation();
  const { mutateAsync: createWorkflow } = trpc.workflows.create.useMutation();
  const navigate = useNavigate();
  const params = useParams("/:organizationId");

  const personalDepartment = usePersonalDepartment();
  const mutatePersonalDepartment = useMutateDepartment(
    personalDepartment?.id ?? "personal"
  );

  function onClick(e: MouseEvent) {
    e.stopPropagation();
    createWorkflow({
      organizationId: params.organizationId,
      workflow: {
        folderId: null,
        name: t("unnamedWorkflow"),
        departmentId: personalDepartment?.id ?? "",
      } satisfies CreateWorkflowInput,
    })
      .then(async (workflow) => {
        await mutatePersonalDepartment();
        navigate(`/:organizationId/workflows/:workflowId`, {
          params: {
            ...params,
            workflowId: workflow.id,
          },
        });
        toast.success(t("createdWorkflow"));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t("createWorkflowFailed"));
      });
    onCreated?.();
  }

  return (
    <>
      {children ? (
        <div onClick={onClick}>{children}</div>
      ) : (
        <Tooltip title={t("unnamedWorkflow")}>
          <IconButton onClick={onClick}>
            <Add fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
