import { ModalDialog, Typography } from "@mui/joy";
import RouteModal from "../../../components/util/RouteModal";
import { ChatInput } from "../../../components/chat/ChatInput";
import { useCreateChat } from "../../../lib/api/chat";
import { useQueuedMessagesStore } from "../../../lib/context/queuedMessagesStore";
import { useParams, useNavigate } from "../../../router";
import { useTranslation } from "react-i18next";

export default function BrainstormerModal() {
  const { t } = useTranslation();
  const createChat = useCreateChat();
  const enqueueMessage = useQueuedMessagesStore(
    (state) => state.addQueuedMessage
  );
  const params = useParams("/:organizationId");
  const navigate = useNavigate();

  const onPost = async (message: string, attachmentIds: string[]) => {
    const chat = await createChat({
      organizationId: params.organizationId,
      modelOverride: "mistral-large-latest", // seems to be the best model for diagrams
      customSystemPromptSuffix: t("system.prompts.diagramContext"),
      name: `Diagramm: ${message}`,
    });

    enqueueMessage({
      chatId: chat.data.id,
      content: t("system.prompts.diagramMessage") + message,
      attachmentIds,
    });

    navigate("/:organizationId/chats/:chatId", {
      params: {
        organizationId: params.organizationId,
        chatId: chat.data.id,
      },
    });
  };

  return (
    <RouteModal>
      <ModalDialog
        variant="soft"
        sx={{
          width: "min(40vw, 900px)",
          overflow: "auto",
        }}
        size="sm"
      >
        <div className="flex flex-col gap-4">
          <Typography level="h4">{t("diagramCreator")}</Typography>
          <Typography>
            Willkommen beim Diagramm Creator. Hier kannst du Diagramme zu allem
            möglichen erstellen. Gebe einfach dein Thema ein oder deine Daten
            ein.
          </Typography>
          <ChatInput
            postMessage={onPost}
            placeholder="Gib hier dein Thema und oder deine Daten ein..."
          />
        </div>
      </ModalDialog>
    </RouteModal>
  );
}
