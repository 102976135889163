import { z } from "zod";

export const ProductConfig = z.object({
  id: z.string(),
  organizationId: z.string(),
  dalle3: z.boolean(),
  documentIntelligence: z.boolean(),
  creditSystem: z.boolean(),
  entraId: z.boolean(),
  helpdesk: z.boolean(),
  meetingSummarizer: z.boolean(),
  translateText: z.boolean(),
  enabledModels: z
    .array(z.string())
    .default([
      "gpt-3.5-turbo",
      "gpt-4",
      "gpt-3.5-turbo-us",
      "gpt-4-us",
      "gpt-4o-us",
      "llama-3-sonar-large-32k-online",
      "mistral-small-latest",
      "mistral-medium-latest",
      "mistral-large-latest",
      "claude-3-opus-20240229",
      "claude-3-sonnet-20240229",
      "claude-3-haiku-20240307",
      "gemini-1.5-pro-preview-0409",
    ]),
});
