import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import { trpc } from "./trpc";
import {
  httpBatchLink,
  splitLink,
  unstable_httpBatchStreamLink,
} from "@trpc/client";

const trpcUrl = "/api/trpc";

export function TrpcProvider({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        // the streaming link is preferred for most operations, since it allows batching but also streaming the individual operations, and not waiting for the whole batch to complete. However, some operations cannot be part of a streaming response. Example: Some operations need to set headers, like for authorization, which is not possible to send after a stream has started. So we allow individual operations to disable streaming by settings `context.disableStreaming` to `true`.
        splitLink({
          condition: (op) => !op.context.disableStreaming,
          true: [
            unstable_httpBatchStreamLink({
              url: trpcUrl,
            }),
          ],
          false: [
            httpBatchLink({
              url: trpcUrl,
            }),
          ],
        }),
      ],
    })
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
