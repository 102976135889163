import z from "zod";

export const GenericTextTemplateDefinition = z.object({
  templateText: z.string(),
  source: z.enum(["organization", "proprietary"]),
  inputs: z.array(
    z.discriminatedUnion("type", [
      z.object({
        type: z.literal("text"),
        id: z.string(),
        name: z.string(),
      }),
    ])
  ),
  departments: z.array(z.string()).default([]),
});

export const ApiTemplate = GenericTextTemplateDefinition.merge(
  z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().nullable(),
  })
);

export type ApiTemplate = z.infer<typeof ApiTemplate>;
