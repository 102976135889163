import { Sheet, Typography } from "@mui/joy";
import type { Workflow } from "apiTypes";
import { toast } from "react-toastify";
import { DelayedLoader } from "../../../components/util/DelayadLoader.tsx";

import { WorkflowEditor } from "../../../components/workflows/WorkflowEditor.tsx";
import { useMutateDepartment } from "../../../lib/api/deparment.ts";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { useTranslation } from "../../../lib/i18n.ts";
import { useParams } from "../../../router.ts";

export default function WorkflowScreen() {
  const { t } = useTranslation();
  const { organizationId, workflowId } = useParams(
    "/:organizationId/workflows/:workflowId"
  );

  const { mutateAsync: updateWorkflow } = trpc.workflows.update.useMutation();
  const { data: workflow } = trpc.workflows.getById.useQuery({
    organizationId: organizationId,
    workflow: { id: workflowId },
  });
  const trpcUtils = trpc.useUtils();

  const mutateDepartment = useMutateDepartment(
    workflow?.departmentId ?? "personal"
  );

  if (!workflow) return <DelayedLoader />;

  async function onUpdate(values: Workflow) {
    try {
      await updateWorkflow({ organizationId, workflow: values });
      await trpcUtils.workflows.invalidate();
      toast.success(t("workflowUpdated"));
    } catch (err) {
      console.error(err);
      toast.error(t("workflowUpdateFailed"));
    }
    mutateDepartment().catch(console.error);
  }

  return (
    <Sheet variant="soft" className="min-h-screen">
      <div className="flex flex-col gap-10 p-10" key={workflowId}>
        <Typography level="h1">
          {t("editWorkflow", { name: workflow.name })}
        </Typography>
        <WorkflowEditor onSubmit={onUpdate} initialValues={workflow} />
      </div>
    </Sheet>
  );
}
