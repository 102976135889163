import { ApiMessage } from "apiTypes";
import {
  useOrganizationApi,
  useOrganizationSchemaResource,
  useOrganizationSWR,
} from "../hooks/useApi";

export function useMessages(chatId: string) {
  return useOrganizationSchemaResource(
    `chats/${chatId}/messages`,
    ApiMessage.array(),
    {
      refreshInterval: 1000,
    }
  );
}

export function useMutateMessages(chatId: string) {
  return useOrganizationSWR(`chats/${chatId}/messages`).mutate;
}

export function useCreateMessage() {
  const api = useOrganizationApi();

  return async ({ content, chatId }: { content: string; chatId: string }) => {
    await api.post(`chats/${chatId}/messages`, {
      content,
    });
  };
}

export function useCancelLatestAiMessage(chatId: string) {
  const api = useOrganizationApi();

  return async () => {
    await api.post(`chats/${chatId}/messages/cancel/latest`);
  };
}
