import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../context/authStore";
import { useEffect } from "react";

export function useLoggedInOnly() {
  const loggedIn = useAuthStore((store) => store.loggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const loc = useLocation();

  useEffect(() => {
    if (!loggedIn) {
      localStorage.setItem("returnUrl", location.pathname + loc.search);
      navigate({
        pathname: "/auth",
      });
    }
  }, [loggedIn, navigate, location.pathname, loc.search]);
}
