import {
  Chat,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  IconButton,
  List,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/joy";
import { differenceInDays } from "date-fns";
import type { ReactElement } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  useChats,
  useDeleteAllChats,
  useDeleteChat,
  useMutateChats,
} from "../../lib/api/chat";
import { useTranslation } from "../../lib/i18n";
import { maxStringLength } from "../../lib/util";
import { useNavigate, useParams } from "../../router";
import { AreYouSure, TreeItem } from "./tree/LeafItem";

export default function ChatTree() {
  const { t, i18n } = useTranslation();
  const DEFAULT_AMOUNT_OF_CHATS = 5;

  const clearAllChats = useDeleteAllChats();
  const [clearAllChatsConfirmOpen, setClearAllChatsConfirmOpen] =
    useState(false);
  const [amountOfChats, setAmountOfChats] = useState(DEFAULT_AMOUNT_OF_CHATS);

  const chats = useChats();

  const totalChats = chats?.length ?? 0;

  if (chats === undefined) return;

  const lastChats = chats
    ?.sort((a, b) => b.updatedAt - a.updatedAt)
    .slice(0, amountOfChats);

  if (!lastChats) return;

  const loadMoreChats = () => {
    setAmountOfChats((prevAmount) => {
      return prevAmount + 15;
    });
  };

  let lastSubheaderLabel = "";

  const groupedChats = lastChats.map((chat) => {
    let currentSubheaderLabel = "";
    const updatedAt = new Date(chat.updatedAt);
    const daysDifference = differenceInDays(new Date(), updatedAt);
    if (daysDifference < 7) {
      currentSubheaderLabel = t("previous7Days");
    } else if (daysDifference < 30) {
      currentSubheaderLabel = t("previous30Days");
    } else {
      currentSubheaderLabel = new Intl.DateTimeFormat(i18n.language, {
        month: "long",
        year: daysDifference > 365 ? "numeric" : undefined,
      })
        .format(updatedAt)
        .toString();
    }
    let subheader: ReactElement | null = null;
    if (
      lastSubheaderLabel !== currentSubheaderLabel &&
      amountOfChats > DEFAULT_AMOUNT_OF_CHATS
    ) {
      lastSubheaderLabel = currentSubheaderLabel;
      subheader = (
        <ListSubheader sx={{ pt: 1, pb: 0, pl: 2 }}>
          {currentSubheaderLabel}
        </ListSubheader>
      );
    }
    return (
      <div key={chat.id}>
        {subheader}
        <ChatItem chat={chat} />
      </div>
    );
  });

  return (
    <div className="group">
      <AreYouSure
        open={clearAllChatsConfirmOpen}
        onClose={() => setClearAllChatsConfirmOpen(false)}
        onSure={clearAllChats}
      />
      <div className="flex flex-row items-center">
        <Typography
          level="title-md"
          color="neutral"
          sx={{
            px: 2,
            py: 1,
          }}
        >
          {t("lastChats")}
        </Typography>
        <div className="opacity-0 transition-all group-hover:opacity-100">
          <Tooltip title={t("deleteAllChats")}>
            <IconButton
              size="sm"
              onClick={() => setClearAllChatsConfirmOpen(true)}
            >
              <Close fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <List size="sm">
        {groupedChats}
        {lastChats.length === 0 ? (
          <Typography
            level="body-sm"
            color="neutral"
            sx={{
              px: 2,
              py: 1,
              fontStyle: "italic",
            }}
          >
            {t("noChats")}
          </Typography>
        ) : (
          <div
            className="my-1 flex-row justify-between"
            style={{ userSelect: "none" }}
          >
            <ButtonGroup
              size="sm"
              variant="plain"
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                pt: 1,
                "& > *:only-child": {
                  border: "none",
                },
              }}
            >
              {lastChats.length < totalChats && (
                <Button
                  onClick={() => {
                    loadMoreChats();
                  }}
                  startDecorator={<KeyboardArrowDown />}
                >
                  {t("moreChats")}
                </Button>
              )}
              {amountOfChats > DEFAULT_AMOUNT_OF_CHATS && (
                <Button
                  onClick={() => setAmountOfChats(DEFAULT_AMOUNT_OF_CHATS)}
                  startDecorator={<KeyboardArrowUp />}
                >
                  {t("hide")}
                </Button>
              )}
            </ButtonGroup>
          </div>
        )}
      </List>
    </div>
  );
}

function ChatItem({ chat }: { chat: ReturnType<typeof useChats>[number] }) {
  const { t } = useTranslation();

  const params = useParams("/:organizationId/chats/:chatId");
  const active = params.chatId === chat.id;
  const navigate = useNavigate();
  const deleteChat = useDeleteChat();
  const mutateChats = useMutateChats();

  return (
    <TreeItem
      icon={<Chat fontSize="small" />}
      name={maxStringLength(chat.name as string, 30)!}
      onDelete={() => {
        toast
          .promise(deleteChat(chat.id).then(mutateChats), {
            success: t("chatDeleted"),
            error: t("chatDeleteFailed"),
          })
          .catch((e) => console.error(e));
        active &&
          navigate("/:organizationId", {
            params: {
              ...params,
            },
          });
      }}
      selected={active}
      onClick={() => {
        navigate("/:organizationId/chats/:chatId", {
          params: {
            ...params,
            chatId: chat.id,
          },
        });
      }}
    />
  );
}
